import React, { Component } from 'react';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return( 
        
        <section id="main-section" className="jc-inner-page align-self-start px-3 py-3">
            <h2 className="d-flex align-center justify-content-center">HBL PSL Refund FAQs</h2>
            <br/>
            <p>The tickets refund process of the 10 HBL Pakistan Super League matches which were abandoned due to
            rain, played behind closed doors or rescheduled due to Covid-19 will commence on Monday, 13 July
            2020.
            </p>

            
            <p>Here are some frequently asked questions to help our valuable customers through this process:</p>

            <b>1. Can I get my refund from any TCS Express Center near me?</b>
            <p>No, please check the list of dedicated express centers available on the HBL PSL website.</p>
            

            <b>2. When can I claim my refund? </b>
            <p>No, please check the list of dedicated express centers available on the HBL PSL website.</p>

            <p>Phase 1 of refunds will cover the group-stage matches </p>

            <ul>
                <li>
                If you have purchased the tickets for group matches, then you can collect your refund from 13 July to 5 August 2020. 
                </li>
                <li>
                If you have purchased the tickets for the playoff matches or final, then you can collect your refund from 6 August - 29 August 2020. 
                </li>
            </ul>

            <b>3. Do I need to bring my CNIC to claim a refund?  </b>
            <p>No</p>

            <b>4. How can I get a refund if the ticket is on someone elseʼs CNIC number? </b>
            <p>The ticket-bearer shall be deemed to be the ultimate owner of the tickets and they can claim the refund. </p>

            <b>5. What do I need to bring for Tickets refund? </b>
            <p>Bring your original match tickets to claim a refund. </p>
            
            <p>For zero-crowd and postponed games, these tickets should be untorn and bear the valid security sticker for validation.  </p>
            
            <p>For washed-out matches in which spectators had already gained access to the venue, the torn customer copy of the ticket shall be used for refunds.  </p>
            

            <b>6. Can I bring the photocopy of my ticket?  </b>
            <p>The refund can only be processed against the original ticket and TCS staff will validate the authenticity of the tickets. </p>


            <b>7. What are the working hours of TCS Express Centers for a refund? </b>
            <p>Monday to Saturday from 10 a.m. – 4 p.m. </p>

            <b>8. Can I send someone else to pick up my tickets?  </b>
            <p>Yes, as long as the tickets are in their original condition. </p>

            <b>9. Can I get my refund in the bank account?  </b>
            <p>No, the refund will only be processed in cash from the designated TCS outlets.  </p>

            <b>10. How can I get a refund of corporate/bulk tickets purchased directly from TCS Head Office or through PCB?</b>
            <p>The refund can be claimed via TCS Head Office by emailing at corporate.ticketing@tcs.com.pk </p>


            <b>11. Do I have to submit my ticket? </b>
            <p>Yes, the original tickets will remain with TCS after the refund is processed. </p>

            <b>12. Are you following the COVID-19 SOPs?</b>
            <p>Yes, strict adherence to prescribed protocols will be compulsory to get your refunds.</p>

            <ul>
                <li>
                It is mandatory for every customer to wear a face mask and no one will be allowed to enter the TCS outlet without wearing one. 
                </li>
                <li>
                Ticket-holders must maintain appropriate social distancing during their visits to the TCS outlets. 
                </li>
            </ul>

            <p>I am not satisfied and I think I need more help. What can I do? </p>
            <p>No problem. Please get in touch on the 24/7 TCS helpline at 111-123-456.</p>



            </section>
        )
    }
}

export default Faq;