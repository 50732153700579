import React, { Component } from "react";
import { MainCarouselSwipe } from "./InitializePlugins";
import {
  MatchScorecardInningsDetails,
  getMatchStateText,
  getLocalTimeInAmPm,
  getTimeInAmPm,
  getDateWithoutYear,
  getTeamColorClass,
} from "./HelperFunctions";
import { ApiUrl, pusherKey } from "./constants";
// import { MatchScorecardInningsDetails, getMatchStateText } from './HelperFunctions';
import { Globals } from "./constants";
import axios from "axios";
import Pusher from "pusher-js";
import { map } from "./API_MAPPER";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import { API_URL, CIG_API_URL } from "./CIG_APIS";

//// This is the old version of main carousel and it was using api of cricingif the new one is useing the api of cricwick

class Maincarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveMatchResp: {},
      customCssTransition: "h-transition",
      leftHandleClass: "",
      rightHandleClass: "",
      loading: true,
    };
    this.handleSwipe = MainCarouselSwipe.bind(this);
    this.windowLoadListener = false;
    this.liveMatchHandler = this.liveMatchHandler.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getFromApi = this.getFromApi.bind(this);

    this.poller = null;
  }

  liveMatchHandler(data) {
    console.log("got live push", data);

    const liveMatchesList = this.state.liveMatchResp.live_matches || [];
    const liveMatches = liveMatchesList.map(
      (obj, i) => (data.id === obj.id ? data : obj)
    );
    let liveMatchResp = this.state.liveMatchResp;
    liveMatchResp.live_matches = liveMatches;
    this.setState({ liveMatchResp: liveMatchResp });
  }

  refresh(data) {
    //   console.log("Live match refresh", data);
    this.getFromApi();
  }

  async getFromApi() {
    const self = this;
    const url = CIG_API_URL + "api/schedules/psl";
    fetch(url)
      .then(function(response) {
        response.json().then(function(schedules) {
          self.setState({
            liveMatchResp: schedules,
            customCssTransition: "v-transition",
            loading: false,
          });
        });
      })
      .catch(function(error) {
        // console.log("Error getting ");
      });
  }

  componentDidMount() {
    if (document.readyState === "complete") {
      // if document has finished loading all the scripts
      this.handleSwipe.call(); // call videos swipe initialization
    } else {
      // if document is still loading scripts i.e readyState === 'loading'
      this.windowLoadListener = true; // Set listener to true to remove it in component will unmount react lifecycle method
      window.addEventListener("load", this.handleSwipe); // add listener
    }
    const pusher = new Pusher(Globals.pusherKey, {
      cluster: "ap1",
      encrypted: true,
    });
    const channel = pusher.subscribe("live_matches");
    channel.bind("match_update", this.liveMatchHandler);
    channel.bind("refresh", this.refresh);
    this.getFromApi();

    this.poller = setInterval(this.getFromApi, 30000);
  }
  componentWillUnmount() {
    if (this.windowLoadListener)
      window.removeEventListener("load", this.handleSwipe); //remove listener if listening
    clearInterval(this.poller);
  }

  render() {
    const { selectedMatchId } = this.props;
    if (
      !this.state.liveMatchResp.length ||
      this.state.liveMatchResp.length <= 0
    )
      return (
        <div className="text-center text-grey main-carousel-error d-flex justify-content-center align-items-center">
          {this.state.loading
            ? "Loading ..."
            : "Match data is not available at the moment"}
        </div>
      );
    let matchesData = getMatchCard(
      map("scheduleWidget", this.state.liveMatchResp)
    );
    matchesData = matchesData.filter((obj) => obj);

    return (
      <div
        id="main-carousel-container"
        className="container zi-1 bg-body position-relative"
      >
        <div className={"cardss " + this.state.customCssTransition}>
          {matchesData.map((obj, i) => (
            // <a key={i} href={"https://www.cricingif.com/match/" + obj.matchId + "/" + obj.slug + "/overview"}>
            <a key={i} href={"https://cricwick.net/"}>
              {getMatchCardTemplate(obj, i, "shortName")}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export const getMatchCard = (matches) => {
  let fourMatches = [],
    sortedMatches = matches.sort(
      (a, b) => new Date(a.matchDate) - new Date(b.matchDate)
    ),
    completedMatchesSliceIndex = 0;

  const liveMatches = sortedMatches.filter((m) => m.matchState === 1); // live
  const upcomingMatches = sortedMatches.filter((m) => m.matchState === 2); // upcoming
  const completedMatches = sortedMatches.filter((m) => m.matchState === 0); // completed

  if (upcomingMatches.length === 0 && liveMatches.length === 0)
    completedMatchesSliceIndex = completedMatches.length - 4;
  if (
    (upcomingMatches.length === 0 && liveMatches.length === 1) ||
    (upcomingMatches.length === 1 && liveMatches.length === 0) ||
    (upcomingMatches.length === 1 && liveMatches.length === 0)
  )
    completedMatchesSliceIndex = completedMatches.length - 3;
  if (completedMatchesSliceIndex === 0 && completedMatches.length)
    completedMatchesSliceIndex = completedMatches.length - 2;

  fourMatches = liveMatches
    .slice(0, 1)
    .concat(
      completedMatches.slice(
        completedMatchesSliceIndex,
        completedMatches.length
      )
    )
    .concat(upcomingMatches.slice(0, 4));
  return fourMatches;
};

const getScoreTemplate = (obj, type) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div
        className={
          "d-flex align-items-center justify-content-between mh-60px border-left-2px " +
          getTeamColorClass(obj.team1Id)
        }
      >
        <img
          className="mx-2"
          width="40px"
          alt=""
          src={
            "https://storage.cricingif.com/cig-live-images/team-images/100x100/" +
            obj.team1Title.replace(/\s+/g, "").toLowerCase() +
            ".png"
          }
        />
        <div className="flex-column">
          <div className="team-name text-black ff-gotham-medium fs-16px">
            {type === "shortName" ? obj.team1Nick : obj.team1Title}
          </div>
          <div className="team-name text-grey fs-14px ff-gotham-medium">
            {obj.team1Inns1Score}
          </div>
          <div className="team-name text-medium-grey fs-10px ff-gotham-light">
            {obj.team1Inns1Overs}
          </div>
        </div>
      </div>
      <div className="flex-column d-flex align-items-center justify-content-start">
        <div className="text-medium-grey">vs</div>
        <div
          className={
            type === "shortName"
              ? "text-medium-grey closing-comment px-1 m-top-10"
              : "text-medium-grey text-center m-top-10"
          }
        >
          {obj.closingComment}
        </div>
      </div>
      <div
        className={
          "d-flex align-items-center justify-content-between mh-60px border-right-2px " +
          getTeamColorClass(obj.team2Id)
        }
      >
        <div className="flex-column">
          <div className="team-name text-right text-black fs-16px ff-gotham-medium">
            {type === "shortName" ? obj.team2Nick : obj.team2Title}
          </div>
          <div className="team-name text-right text-grey fs-14px ff-gotham-medium">
            {obj.team2Inns1Score}
          </div>
          <div className="team-name text-right text-medium-grey fs-10px ff-gotham-light">
            {obj.team2Inns1Overs}
          </div>
        </div>
        <img
          className="mx-2"
          width="40px"
          alt=""
          src={
            "https://storage.cricingif.com/cig-live-images/team-images/100x100/" +
            obj.team2Title.replace(/\s+/g, "").toLowerCase() +
            ".png"
          }
        />
      </div>
    </div>
  );
};

export const getMatchCardTemplate = (obj, i, type) => (
  <div key={"lv-match-c-" + i} className="card main-carousel mx-2">
    <div className="card-body m-f-11 py-2 px-2 topline d-flex justify-content-between">
      <div>
        <span>{" " + obj.matchInfo + " at "}</span>
        <span className="text-dark-grey">{obj.matchLocation}</span>
        <span>{" on "}</span>
        <span className="text-dark-grey">
          {getDateWithoutYear(obj.matchDate) +
            ", " +
            getLocalTimeInAmPm(obj.matchDate.split(" ")[0] + " " + obj.time)}
        </span>
      </div>
      <div>
        {obj.matchState === 1 && (
          <span className="text-red font-weight-bold">Live</span>
        )}
        {obj.matchState === 0 && (
          <span className="text-grey font-weight-bold">Completed</span>
        )}
        {obj.matchState === 2 && (
          <span className="text-grey font-weight-bold">Upcoming</span>
        )}
      </div>
    </div>
    {getScoreTemplate(obj, type)}
  </div>
);

export default Maincarousel;
