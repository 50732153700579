import * as React from "react";

import ReactPlayer from 'react-player'
import VideoComponent from "./VideoComponent";
import { API_URL, PSL_API_URL, PSL_PROD_STATIC_IMAGES_BASE_URL } from "./CIG_APIS";
// import api from "../services/api-service";

import {Link} from 'react-router-dom';

import map from 'lodash/map';
import clone from 'lodash/clone';
import mapKeys from 'lodash/mapKeys';
import get from 'lodash/get';

class AllVideosComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        playingVideoId: "",
        playVideo: false,
        onReady: false,
        videoUrl: "",
        allVideos: props.allVideos,
        primaryVideo: props.allVideos[0],
        videoReady: false
    }

    this.setVideos = this.setVideos.bind(this);
    
  }

  mapVideos(videos) {
    const videosMap = [{
        Id: "id",
        csId: "contentSeriesId",
        t: "title",
        d: "description",
        p: "preview",
        Url: "url",
        a: "active",
        pd: "publishDate",
        v: "views",
        vt: "videoType"
    }];
    const mapObj = get(videosMap, "0");
    return map(videos, function (match, i) {
        return mapKeys(match, function (value, key) {
            return get(mapObj, key);
        });
    });
  }

  setVideos(id) {
    const self = this;
    if (!id) {
        let myHeaders = new Headers();
        myHeaders.append('content-type', 'application/json');
    
        var myInit = {
          method: 'GET',
          headers: myHeaders,
          mode: 'cors',
          cache: 'default',
        }
        fetch(PSL_API_URL + "api/psl/videos/latest/0", myInit).then(function(response) {
            if (response.status === 200) {
                response.json().then(function(videos) {
                    const mappedVideos = self.mapVideos(videos);
                    self.setState({ allVideos: mappedVideos, primaryVideo: mappedVideos[0] });
                });
            }
        });
    }
  }

  componentDidMount() {
      const { allVideos } = this.state;
      if (allVideos.length <= 0) {
        this.setVideos(null);
      }
  }

  getPlaceHolder(isPrimary, path) {
      return <div className="cover-image" style={{ height: (isPrimary ? "226px" : "106px"), background: "url(" + path + ")" }}></div>
  }

  handleChange(video, index) {
      
    let videoUrl = video ? video.url : "";
    if (videoUrl && (videoUrl !== this.state.videoUrl)) {
        
        const allVideos = clone(this.state.allVideos, true);
        
        const tempVideo = allVideos[0];
        allVideos[0] = allVideos[index];
        allVideos[index] = tempVideo;
        
        this.setState({ 
            videoUrl: videoUrl,
            playVideo: true, 
            allVideos: allVideos,
            primaryVideo: allVideos[0],
            videoReady: false
        });
    }
  }

  handleMobileVideo(videoId) {
    this.setState({ playingVideoId: videoId });
  }

  onReady(event) {
    this.setState({ videoReady: true });
  }
  
  render() {
    const self = this;
    const { primaryVideo, allVideos } = this.state;

    // console.log("vids-->", primaryVideo, allVideos)

    if (allVideos.length <= 0) return null;    
    return(
        <div className={!this.props.isMobile ? "videos-component p-3 border-bottom border-top" : "videos-component videos-component-mobile p-3 border-bottom border-top"}>
            <div className="flex-row">
                <div className="title-container">
                    <div className="d-flex justify-content-center align-items-center">
                        {/* <img src="/images/latest_videos_icon.png" /> */}
                        <h2 className="heading text-center my-3 ff-gotham-bold fs-32px fs-28px-sm text-green text-uppercase mx-2">Latest Videos</h2>
                        {/* <img src="/images/latest_videos_icon.png" /> */}
                    </div>
                    <div className="line"></div>
                </div>
            </div>
            {<div>
                    <div className="primary-video-container"
                        onClick={() => this.handleChange(primaryVideo, 0)}>
                        {this.state.playVideo && <div style={{background: "black"}}>
                            {/* {!this.state.videoReady && <div className="video-image primary-video"><img src="https://scdn.cricingif.com/cig-live-images/static-assets/loading.gif" width="150" height="150" /></div>} */}
                            {primaryVideo.videoType === 3 && <div style={!this.state.videoReady ? {display: "none"} : {}}>
                                <ReactPlayer
                                    url={this.state.videoUrl}
                                    preload="true"
                                    playing={true}
                                    className="video-player"
                                    controls={true}
                                    onReady={this.onReady.bind(this)}
                                />
                            </div>}
                            {primaryVideo.videoType === 2 && <div style={!this.state.videoReady ? {display: "none"} : {}}>
                                <ReactPlayer
                                    url={this.state.videoUrl}
                                    preload="true"
                                    playing={true}
                                    className="video-player"
                                    controls={true}
                                    config={{ facebook: { appId: '1536690803213439' } }}
                                    style={{ paddingTop: "2px"  }}
                                    onReady={this.onReady.bind(this)}
                                />
                            </div>}
                            {primaryVideo.videoType === 1 && <div style={!this.state.videoReady ? {display: "none"} : {}}>
                                <ReactPlayer
                                    url={this.state.videoUrl}
                                    preload="true"
                                    playing={true}
                                    className="video-player"
                                    controls={true}
                                    config={{ youtube: { playerVars: { showinfo: 1 } } }}
                                    onReady={this.onReady.bind(this)}
                                />
                            </div>}
                        </div>}
                        {!this.state.playVideo && <div>
                            <div className="video-image primary-video" 
                            style={{background: "url(" + primaryVideo.preview + "), " 
                            // + "url(" + this.props.imageUrl + "/content-assets/images/LQ/396x226/" + primaryVideo.id + ".jpg), " 
                            + `url(${PSL_PROD_STATIC_IMAGES_BASE_URL}placeholder.png)` }}>
                                {!this.state.playVideo && <img className="play-icon" src="/images/play-icon.png" width="50" height="50" />}
                            </div>
                        </div>}
                        
                    </div>
                    <div className="font-weight-bold mt-2">
                        <div className="oswald-bold regular-font-primary-18 primary-video-title">{primaryVideo && primaryVideo.title}</div>
                        {/* <div className="oswald-regular date">{primaryVideo && new Date(primaryVideo.publishDate).toDateString()}</div> */}
                    </div>
                    <div className="secondary-videos">
                        <div className="video-row" onClick={() => this.handleChange(allVideos[1], 1)}>
                            <div className="video">
                                <div className="video-image secondary-video" style={{background: "url(" + allVideos[1].preview + "), " 
                                // + "url(" + this.props.imageUrl + "/content-assets/images/LQ/186x100/" + (allVideos[1] && allVideos[1].id) + ".jpg), " 
                                + `url(${PSL_PROD_STATIC_IMAGES_BASE_URL}placeholder.png)` }}>
                                    <img className="play-icon-sm" src="/images/play-icon.png" width="30" height="30" />
                                </div>
                                
                            </div>
                            <div className="title-and-date">
                                <div className="oswald-bold title">{allVideos[1] && allVideos[1].title}</div>
                                {/* <div className="oswald-regular date">{allVideos[1] && new Date(allVideos[1].publishDate).toDateString()}</div> */}
                            </div>
                            
                        </div>
                        <div className="video-row" onClick={() => this.handleChange(allVideos[2], 2)}>
                            <div className="video">
                                <div className="video-image secondary-video" style={{background: "url("  + allVideos[2].preview + "), " 
                                // + "url(" + this.props.imageUrl + "/content-assets/images/LQ/186x100/" + (allVideos[2] && allVideos[2].id) + ".jpg), " 
                                + `url(${PSL_PROD_STATIC_IMAGES_BASE_URL}placeholder.png)` }}>
                                    <img className="play-icon-sm" src="/images/play-icon.png" width="30" height="30" />
                                </div>
                                
                            </div>
                            <div className="title-and-date">
                                <div className="oswald-bold title">{allVideos[2] && allVideos[2] && allVideos[2].title}</div>
                                {/* <div className="oswald-regular date">{allVideos[2] && new Date(allVideos[2].publishDate).toDateString()}</div> */}
                            </div>
                        </div>
                        <div className="video-row" onClick={() => this.handleChange(allVideos[3], 3)}>
                            <div className="video">
                                <div className="video-image secondary-video" style={{background: "url("  + allVideos[3].preview + "), " 
                                // + "url(" + this.props.imageUrl + "/content-assets/images/LQ/186x100/" + (allVideos[3] && allVideos[3].id) + ".jpg), " 
                                + `url(${PSL_PROD_STATIC_IMAGES_BASE_URL}placeholder.png)` }}>
                                    <img className="play-icon-sm" src="/images/play-icon.png" width="30" height="30" />
                                </div>
                                
                            </div>
                            <div className="title-and-date">
                                <div className="oswald-bold title">{allVideos[3] && allVideos[3].title}</div>
                                {/* <div className="oswald-regular date">{allVideos[3] && new Date(allVideos[3].publishDate).toDateString()}</div> */}
                            </div>
                            
                        </div>
                        <div className="video-row" onClick={() => this.handleChange(allVideos[4], 4)}>
                            <div className="video">
                                <div className="video-image secondary-video" style={{background: "url("  + allVideos[4].preview + "), " 
                                // + "url(" + this.props.imageUrl + "/content-assets/images/LQ/186x100/" + (allVideos[4] && allVideos[4].id) + ".jpg), " 
                                + `url(${PSL_PROD_STATIC_IMAGES_BASE_URL}placeholder.png)` }}>
                                    <img className="play-icon-sm" src="/images/play-icon.png" width="30" height="30" />
                                </div>
                                
                            </div>
                            <div className="title-and-date">
                                <div className="oswald-bold title">{allVideos[4] && allVideos[4].title}</div>
                                {/* <div className="oswald-regular date">{allVideos[4] && new Date(allVideos[4].publishDate).toDateString()}</div> */}
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="text-center font-weight-bold py-4">
                    <Link to="/videos" className="text-green">See all videos</Link>
                </div>
        </div>
    );
  }
}

export default AllVideosComponent;
