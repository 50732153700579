import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { initVideosPage } from './InitializePlugins';
import axios from 'axios';
import BackgroundImage from 'react-background-image-loader';
import { Globals } from './constants';
import {VideoCardFourByThree, space2hyphen} from './HelperFunctions';
import { API_URL, PSL_API_URL } from "./CIG_APIS";
import ReactPlayer from 'react-player';
import * as _ from "lodash";

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
        customCssTransition: 'h-transition',
        isLoading: true,
        allVideosClass: '',
        featuredClass: '',
        archivesClass: '',
        filter: '',
        apiPath: Globals.ApiUrl+'api/v1/',
        page: 0,
        per_page: 5,
        pagesEnd: false,
        apiResp: [],
        font1p3: { fontSize: '1.3em'},
        font27px: {fontSize: '27px' },
        height268: { height: '238px' },
        height234: { height: '207px' },
        cursorPointer: {cursor: 'pointer'},
        playingVideo: {},
        fetchingMore: false
    }
    this.externalScriptsHandler = initVideosPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.windowLoadListener = false;
    this.updatePlayingVideo = this.updatePlayingVideo.bind(this);
    // this.clickedVideo = this.clickedVideo.bind(this);
  
};

    async componentWillMount() {
        let filter;
        if(this.props.type && this.props.type == 'allVideos'){
            filter = 'allVideos';
            this.setState({allVideosClass: 'border-bottom border-danger', featuredClass:'', archivesClass:''});
        }
        this.setState({filter: filter});

        let page = this.state.page,
            per_page = this.state.per_page,
            resp= [],
            url = PSL_API_URL + "api/psl/videos/latest/" + page;
        try {
            resp = await axios.get(url);
        } catch(error){
            // console.log('Error fetching all videos');
            // window.location.href = '/404notfound';
        }

        if(resp.status == 200) {
            const apiResp = this.state.apiResp.slice();
            // if(filter == 'archives' || filter == 'featured'){
            //     resp.data.videos.forEach( (video) => {apiResp.push(video);})
            // } else{
                resp.data.forEach( (video) => {apiResp.push(video);})
            // }
            this.setState({apiResp: apiResp, customCssTransition: 'v-transition', isLoading: false});
        } else {
            // console.log('Error fetching all videos!');
            // window.location.href = '/404notfound';
        }
    }

  componentDidMount() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

    if (document.readyState === 'complete') { // if document has finished loading all the scripts
      this.externalScriptsHandler.call(); // call videos swipe initialization
    } else { // if document is still loading scripts i.e readyState === 'loading'
      this.windowLoadListener = true; // Set listener to true to remove it in component will unmount react lifecycle method
      window.addEventListener('load', this.externalScriptsHandler); // add listener
    };
  }
  componentWillUnmount() {
    if (this.windowLoadListener) window.removeEventListener('load', this.externalScriptsHandler); //remove listener if listening
  }

  getContainers() {
    // if(this.state.filter == 'archives' || this.state.filter == 'featured'){
    //     return this.state.apiResp.map( (video, i) => {
    //         return <VideoCardFourByThree key={'vc-'+i} data={video}></VideoCardFourByThree>
    //     })
    // }else{
        return this.state.apiResp.map( (video, i) => {
            return <VideosContainer key={'vc-'+i} data={video} ></VideosContainer>
        })
    // }
    
  }

  async nextPage(e){
    // let filter;
    // if(this.props.type && this.props.type === 'allVideos'){
    //     filter = 'allVideos';
    //     this.setState({allVideosClass: 'border-bottom border-danger', featuredClass:'', archivesClass:''});
    // }

    // this.setState({filter: filter});


    if (!this.state.fetchingMore) {

        this.setState({fetchingMore: true});

        let page = this.state.page + 1,
            per_page = this.state.per_page,
            resp= [],
            url = PSL_API_URL + "api/psl/videos/latest/" + page;
        try {
            resp = await axios.get(url);
        } catch(error){
            // console.log('Error fetching all videos');
            // window.location.href = '/404notfound';
        }

        
        const apiResp = resp.data && resp.data.length 
        ? this.state.apiResp.concat(resp.data)
        : this.state.apiResp.slice();

        this.setState({
            apiResp: apiResp, 
            customCssTransition: 'v-transition', 
            isLoading: false, 
            page: page,
            fetchingMore: false

        });
    

    }

  }

  updatePlayingVideo(video) {
    //   debugger;
    // console.log("Playing video", video, this.state.playingVideo)
      if (video.Id !== this.state.playingVideo.Id) {
          window.scrollTo(0,0)
        this.setState({ playingVideo: video });        
      }
  }

  render() {
    const { playingVideo } = this.state;
    return (

      <section id="main-section" className="align-self-start">
        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }
        <div className={this.state.customCssTransition}>

            <div className="card p-3">
                <div className="d-flex overflow-x-auto">
                    <h4 className={this.state.allVideosClass+" font-weight-semi-bold p-2 m-f-14"}>
                        <Link className="alert-heading" to="/videos">All Videos</Link>
                    </h4>
                </div>
            </div>

            <div id="all-videos" className="mt-2">
                <div className="card px-3 py-0">
                    {<div className="player-container mt-3 mb-5">
                        <div className="player-position">
                            {!_.isEmpty(playingVideo) && playingVideo.vt === 3 && <ReactPlayer
                                    url={playingVideo.Url}
                                    preload="true"
                                    playing={true}
                                    className="video-player"
                                    controls={true}
                            />}
                            {!_.isEmpty(playingVideo) && playingVideo.vt === 2 && <ReactPlayer
                                    url={playingVideo.Url}
                                    preload="true"
                                    playing={true}
                                    className="video-player"
                                    controls={true}
                                    config={{ facebook: { appId: '1536690803213439' } }}
                                    style={{ paddingTop: "2px"  }}
                            />}
                            {!_.isEmpty(playingVideo) && playingVideo.vt === 1 && <ReactPlayer
                                url={playingVideo.Url}
                                preload="true"
                                playing={true}
                                className="video-player"
                                controls={true}
                                config={{ youtube: { playerVars: { showinfo: 1 } } }} />}
                        </div>
                    </div>}
                    {this.state.apiResp.map( (video, i) => {
                        return <VideosContainer key={'vc-'+i} 
                            data={video}
                            updatePlayingVideo={this.updatePlayingVideo}>
                        </VideosContainer>
                    })}
                            {/*this.getContainers()*/}
                </div>
            </div>
            {
                (!this.state.pagesEnd && this.state.apiResp.length) ?
                (
                <div>
                    <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" alt="" height="30" /></div>
                    <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
                </div>
                )
                : <div></div>
            }
        </div>
      </section>
    );
  } 
}

// const VideosList = ( props ) => (
//     <div className="text-primary" onClick={(props) => { this.setState({ clickedVideo: props.data }) }}>
//         <div className="vc-item px-0 pr-1">
//             <BackgroundImage className="vc-item-img sixteen-by-nine bg-center-cover  position-relative"
//                 src={props.data.p} placeholder={Globals.placeholderPlain4x3}>
//                <div className="fa fa-play-circle play-btn-lg play-btn-sm"></div> 
//             </BackgroundImage>
//             {/* <div className="vc-item-img sixteen-by-nine bg-center-cover" style={{backgroundImage: 'url('+props.data.video.thumb+')'}}></div> */}
//             <div className="vc-item-h1 mt-3 mb-2 px-2 text-nowrap text-truncate m-f-12">
//                 {/*props.data.video.match_obj.*/} 
//                 {/* -  v Pak U19 W */}
//             </div>
//             <div className="vc-item-h2 px-2 truncate-v2 m-f-13">
//                 {props.data.t}
//                 {/* Day: 5 Session 5 Highlights Session 5 Highlights */}
//             </div>
//         </div>
//     </div>
// )

// const VideosContainerWCounter = ( props ) => (
//     <div className="videos-collection-container">
//         <div className="videos-collection mt-4">

//             <div className="vc-header mb-2">
//                 <div className="vc-title text-nowrap text-truncate">
//                         West Indies tour of india 2018/19
//                 </div>
//                 <div className="row align-items-end">
//                     <div className="vc-title col-6 col-md-10 col-lg-9 col-xl-10 text-nowrap text-truncate">
//                         <span className="vc-count">999 Videos</span>
//                     </div>
//                     <div className="vc-all col-6 col-md-2 col-lg-3 col-xl-2 text-right text-nowrap text-truncate">
//                         <a href="#">View All</a>
//                     </div>
//                 </div>
//             </div>

//             <div className="vc-list">

                

//                 <div className="vc-item px-0 pr-1">
//                     <div className="vc-item-img sixteen-by-nine bg-center-cover" ></div>
//                     <div className="vc-item-h1 mt-3 px-2 text-nowrap text-truncate">
//                         5th Test - Aus v Pak 
//                     </div>
//                     <div className="vc-item-h2 px-2 truncate-v2">
//                         Day: 5 Session 5 Highlights 
//                     </div>
//                 </div>
                
//                 <div className="clearfix"></div>

//             </div>

//         </div>
//     </div>
// );

// const getVideosList = (props) => {
//     debugger;
    // return props.data.map( (video, i) => {
    //     if(video){
            // return <VideosList data={props.data} seriesId={props.data.id}></VideosList>
    //     }
    // })
// }
              
const VideosContainer = (props) => (
        <div className="latest-videos videos-collection-container py-2" style={props.height268}>
            <div className="videos-collection mt-2">
            
                <div className="vc-list" style={props.height234}>

                <div className="text-primary" onClick={() => {props.updatePlayingVideo(props.data)}}>
                    <div className="vc-item px-0 pr-1">
                        <BackgroundImage className="vc-item-img sixteen-by-nine bg-center-cover  position-relative"
                            src={props.data.p} placeholder={Globals.placeholderPlain4x3}>
                        <div className="fa fa-play-circle play-btn-lg play-btn-sm"></div> 
                        </BackgroundImage>
                        {/* <div className="vc-item-img sixteen-by-nine bg-center-cover" style={{backgroundImage: 'url('+props.data.video.thumb+')'}}></div> */}
                        <div className="vc-item-h1 mt-3 mb-2 px-2 text-nowrap text-truncate m-f-12">
                            {/*props.data.video.match_obj.*/} 
                            {/* -  v Pak U19 W */}
                        </div>
                        <div className="vc-item-h2 px-2 truncate-v2 m-f-13">
                            {props.data.t}
                            {/* Day: 5 Session 5 Highlights Session 5 Highlights */}
                        </div>
                    </div>
                </div>

                {/* <VideosList data={props.data} seriesId={props.data.id}></VideosList> */}
                {/*getVideosList(props)*/}


                {/* <div className="vc-item px-0 pr-1">
                    <div className="vc-item-img sixteen-by-nine bg-center-cover" style={props.image}>

                    </div>
                    <div className="vc-item-h1 mt-3 px-2 text-nowrap text-truncate">
                        5th Test - Aus v Pak 
                    </div>
                    <div className="vc-item-h2 px-2 truncate-v2">
                        Day: 5 Session 5 Highlights 
                    </div>
                </div> */}

                <div className="clearfix"></div>

                </div>
            </div>

        </div>
);

export default Videos;
