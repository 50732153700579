import React, { Component } from 'react';

class Tests extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    };

    componentDidMount(){
    }

  render() {
    return(
      <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        yolo
      </section>
    )
  }

}


export default Tests;
