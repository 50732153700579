import React, { Component } from 'react';
import JWPlayerOnScrollViewResize from './JWPlayerOnScrollViewResize';
import { VideoCardDescription, MatchNav } from './HelperFunctions';
import ReactJWPlayer from 'react-jw-player';
import {Globals} from './constants';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BackgroundImage from 'react-background-image-loader';
import SingleVideo from './SingleVideo';

class MatchVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            matchVideosResp: {},
            videoIndex: 0,
            videoId: this.props.videoId,
            matchId: this.props.matchId,
            page: 1,
            pagesEnd: false,
            tag: 33,
            customCssTransition: 'h-transition',
            isLoading: true,
            tags: [],
            filterBalls: false,
            hasError: false

        };
        this.setAutoplay = this.setAutoplay.bind(this);
        this.playerReady = this.playerReady.bind(this);
        this.playerError = this.playerError.bind(this);
        this.videoLoad = this.videoLoad.bind(this);
        this.nextVideoLoad = this.nextVideoLoad.bind(this);
        this.calendarSelect = this.calendarSelect.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.singleVideoCase = this.singleVideoCase.bind(this);
        this.isMobile = window.innerWidth < 992;
        this.tagChange = this.tagChange.bind(this);
        this.getFromApi = this.getFromApi.bind(this);
        this.getTags = this.getTags.bind(this);
        this.refresh = this.refresh.bind(this);
    };

    scrollToTop(){
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    setAutoplay(e){
        let isChecked = this.state.isChecked
        let elem = document.getElementsByName('checkbox');
        if(elem.length){
            elem = elem[0]
            if(elem.checked){
                isChecked = 1;
            }else{
                isChecked = 0;
            }
            this.setState({isChecked: isChecked});
            localStorage.setItem('autoplay', isChecked)
        }
    }

    tagChange(e) {
        e.persist();
        console.log("tag chnage", e);
        let html = e.target.innerHTML;
        const selectedTagId = e.target.id;
        document.getElementById('videos-selection').innerHTML = html;
        const selectedTag = this.state.tags.filter(obj => obj.label===html);
        console.log("selected tag", selectedTag, selectedTagId, e.target.innerHTML)
        let filterBalls = false;
        if (selectedTag[0].show_all === false && selectedTag[0].filter_balls === true) {
            filterBalls = true;
        };
        this.setState({page: 1, tag: selectedTagId, pagesEnd: false, filterBalls: filterBalls}, () => {
            if (this.state.videoId && this.state.videoId !== 'v') {

                this.singleVideoCase(this.state.videoId)
    
            } else {
                
                this.getFromApi();
                
            };
        });
        
    };

    singleVideoCase(videoId) {
        const url = Globals.ApiUrl+"api/v2/user_api/get_new_timeline_items?match_id="+this.state.matchId.toString()
        +"&page="+this.state.page.toString()+"&tag_id="+this.state.tag+"&per_page=10&telco="+Globals.telco;

        const url2 = Globals.ApiUrl+"api/v1/user_api/get_single_video?id="+videoId+"&telco="+Globals.telco;

        const promise1 = new Promise( (resolve, reject) => {
            axios.get(url2).then(res => res.data.video ? resolve(res) : reject()).catch(err => reject(err));
        });

        const promise2 = new Promise( (resolve, reject) => {
            axios.get(url).then(res => resolve(res)).catch(err => reject(err));
        });

        Promise.all([promise1, promise2]).then(resp => {
            console.log("Promise all", resp)

            let singleVideo = [], mergedVideos = {}, mergedResponse = {}, hasError = false;

            singleVideo.push({video: resp[0].data.video});
            
            if (resp[1].data.timeline) {
                mergedVideos = singleVideo.concat(resp[1].data.timeline);
                mergedResponse = {...resp[1].data, ...{timeline: mergedVideos}};
            } else {
                mergedResponse.timeline = singleVideo;
            };
            console.log("Single video merged resp", mergedResponse)
            this.setState({ matchVideosResp: mergedResponse, videoId: videoId, 
            isLoading: false, customCssTransition: 'v-transition', hasError: hasError }, () => console.log("Set state ", this.state))              
            

        }).catch(err => {
            console.log(err);
            this.setState({ videoId: videoId, 
                isLoading: false, customCssTransition: 'v-transition', hasError: true }, () => console.log("Set state ", this.state))
        });
    }

    async getFromApi() {

        const url = Globals.ApiUrl+"api/v2/user_api/get_new_timeline_items?match_id="+this.state.matchId.toString()
        +"&page="+this.state.page.toString()+"&tag_id="+this.state.tag+"&per_page=10&telco="+Globals.telco;

        let response = {}, hasError = false;

        try {
            response = await axios.get(url);
            console.log("Match videos resp", response);
            
        }
        catch (err) {
            console.log(err);
        };
        if (!response.data.hasOwnProperty('timeline')) hasError = true;
        this.setState({ matchVideosResp: response.data, videoId: "v",
        isLoading: false, customCssTransition: 'v-transition', hasError: hasError }, () => console.log("Set state ", this.state))
         
        

    }

    async getTags() {
        const url = Globals.ApiUrl + "api/v2/user_api/get_tag_options_by_match?match_id="+this.state.matchId+"&telcotelco="+Globals.telco
        try {
            const resp = await axios.get(url);
            const showAllTag = resp.data.tags.filter(obj => obj.label.toLowerCase()==='show all');
            let filterBalls = false;
            if (showAllTag[0].show_all === false && showAllTag[0].filter_balls === true) {
                filterBalls = true;
            };

            this.setState({tags: resp.data.tags, isChecked: localStorage.getItem('autoplay'), 
            tag: showAllTag[0].id, filterBalls: filterBalls}, () => {
                if (this.state.videoId && this.state.videoId !== 'v') {

                    this.singleVideoCase(this.state.videoId)
        
                } else {
        
                    this.getFromApi(this.state.tag);
        
                };
            });
            console.log("tags", resp);
        }
        catch (err) {
            console.log(err);
        };
    }

    componentDidUpdate() {
        console.log("Componenet did update videos", this.props);
        if (this.props.videoId && this.props.videoId !== this.state.videoId && this.props.videoId !== 'v') {
            this.singleVideoCase(this.props.videoId)
            // this.getTags();
        };
        if (this.state.videoId !== 'v' && this.props.videoId === 'v') {
            this.getFromApi(this.state.tag);
            // this.getTags();
        };


    }

    refresh() {
        if(this.state.isRegionChecked) {
            this.setState({isLoading: true, hasError: false, customCssTransition: 'h-transition'}, this.getTags);
        }
        
    }

    async componentDidMount() {
        this.scrollToTop();
        this.getTags();

        console.log("Componenet did mount videos", this.props);

    }

    calendarSelect(e) {
        console.log(e);
    };
    playerReady(e) {
        if (!this.isMobile)
            JWPlayerOnScrollViewResize();
    }
    playerError(e) {
        
    }
    videoLoad(e) {
        console.log(e);
        if (e.index!==0) {
            const videosCopy = this.state.matchVideosResp.timeline.slice(1, this.state.matchVideosResp.timeline.length);
            console.log("Videos copy", videosCopy, "old videos", this.state.matchVideosResp.timeline);
            this.setState({matchVideosResp: {timeline: videosCopy}}, () => console.log("Next Video Loaded state", this.state));
        }
    }
    nextVideoLoad(e) {
        let elem = document.getElementsByName('checkbox')
        if(elem){
            // alert();
            elem = elem[0];
            if(elem.checked){
                let videoIndex = this.state.videoIndex;
                let videoElem = document.getElementsByClassName('mvlist');
                if(videoElem[videoIndex]){
                    if(this.state.videoId !== 'v'){
                        let v = videoIndex+ 1;
                        this.setState({videoIndex: v})
                    }
                    
                    videoElem[videoIndex].click();
                }
                
            }
        }
    }

    async nextPage(e) {

        if (!this.state.pagesEnd) {
            let elem = document.getElementsByClassName('loading-more-results');
            if(elem.length)
                elem[0].classList.remove('d-none');
      
          const page = this.state.page;
          const url = Globals.ApiUrl+"api/v2/user_api/get_new_timeline_items?match_id="+this.state.matchId.toString()
          +"&page="+page.toString()+"&tag_id="+this.state.tag+"&per_page=10&telco="+Globals.telco;
          let resp;
          try {
            resp = await axios.get(url);
            console.log("Next page", resp);
          }
          catch(err) {
            console.log("error fetching next page");
          };

          if (resp.data && resp.data.timeline && resp.data.timeline.length > 0) {
            if(elem.length)
                elem[0].classList.add('d-none');

            const moreVideos = this.state.matchVideosResp.timeline.concat(resp.data.timeline);  

            this.setState({page: page, matchVideosResp: {timeline: moreVideos}}, () => console.log("Next page state set", this.state));

          } else {
            if(elem.length)
                elem[0].classList.add('d-none');
                
            if (resp.data.timeline && resp.data.timeline.length===0) this.setState({pagesEnd: true});

            console.log("Api Error or no results", resp);
          };

        }

    }



  render() {
    let match, videos = [], playlist = [], title = '', description = '', date = '', file=[];
    if (this.state.matchVideosResp.hasOwnProperty('timeline') && this.state.matchVideosResp.timeline.length) {
        videos = this.state.matchVideosResp.timeline;
        playlist = this.state.matchVideosResp.timeline.map( obj => ( obj.video
         ? { file: obj.video.video_file } : '') );
        // if(this.state.matchVideosResp.timeline.length && this.state.matchVideosResp.timeline.video){ 
        //     file = {file: this.state.matchVideosResp.timeline[0].video.video_file}
        // }
        title = videos[0].video.title;
        description = videos[0].video.match_desc;
        date = videos[0].video.created_at;
    }
    
    return (
        <div>
        {
            (this.state.isLoading) ?
            <div className="mt-2 main-loader card min-height-80vh">
            <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }
        
        <div className={this.state.customCssTransition}>

        {
            (this.state.hasError) ?
            <div className="mt-2 has-error card min-height-80vh p-3">
            <h5> Error or no result <button className="btn btn-default" onClick={this.refresh}>Try Again</button></h5>
            </div> : <div></div>
        }

        {
            (this.state.matchVideosResp.hasOwnProperty('timeline')) ?

          <div className="card p-3 mt-2 min-height-80vh">

            {
                (this.state.matchVideosResp.hasOwnProperty('timeline') && !this.state.matchVideosResp.timeline.length) ?
                <h4>No Videos Available</h4> : <div></div>
            }

            <h4 className="text-uppercase mb-3 pl-2">{(match) ? match.short_name : ''} 
            </h4>
            {
                (videos.length) ?
                <div className="player-container">
                    <div className="player-position">
                        <ReactJWPlayer
                            playerId='player'
                            playerScript='https://content.jwplatform.com/libraries/IDzF9Zmk.js'
                            // playlist={playlist}
                            file = {videos[0].video.video_file}
                            onReady={this.playerReady}
                            onVideoLoad={this.videoLoad}
                            onOneHundredPercent={this.nextVideoLoad}
                            onSetupError = {this.playerError}
                        />
                    </div>
                </div> : <div></div>
            }
            <div className="px-2 pt-3 pb-0 d-flex ">
                <VideoCardDescription title={title} description={description} date={date} showDescription={true} showDate={true}></VideoCardDescription>
                
            </div>
            {(videos.length) ?
            <div className="d-flex">
                    <div className="ml-auto navbar-expand">
                        <ul className="navbar-nav mt-2 mt-lg-0">
                        <li className="nav-item">
                            <a data-original-title="Facebook" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href}>
                                <i className="fa fa-facebook"></i></a></li>
                        <li className="nav-item">
                            <a data-original-title="twitter" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" href={"http://www.twitter.com/share?text="+title+"&amp;url="+window.location.href+"/"}>
                                <i className="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a data-original-title="google-plus" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" href={"https://plus.google.com/share?url="+window.location.href+"/"}>
                                <i className="fa fa-google-plus google"></i>
                            </a>
                        </li>
                        </ul>
                    </div>
                </div>
                
            : <div></div> }

            <div className="text-right d-flex mb-3">
                <Dropdown tags={this.state.tags} tagChange={this.tagChange} defaultSelect="Show All"></Dropdown>
                <ToggleSwitch checked={this.state.isChecked} setAutoplay={this.setAutoplay}></ToggleSwitch>
            </div> 
            
            {
                videos.map( (obj, i) => 
                (i!==0 && (!this.state.filterBalls || (this.state.filterBalls && obj))) ?
                    <Link to={"/match/"+this.state.matchId+"/videos/"+obj.video.id} key={"sv-"+i} className="d-flex p-2 mvlist" onClick={this.scrollToTop}>
                        <div className="w-30 pr-3">
                            {/* <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: "url(" + obj.video.thumb + ")"}}></div> */}
                            <BackgroundImage className="sixteen-by-nine bg-center-cover" src={obj.video.thumb} placeholder={Globals.placeholderVideo4x3}>
                                 </BackgroundImage>
                        </div>
                        <VideoCardDescription data={obj.video} title={obj.video.title} description={obj.video.match_desc}
                         showDescription={true} showDate={true}></VideoCardDescription>
                    </Link> : <div key={"sv-"+i}></div>
                    )
            }
            
          </div> : <div></div>
          }
          {
            (!this.state.pagesEnd && videos.length) ?
            (
                <div>
                    <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" alt="" height="30" /></div>
                    <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
                </div>
            )
            : <div></div>
          }

          </div>
          
        </div>


    );
  }
}


export const ToggleSwitch = (props) => (
    <label className="switch mb-0 ml-auto align-self-center d-lg-block d-none"> 
        <span className="h5 font-weight-normal" style={{paddingRight: '40px'}}>Autoplay</span>
      <input name="checkbox" type="checkbox" checked={(props && props.checked)? true: false} onChange={(props && props.setAutoplay)? props.setAutoplay:''} />
      <span className="slider round"></span>
    </label>
);

export const Dropdown = (props) => (
    <div className="dropdown my-3">
        <button className="fa btn btn-secondary dropdown-toggle br-2rem px-3 py-2 mw-11rem d-flex justify-content-between
         align-items-center bg-white text-dark videos-menu border border-primary" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <h5 id="videos-selection" className="font-weight-normal app-font mb-0 text-secondary">{props.defaultSelect}</h5>
        </button>
        <div className="dropdown-menu custom-box mt-1" aria-labelledby="dropdownMenuButton">
            {
                props.tags.map( (tag, i) => 
                    
                    <button key={"tag-"+i} className="dropdown-item" id={tag.id} onClick={props.tagChange}>{tag.label}</button>

                )
            }
        </div>
    </div>
)

export default MatchVideos;
