import React, { Component } from 'react';
import {  VideoCard, NewsCard, getLiveMatchButton, MatchNav, MatchScorecardSection, MatchHighlightBar,
MatchScorecardTable, MatchScorecardTableBowling, BallByBall, BallByBallDetail, ScrollRightBallByBall } from './HelperFunctions';
import { Link } from 'react-router-dom';
import { BallByBallSwipe } from './InitializePlugins';
import { Globals } from './constants';
import JWPlayerOnScrollViewResize from './JWPlayerOnScrollViewResize';
import axios from 'axios';
import Pusher from 'pusher-js';
import MatchSquads from './MatchSquads';
import ReactJWPlayer from 'react-jw-player';
import MatchScorecard from './MatchScorecard';
import MatchVideos from './MatchVideos';

class Match extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matchSummaryResp: {},
            ballByBallDetail: [],
            squads: [],
            activeSquadsId: null,
            matchId: this.props.match.params.id,
            category: this.props.match.params.category,
            subcategory: this.props.match.params.subcategory,
            overPageEnd: false,
            allowLiveStream: false,
            customCssTransition: 'h-transition',
            isLoading: true,
            loadingPreviousBalls: false,
            selectedOver: 0,
            matchButtonText: 'Watch Now!',
            hasError: false,
            hasErrorSquads: false
        };
        this.isMobile = window.innerWidth < 992;
        this.handleSwipe = BallByBallSwipe.bind(this); 
        this.windowLoadListener = false;
        this.promiseHandler = this.promiseHandler.bind(this);
        this.setMatchSummaryFromApi = this.setMatchSummaryFromApi.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getPreviousOvers = this.getPreviousOvers.bind(this);
        this.playerReady = this.playerReady.bind(this);
        this.playLiveStream = this.playLiveStream.bind(this);
        this.setSquads = this.setSquads.bind(this);
        this.ballByBallScroll = this.ballByBallScroll.bind(this);
        this.ballByBallSelect = this.ballByBallSelect.bind(this);
        this.refreshMatchSummary = this.refreshMatchSummary.bind(this);
        this.getMatchSquads = this.getMatchSquads.bind(this);

        this.pusher = new Pusher(Globals.pusherKey, {
          cluster: 'ap1',
          encrypted: true
        });
        this.channel = this.pusher.subscribe('live_matches');
        // if (!localStorage.getItem('user')) window.location.href = '/login';

        console.log("Router location props", this.props.location, this.props.match);
    };

    playLiveStream(e) {
        console.log("Playing live stream", this.state);
        (!this.state.allowLiveStream) ?
        this.setState( { allowLiveStream: true, matchButtonText: 'Close!' }, () => console.log('live stream allowed', this.state) )
        : 
        this.setState( { allowLiveStream: false, matchButtonText: 'Watch Now!' }, () => console.log('live stream allowed', this.state) )
    }

    ballByBallScroll(e) {
        e.persist();
        console.log("Ball by ball scroll", e);
        console.log("Ball by ball scroll pos", e.target.scrollLeft);
        const scrollPos = e.target.scrollLeft;
        if (scrollPos === 0) {
            this.getPreviousOvers();
        };
    }

    ballByBallSelect(overNumber) {
        // e.persist();
        // console.log("Selected over", overNumber)
        // console.log("Selected ball node", e)
        // const oversTitle = e.target.firstChild.data || e.target.innerText || e.target.innerHTML || "Over 0";
        // const splitBySpaceArray = oversTitle.split(' ')[1];
        // const selectedOverNumber = (splitBySpaceArray) ? splitBySpaceArray.split('\n')[0] : 0;
        // console.log("Selected over", selectedOverNumber, splitBySpaceArray, oversTitle, e);
        // console.log(e);
        const selectedOverNumber = overNumber;
        if (selectedOverNumber !== this.state.selectedOver) {
            this.setState({selectedOver: selectedOverNumber});
        }   
    }

    async setMatchSummaryFromApi(matchId, category, subcategory) {
        const matchSummaryApiUrl = Globals.ApiUrl + "api/v3/matches/" + matchId + "/summary";
        let matchSummaryResp = {},
            ballByBallDetail = [],
            lastOver = 0,
            isOverSelected = false;
        
        try {

            const resp = await axios.get(matchSummaryApiUrl);
            console.log("Match Summary response", resp);
            matchSummaryResp = resp.data;
 
        } catch (err) {
            console.log("Match Summary Api Error", err);
            // window.location.href = '/404notfound';
        };

        if (!matchSummaryResp.match) {
            this.setState({hasError: true});
        }

        if (!matchSummaryResp.hasOwnProperty('overs')) {
            matchSummaryResp.overs = [];
        }

        if (matchSummaryResp.match && matchSummaryResp.match.match_state.toLowerCase().indexOf('over') > -1) {
            const overMatchesBallsUrl = Globals.ApiUrl + "api/v1/matches/" + matchId + "/summary"
            try {
                const resp2 = await axios.get(overMatchesBallsUrl);
                console.log("Match over resp", resp2);
                matchSummaryResp.overs = resp2.data.overs.slice();

            }
            catch (err) {
                console.log(err);
            }
        }

        matchSummaryResp.overs.forEach( obj =>  {
            let balls = obj.balls.slice();
            ballByBallDetail.push({...obj, balls});
        });


        lastOver = matchSummaryResp.overs.length ? matchSummaryResp.overs[0].title.split(' ')[1] : 0;


        if (this.state.selectedOver !== 0 && this.state.selectedOver !== lastOver) { // if over already selected
            lastOver = this.state.selectedOver;
            isOverSelected = true;
        };


        console.log("bb detail", ballByBallDetail);

        matchSummaryResp.overs = matchSummaryResp.overs.reverse().map( (obj, i) => {
            return {title: obj.title, balls: obj.balls.reverse()} 
        });

        if (category==='squads') {

            this.setSquads(category, subcategory, matchId, matchSummaryResp, ballByBallDetail)

        } else {
            let allowStream = false, buttonText = "Watch Now!";
            if (subcategory === 'allowStream') {
                allowStream = true;
                buttonText = "Close!"
            }
            this.setState({ category: category, subcategory: subcategory, matchId: matchId,
                matchSummaryResp: matchSummaryResp, ballByBallDetail: ballByBallDetail, isLoading: false,
                customCssTransition: 'v-transition', selectedOver: lastOver, allowLiveStream: allowStream, matchButtonText: buttonText }, () => {
                    console.log("Match summary set state", this.state)

                    this.channel.bind('refresh', this.refresh);
                    if (!isOverSelected) {
                        ScrollRightBallByBall();
                    };
                    if (this.state.matchSummaryResp.match && this.state.matchSummaryResp.match.match_state.toLowerCase().indexOf('live') > -1) { //match is live
                        this.pusherSubscriptions();
                    };

                });
        };
        
    }

    async setSquads(category, subcategory, matchId, matchSummaryResp, ballByBallDetail) {
        const teamId = (subcategory==="2") ? matchSummaryResp.match.team_2_id : matchSummaryResp.match.team_1_id;
        const squadUrl = Globals.ApiUrl+"api/"+teamId.toString()+"/"+matchId.toString()+"/squad";
        let squadsData = [], hasErrorSquads = false;

        try {
            const resp2 = await axios.get(squadUrl);
            squadsData = Array.isArray(resp2.data) ? resp2.data.slice() : [];
        } catch (err) { console.log(err); };

        if (!squadsData.length) hasErrorSquads = true;

        this.setState({ hasErrorSquads: hasErrorSquads, category: category, subcategory: subcategory, matchId: matchId,
            matchSummaryResp: matchSummaryResp, ballByBallDetail: ballByBallDetail,
             squads: squadsData, activeSquadsId: teamId, isLoading: false, customCssTransition: 'v-transition' });
    }

    componentWillReceiveProps(nextProps) {
        console.log("Next props", nextProps);
        let matchId = nextProps.match.params.id, 
        category = nextProps.match.params.category,
        subcategory = nextProps.match.params.subcategory;

        if (matchId !== this.state.matchId) {

            this.setState({isLoading: true, hasError: false, customCssTransition: 'h-transition', allowLiveStream: false, selectedOver: 0}, () => 
                    
                this.setMatchSummaryFromApi(matchId, category, subcategory)

            );
    

        } else if (category !== this.state.category || subcategory !== this.state.subcategory) {

            if (category === 'squads') {
            
                // this.setState({isLoading: true, customCssTransition: 'h-transition'}, () => 
                    
                    this.setSquads(category, subcategory, matchId, this.state.matchSummaryResp, this.state.ballByBallDetail)

                // );
    
                
    
            } else {

                let allowStream = false, buttonText = "Watch Now!";
                if (subcategory === 'allowStream') {
                    allowStream = true;
                    buttonText = "Close!"
                }
                this.setState({ category: category, subcategory: subcategory, allowLiveStream: allowStream, matchButtonText: buttonText }, () => {
                    if (category === 'summary' || category === 'ballbyball') {
                        ScrollRightBallByBall();
                    }
                });


        

            };

        };

    }

    async getPreviousOvers() {
        this.setState({ loadingPreviousBalls: true });
        const matchOvers = this.state.matchSummaryResp.overs;
        const lastOver = matchOvers[0].balls[0].over_number;
        const innings = this.state.matchSummaryResp.match.innings;

        const inningsId = innings.map( obj => (obj.innings_order === innings.length) ? obj.id : null ).filter( obj => obj );
        console.log("innings id", inningsId);
        const url = Globals.ApiUrl + "api/v3/innings/" + inningsId.toString() + "/get_previous_overs?over_number=" + lastOver.toString();
        let ballByBallDetail = this.state.ballByBallDetail.slice();
        console.log("url", url);

        try {
            const response = await axios.get(url);
            if (response.status === 200 && response.data.status && response.data.overs.length) {
                console.log("Previous overs", response.data.overs);
                let matchSummaryRespCopy = Object.assign({}, this.state.matchSummaryResp);
                matchSummaryRespCopy.overs = response.data.overs.slice().reverse().concat(matchSummaryRespCopy.overs);
                ballByBallDetail = ballByBallDetail.concat(response.data.overs);

                this.setState({ matchSummaryResp: matchSummaryRespCopy, ballByBallDetail: ballByBallDetail, loadingPreviousBalls: false }, () => {
                    console.log("Set state previous overs", this.state)
                    let elem = document.getElementsByClassName('bbb-container');
                    if(elem){
                        elem = elem[0]
                        elem.scrollTo(10, 0);
                    }
                });
                // ballByBallDetail = ballByBallDetail.concat(response.data.overs);
                // this.setState({ ballByBallDetail: ballByBallDetail }, () => console.log("Next over state set", this.state));

            }
            else if (!response.data.overs.length) {
                this.setState({overPagesEnd: true, loadingPreviousBalls: false});
            };
            console.log("next page response", response);

        }
        catch (err) {
            console.log("error getting next page", err);
            this.setState({loadingPreviousBalls: false});
        };

    }

    pusherSubscriptions() {
        console.log("Pusher subscribe");
        const channelKeys = ['partnership_update', 'ball_by_ball_update', 'match_update'];
        const promises = channelKeys.map( obj =>
                new Promise( resolve => this.channel.bind(obj, (data) => resolve(data)) )
            );
        
        Promise.all(promises).then(data => {
            console.log("promise all data", data)

            if (data[1].match_obj.id === this.state.matchSummaryResp.match.id) {
                
                console.log("calling promise handler")
                this.promiseHandler(data)

            } 
            else {
                this.pusherSubscriptions();
            };
        
        }).catch(err => {
            console.log("Promise all error", err);
            this.setMatchSummaryFromApi(this.state.matchId, this.state.category, this.state.subcategory);
            return;
        });
    }

    refreshMatchSummary() {
        this.setState({isLoading: true, hasError: false, customCssTransition: 'h-transition'}, () =>
            this.setMatchSummaryFromApi(this.state.matchId, this.state.category, this.state.subcategory));
    }

    async componentDidMount() {
        console.log("Match did mount")
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

        const { match: { params } } = this.props;
        if (document.readyState === 'complete') { // if document has finished loading all the scripts
            this.handleSwipe(); // call videos swipe initialization
        } else { // if document is still loading scripts i.e readyState === 'loading'
            this.windowLoadListener = true; // Set listener to true to remove it in component will unmount react lifecycle method
            window.addEventListener('load', this.handleSwipe); // add listener
        };
        this.setMatchSummaryFromApi(params.id, params.category, params.subcategory);
        
    }
    refresh(data) {
        console.log("Live match refresh", data);
        
        if (data.match_id === this.state.matchSummaryResp.match.id) {
            this.setMatchSummaryFromApi(this.state.matchId, this.state.category, this.state.subcategory);
        };

    }

    promiseHandler(pusherData) {
        console.log("Match updated--->> Match page", pusherData);

        if ( !pusherData || !pusherData.length || (parseInt(this.state.matchId) !== pusherData[0].id 
        || !(this.state.category === 'summary' || this.state.category === 'ballbyball') ) ) {

            console.log("not this match push", this.state.matchId, pusherData[0].id);
            this.pusherSubscriptions();
            if (this.state.category === 'videos') {
                if (this.state.videoId && this.state.videoId !== 'v') {

                    this.singleVideoCase(this.state.videoId)

                } else {

                    this.getFromApi(this.state.tag);

                };
            };
            
        } else {

            const matchSummaryRespCopy = Object.assign({}, this.state.matchSummaryResp);
            const mergedMatchObject = {...matchSummaryRespCopy.match, ...pusherData[2], ...pusherData[0]};
            
            if (!mergedMatchObject.partnership.hasOwnProperty('fall_of_wickets')) {
                mergedMatchObject.partnership.fall_of_wickets = this.state.matchSummaryResp.match.partnership.fall_of_wickets.slice();
            }

            console.log("Merged match Object", mergedMatchObject);

            let matchOversArray = matchSummaryRespCopy.overs.slice();

            const lastOverIndex = matchOversArray.length-1;
            const lastOverBalls = matchOversArray[lastOverIndex].balls;
            const ballOrders = lastOverBalls.map( obj => obj.ball_order );
            const lastBallIndex = Math.max(...ballOrders);
            const lastOver = matchOversArray[lastOverIndex];
            const lastBall = lastOver.balls[lastOver.balls.length-1];
            const lastBallWideOrNo = (lastBall.no_ball || lastBall.wide_ball);
            const isLastBall = (lastBallIndex === 6) && !(lastBallWideOrNo);
            const nextBallIndex = pusherData[1].ball.ball_order;

            let isFirstBall = false;

            if (matchOversArray.length) {

                if ( (lastBallWideOrNo && !(lastBallIndex === nextBallIndex) ) // if ball order is not correct
                    || (!isLastBall && !( (lastBallIndex+1) === nextBallIndex) )
                    || (isLastBall && nextBallIndex !== 1) ) {

                        this.setMatchSummaryFromApi(this.state.matchId, this.state.category, this.state.subcategory);
                        return;
                
                };

                if ( isLastBall ) { // pushed ball is first ball of next over

                    matchOversArray.push({balls: [], title: "over " + pusherData[1].ball.over_number})
                    matchOversArray[matchOversArray.length-1].balls.push(pusherData[1].ball);

                    isFirstBall = true;

                } else {

                    lastOverBalls.push(pusherData[1].ball);

                };

            } else { // if first ball of inning

                matchOversArray.push({balls: [], title: "over " + pusherData[1].ball.over_number})
                matchOversArray[0].balls.push(pusherData[1].ball);

                isFirstBall = true;

            };

            console.log('Updated Match Overs list', matchOversArray);

            const finalResponse = {...matchSummaryRespCopy, ...{overs: matchOversArray}, ...{match: mergedMatchObject} };

            const ballByBallDetail = finalResponse.overs.slice().reverse().map( (obj, i) => {
                return {title: obj.title, balls: obj.balls.slice().reverse()} 
            });

            console.log("Before set state pusher", this.state);

            let selectedOver = finalResponse.overs.length ? finalResponse.overs[finalResponse.overs.length-1].title.split(' ')[1] : 0;
            let isOverSelected = false;

            console.log("selected over", selectedOver)

            if ( (this.state.selectedOver !== selectedOver) && !(isFirstBall && (parseInt(this.state.selectedOver)+1) === selectedOver)) { // if over already selected
                selectedOver = this.state.selectedOver;
                isOverSelected = true;
            };

            this.setState({ matchSummaryResp: finalResponse, ballByBallDetail: ballByBallDetail, selectedOver: selectedOver }, () => {
                console.log("After push set state", this.state);
                this.pusherSubscriptions()
                if (!isOverSelected) {
                    ScrollRightBallByBall();
                };
            });
            
            console.log("merged response", mergedMatchObject);    
        
        };
    }

    componentWillUnmount() {
        if (this.windowLoadListener) window.removeEventListener('load', this.handleSwipe); //remove listener if listening
    }

    playerReady(e) {
        if (!this.isMobile)
            JWPlayerOnScrollViewResize();
    }

    getMatchSquads() {
        if (this.state.hasErrorSquads) {
            return <div className="has-error card min-height-80vh p-3 mt-2">
                <h5> Error or no result <button className="btn btn-default" onClick={this.refreshMatchSummary}>Try Again</button></h5>
            </div>
        } else {
            return <MatchSquads match={this.state.matchSummaryResp.match}
            activeSquadsId={this.state.activeSquadsId} data={this.state.squads} customCssTransition={this.state.customCssTransition} />
        }
    }

  render() {
    const playlist = [{
        file: (this.state.matchSummaryResp.hasOwnProperty('match')) ? this.state.matchSummaryResp.match.live_stream_url : null
      }
    ];
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
           
            {
            (this.state.isLoading) ?
                <div className="main-loader card min-height-80vh">
                <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
                </div> : <div></div>
            }
            
            <div className={this.state.customCssTransition}>

            {
                (this.state.hasError) ?
                <div className="has-error card min-height-80vh p-3 mt-2">
                <h5> Error or no result <button className="btn btn-default" onClick={this.refreshMatchSummary}>Try Again</button></h5>
                </div> :  <div className="card p-3">
                    <h4 className="text-uppercase font-weight-bold mb-2 mb-lg-3 pl-2 m-f-14">
                        {
                            (this.state.matchSummaryResp.match) ? this.state.matchSummaryResp.match.series.title : ''
                        } 
                        {/* <span className="text-secondary font-weight-normal pl-2"> UAE 2018</span> */}
                    </h4>
                    <MatchNav category={this.state.category} matchId={this.state.matchId}></MatchNav>
                </div>
            }

            {
                (this.state.category === 'squads') ? 
                this.getMatchSquads() : <div></div>

            }

            {
                (this.state.category === 'scorecard') ? <MatchScorecard matchId={this.state.matchId} /> : <div></div>

            }

            {
                
                (this.state.category === 'videos') ? <MatchVideos videoId={this.state.subcategory} matchId={this.state.matchId} /> : <div></div>

            }

            {
                (this.state.category === 'summary' && this.state.matchSummaryResp.match) ? 
                <div className="card mt-2 p-3">
                    {
                        (this.state.allowLiveStream) ?
                        <div className="player-container mb-2">
                            <div className="player-position">
                                <ReactJWPlayer
                                    playerId='player'
                                    playerScript='https://content.jwplatform.com/libraries/IDzF9Zmk.js'
                                    playlist={playlist}
                                    onReady={this.playerReady}
                                />
                            </div>
                        </div> : <div style={{height: 0}}></div>
                    }
                    
                    <MatchScorecardSection matchButtonText={this.state.matchButtonText} 
                    playLiveStream={this.playLiveStream} 
                    data={this.state.matchSummaryResp.match}>
                    </MatchScorecardSection>
                    <MatchHighlightBar data={this.state.matchSummaryResp.match}></MatchHighlightBar>
                    <MatchScorecardTable data={this.state.matchSummaryResp.match} showPlayerDetailText={false}></MatchScorecardTable>
                    <MatchScorecardTableBowling data={this.state.matchSummaryResp.match} showPlayerDetailText={false}></MatchScorecardTableBowling>

                </div> : <div></div>
            }

            
    
        {
            ((this.state.category === 'ballbyball' || this.state.category === 'summary') && this.state.ballByBallDetail.length) ?
            <div className="card mt-2 pb-3">

                <BallByBall loadingPreviousBalls={this.state.loadingPreviousBalls}
                 scroll={this.ballByBallScroll} data={this.state.matchSummaryResp.overs}
                 ballByBallSelect={this.ballByBallSelect}></BallByBall>
                {
                    this.state.ballByBallDetail.map(obj => (obj.title.split(' ')[1] === this.state.selectedOver) ? obj.balls.map( (ball, i) => 
                        <BallByBallDetail key={"bbb-detail-"+i} data={ball}></BallByBallDetail>     
                    ) : '')
                }
            </div> : <div></div>
        }

        {
            (this.state.category === 'ballbyball' && !this.state.ballByBallDetail.length) ?
            <div className="card p-3 mt-2">
                <h4>No Ball By Ball available</h4>
            </div> : <div></div>
        }
    
        {
            
            (this.state.category === 'summary' && this.state.matchSummaryResp.timeline_videos && 
            this.state.matchSummaryResp.timeline_videos.timeline.length) ?
            <div className="card mt-2 px-3 pb-3 pt-4">
                { 
                    this.state.matchSummaryResp.timeline_videos.timeline.map( (obj, i) =>
                    
                        <VideoCard 
                            key={"ms-videos-"+i} 
                            image={obj.video.thumb} 
                            matchTitle={obj.video.match_obj.title}
                            title={obj.video.title}
                            matchId={this.state.matchSummaryResp.match.id}
                            id={obj.video.id}
                            showDescription={false}>
                        </VideoCard>

                    )
                }
            </div> : <div></div>
        }
        {
            (this.state.category === 'summary' && this.state.matchSummaryResp.articles) ?
            <div className="card mt-2 px-3 pb-3 pt-4">    

                {
                    
                    this.state.matchSummaryResp.articles.map( (obj, i) =>

                        <NewsCard key={"ms-articles-"+i} image={obj.image} 
                        id={obj.id}
                        title={obj.title} date={obj.created_at} />
                    
                    )

                }

            </div> : <div></div>
        }

        {/* {
            (!this.state.overPagesEnd && this.state.matchSummaryResp.overs && this.state.category === 'ballbyball' && 
            this.state.ballByBallDetail.length) ?
            <button onClick={this.getPreviousOvers} type="button" className="btn btn-primary">Previous Overs</button>
            : <div></div>
        } */}
                  
                          
        </div>
                

        </section>


    )
  }
}

export default Match;
