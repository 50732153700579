import React, { Component } from 'react';

class RefundPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
        return <section id="main-section" className="jc-inner-page align-self-start px-3 py-3">
            
            <h2 className="d-flex align-center justify-content-center">HBL PSL Match Ticket Refund Policy</h2>
           <br/>
             <p>If play is washed out without the toss having taken place a 100% refund is claimable.
                If play does not take place at the ground on the day for which this ticket is valid, a 100 % ticket
                refund will be claimable
                If play is restricted to less than 5 overs in one innings, a 75 % ticket refund will be claimable.
                If play is restircited to 5 completed overs but less than 10 overs cumulative a 50 % refund is
                claimable
                If a cumulative of 10 overs or above take place no refund will be claimable
                For the avoidance of doubt, if 10 or more overs cumulative are played, then no refund will be
                claimable
            </p>
            <h4>Hospitality Boxes and Tickets</h4>
            <p>If play is washed out without the toss having taken place and a Hospitality Ticket is not validated
                a 100 % refund is claimable
                If no toss or play has taken place and a Hospitality Ticket is validated a 50 % refund is claimable
                If the toss has taken place and a ticket has been validated no refund will be claimable.
                The refund policy outlined above only applies in the circumstances outlined herein and does not
                apply for any other reason, inter alia, if:
                 The match is completed early in the normal course of play; or
                 For lost or unused tickets.
                The Refund Policy above applies to Express Center, Box Office & Online Ticket Sales as well as
                Corporate Bookings and Hospitality Sales through the Official ticketing Partner of PCB and in the
                the case of Hospitality Sales by PCB directly.
                Within 5 days after the relevant match for which a refund may be claimed PCB shall announce the
                dates and procedures for refunds of tickets through a Media Release and on its official Digital
                Platforms.
            </p>
            <h4>Terms & Conditions of Sale</h4>
            <p>These conditions govern all tickets and admission to any venue for the match(es) specified on the
            ticket. A ticket holder shall, by a ticket, be deemed to have been made aware of and to have
            accepted and be bound by the conditions. Defined terms used below shall have the iven in these
            conditions.
            The Conditions incorporate the Venue Regulations and the Ticket Refund Policy.
            Tickets must not be:
            </p>
            <ul>
                <li>Offered publicly (including on any website) for sale;</li>
                <li>Offered, transferred or used in the course of any business or otherwise for commercial 
                    gain;
                </li>
                <li>Offered, transferred or used in relation to any promotional purpose, as a prize or
                    otherwise bundled with any other goods or services (including as part of any hospitality or
                    travel package), in each case without the prior written authorization of the Pakistan
                    Cricket Board through its competent authority in writing.
                </li>
                <li>
                    Please keep your ticket with you at all times. Pakistan Cricket Board and/or any
                    Authorized Person may refuse admission to or eject from the Venue without refund a
                    Ticket holder who, in their reasonable opinion:
                    <ul>
                        <li>Does not produce a valid ticket upon request;</li>
                        <li>Is in breach of any of the Conditions;</li>
                        <li>Is in breach of any applicable law, regulation, condition or policy.</li>
                        <li>Fails to comply with instructions from any authorized person, police, or other
                        security officer;</li>
                        <li>Enters any area within the Venue where he/she is not permitted (including the
                        playing field); and/or</li>
                        <li>Is in possession of a Prohibited Item.</li>
                    </ul>
                </li>
            </ul>
            <p>It shall be a breach of the Conditions for any Ticket holder to engage in any conduct (whether
                through the use of language, gestures or otherwise) which is likely to offend, insult, humiliate,
                intimidate, threaten, disparage or vilify any other person (including players, match officials or
                spectators) on the basis of their race, religion, culture, colour, descent, national or ethnic origin.
                Such conduct will not be tolerated and is likely to result in ejection from the Venue.
                It shall be a breach of the conditions for any Ticket holder to engage in any form of ambush
                marketing or to conduct any activity that conflicts with, impairs, infringes or denigrates the rights
                of any commercial partner.
                A valid ticket permits the Ticket holder admission to the Venue and to view the Match(es) to
                which it relates from the seat or area specified on the Ticket. Please note that ticket holders who
                leave the venue at any time during play must ensure they retain their ticket stub and must
                present the stub in order to re-enter the venue.
                Pakistan Cricket Board reserves the right at its discretion to make alterations to the time, date
                and/or venue of any Match
                Each person who is 4 years of age or older seeking admission to a venue will require his/her own
                ticket. For the avoidance of doubt any person requiring a seat will require a ticket.
                Prohibited Items include; Glass bottles and cans, fireworks, flares, weapons, firearms, and any
                other items considered dangerous by the Venue Security Officials.
                Ticket holders shall not themselves conduct any form of betting or gambling at or around the
                Venue nor facilitate the conduct by a third party of any form of betting or gambling at or around 
                the Venue or elsewhere in the world by any means whatsoever (including the use of any form of
                electronic or mobile device).
                Photographs and/or any other recordings of sounds made or images taken within a Venue may be
                used for personal, private, non-commercial and non-promotional purposes only.
                The Ticket holder acknowledges that his/her presence at the Venue is at his/her own risk and that
                the Pakistan Cricket Board and Official Ticketing Agents are Not liable for any loss, damage or
                harm howsoever arising from the Match and his/her attendance at the Venue.
                </p>
        </section>
  }
}

export default RefundPolicy;