import React, { Component } from "react";

class Mystars11 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section
        id="main-section"
        className="jc-inner-page align-self-start px-3 py-3"
        style={{
          position: `relative`,
        }}
      >
        <iframe
          title="Free Fantasy"
          src="https://ff.cricwick.net/?utm_source=psl&utm_medium=web&utm_campaign=MyStar11"
          style={{ width: "100%", height: "1080px" }}
          frameBorder="0"
        />
      </section>
    );
  }
}

export default Mystars11;
