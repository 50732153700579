import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Globals } from "./constants";

class Nav extends Component {
  getTelcoLogo() {
    return Globals.telcoLogo ? (
      <span className="telco-logo ml-3 ml-lg-4">
        <img src={Globals.telcoLogo} alt="" />
      </span>
    ) : (
      ""
    );
  }
  render() {
    const isMobile = window.innerWidth < 992;
    return (
      <nav
        id="main-nav"
        className="navbar navbar-expand-lg navbar-light py-0 shadow-md mb-2 fixed-top"
      >
        <div
          className={isMobile ? "w-100 text-center" : "container w-1150px-lg"}
        >
          <button
            id="navToggler"
            className="navbar-toggler p-0 collapsed position-absolute top-25px left-10px"
            type="button"
            data-toggle="collapse"
            data-target="#navbarContent"
          >
            <i className="fa fa-bars text-white" />
          </button>

          {isMobile && (
            <Link
              to="/"
              className="navbar-brand mr-auto mx-auto p-0 pb-lg-2 pt-lg-1 pl-lg-0 mr-lg-5 order-0"
            >
              <img
                alt=""
                width={180}
                height={`auto`}
                src="/images/psl-logo-2023.png"
              />
            </Link>
          )}

          <div
            className="collapse navbar-collapse m-0 p-0 order-2 order-lg-1 w-100"
            id="navbarContent"
          >
            <ul className="navbar-nav ml-0 align-items-center justify-content-between">
              <div
                className={
                  !isMobile ? "d-flex justify-content-end w-550px" : ""
                }
              >
                <li
                  className={"nav-item px-3 position-relative padding-left-0"}
                >
                  <a
                    className="nav-link dropdown-toggle d-flex align-items-center text-uppercase font-weight-normal"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Latest</span>
                    <i className="fa fa-chevron-down ml-1" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link className="dropdown-item" to="/latest/latest-news">
                      Latest News
                    </Link>
                    <Link className="dropdown-item" to="/latest/press-release">
                      Press Release
                    </Link>
                  </div>
                </li>

                {/* <li className={"nav-item px-3 position-relative"}>
                  <a
                    className="nav-link dropdown-toggle d-flex align-items-center text-uppercase font-weight-normal"
                    href="#"
                    id="navbarDropdown3"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Gallery</span>
                    <i className="fa fa-chevron-down ml-1" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown3"
                  >
                    <Link className="dropdown-item" to="/videos">
                      Videos
                    </Link>
                    <Link className="dropdown-item" to="/photos">
                      Photos
                    </Link>
                  </div>
                </li> */}

                {/* <li className={"nav-item px-3 position-relative"}>
                  <a
                    className="nav-link dropdown-toggle d-flex align-items-center text-uppercase font-weight-normal"
                    href="#"
                    id="navbarDropdown4"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Tickets</span>
                    <i className="fa fa-chevron-down ml-1" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown4"
                  >
                    <Link className="dropdown-item" to="/tickets">
                      Tickets
                    </Link>
                    <Link className="dropdown-item" to="/news/1112">
                      Refund Update
                    </Link>
                    <a
                      className="dropdown-item"
                      href="https://storage.cricingif.com/psl-prod-images/static-assets/HBL_PSL_Refund_Outlets.pdf"
                    >
                      List of Refund Outlets
                    </a>
                    <Link className="dropdown-item" to="/faq">
                      Frequently Asked Questions
                    </Link>
                    <Link className="dropdown-item" to="/refund-policy">
                      Refund Policy
                    </Link>
                  </div>
                </li> */}

                <li className={"nav-item nav-dropdown px-3 position-relative"}>
                  <a
                    className="nav-link dropdown-toggle d-flex align-items-center text-uppercase font-weight-normal"
                    href="#"
                    id="navbarDropdown2"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Teams</span>
                    <i className="fa fa-chevron-down ml-1" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown2"
                  >
                    <Link className="dropdown-item" to="/teams/4586">
                      Peshawar Zalmi
                    </Link>
                    <Link className="dropdown-item" to="/teams/4587">
                      Lahore Qalandars
                    </Link>
                    <Link className="dropdown-item" to="/teams/4583">
                      Karachi Kings
                    </Link>
                    <Link className="dropdown-item" to="/teams/4588">
                      Islamabad United
                    </Link>
                    <Link className="dropdown-item" to="/teams/4585">
                      Quetta Gladiators
                    </Link>
                    <Link className="dropdown-item" to="/teams/4584">
                      Multan Sultans
                    </Link>
                  </div>
                </li>

                {/* <li
                  id="watchPSL"
                  className={
                    this.props.currentPage === "/watchpsl"
                      ? "active nav-item px-3"
                      : "nav-item px-3"
                  }
                >
                  <Link
                    to="/watchpsl"
                    className="nav-link text-uppercase ff-gotham-medium"
                  >
                    Watch PSL7
                  </Link>
                </li> */}
                <li
                  id="fixtures"
                  className={
                    this.props.currentPage === "/fixtures"
                      ? "active nav-item px-3"
                      : "nav-item px-3"
                  }
                >
                  <Link
                    to="/fixtures/1493"
                    className="nav-link text-uppercase ff-gotham-medium"
                  >
                    Fixtures
                  </Link>
                </li>
                

                {/* <li id="buy-tickets" className={(this.props.currentPage==="/stories") ? 'active nav-item px-3' : 'nav-item px-3'}>
                                <a href="https://www.q-tickets.com/psl" target="_blank" 
                                className="nav-link text-uppercase ff-gotham-medium">Buy Tickets</a>
                            </li> */}
              </div>

              {!isMobile && (
                <Link
                  to="/"
                  style={{
                    width: `300px`,
                  }}
                >
                  <div className={`d-flex justify-content-center`}>
                    <img
                      width="90%"
                      height={`34%`}
                      src="/images/psl-logo-2023.png"
                      style={{ padding: "0px 20px" }}
                    />
                  </div>
                </Link>
              )}

              <div
                className={!isMobile ? "d-flex justify-content w-550px" : ""}
              >
               
                <li className="nav-item nav-dropdown px-3 position-relative">
                  <a
                    className="nav-link dropdown-toggle d-flex align-items-center text-uppercase font-weight-normal"
                    href="#"
                    id="navbarDropdown4"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Results</span>
                    <i className="fa fa-chevron-down ml-1" />
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown4"
                  >
                    <Link className="dropdown-item" to="/fixtures/1345">
                      Season 6
                    </Link>
                    <Link className="dropdown-item" to="/fixtures/616">
                      Season 5
                    </Link>
                    <Link className="dropdown-item" to="/fixtures/350">
                      Season 4
                    </Link>
                    <Link className="dropdown-item" to="/fixtures/112">
                      Season 3
                    </Link>
                  </div>
                </li>

                <li className="nav-item nav-dropdown px-3 position-relative padding-right-0">
                  <Link
                    className="nav-link text-uppercase ff-gotham-medium"
                    to="/hamaray-heroes"
                  >
                    Hamaray Heroes
                  </Link>
                </li>
                {/* <li className="nav-item nav-dropdown px-3 position-relative padding-right-0">
                  <Link
                    className="nav-link text-uppercase ff-gotham-medium"
                    to="/watchpsl"
                  >
                    Watch HBL PSL 7
                  </Link>
                </li> */}
                <li className="nav-item nav-dropdown px-3 position-relative padding-right-0">
                  <a
                    className="nav-link text-uppercase ff-gotham-medium" style={{cursor: "pointer"}}
                    onClick={()=> window.open("https://cricwick.net/fantasy", "_blank")}
                  >
                    Fantasy League
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default withRouter(Nav);
