import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { StoriesCard } from './Stories';
import { VideoCardDescription } from './HomeSeriesCard';
import ImageLoader from 'react-load-image';

import {API_URL, PSL_API_URL} from './CIG_APIS';
import { Globals } from './constants';
import {Helmet, Child} from "react-helmet";

import {getDateWithYear, CigPreloader} from './HelperFunctions';


class StoriesDetail extends Component {
	constructor(props) {
        super(props);
        this.state = {
            customCssTransition: 'h-transition',
            isLoading: true,
            // apiPath: Globals.ApiUrl + "api/",
            apiResp: [],
            articles: [],
            label: '',
            page: 1,
            per_page: 6,
            type: '',
            id: '',
            articlesClass: '',
            newsClass: '',
            newsData: {}
        };
        this.apiPath = PSL_API_URL + "api/psl/news/get/";
        this.getDataFromApi = this.getDataFromApi.bind(this);
        this.newsType = ["", "News", "Blog", "Press Release", "Match Report", "Others"]
    };

    async componentDidMount() {

        this.getDataFromApi(this.props.match.match.params.id);

    }

    async getDataFromApi(contentId) {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        let type = this.props.type,
        id = contentId,
        url = this.apiPath+id,
        articlesUrl ="",
        articles = [],
        apiResp,
        label,
        resp;
        
        if (type === "news") {
            url = 'https://pslcms.khaleef.com/api/psl_articles/' + id;
        } else {
          url = 'https://pslcms.khaleef.com/api/psl_press_releases/'+id;
        }

        try {
            resp = await axios.get(url);
            if (resp.data && resp.data.status === 0) {
                window.location.href= '/'
            }
            this.setState({newsData: resp.data, isLoading: false, customCssTransition: 'v-transition'});
            // console.log("News detail resp", resp)
        }
        catch (err) {
            // console.log(err);
        };

        // if(type == 'articles'){
        //     label="Article";
        //     this.setState({articlesClass: 'border-bottom border-danger', newsClass: '', label:label});
        // }else{
        //     label="News";
        //     this.setState({newsClass: 'border-bottom border-danger', articlesClass:'', label:label});
        // }

        // try{resp = await axios.get(url)}catch(error){console.log('error fetching '+type);window.location.href = '/404notfound';}
        // if(resp.status == 200){
        //     this.setState({apiResp: resp.data, type: type, id: id, customCssTransition: 'v-transition', isLoading: false}, 
        //     () => console.log("Stories set state", this.state));
        //     console.log('fetched '+type, this.state.apiResp);
        // }
        // else {
        //     console.log('error fetching '+type+'!');
        //     window.location.href = '/404notfound';
        // }


        // try{
        //     apiResp = await axios.get(articlesUrl);
        // }
        // catch(err){
        //     console.log('Error fetching articles');
        //     window.location.href = '/404notfound';
        // }

        // if(apiResp.status == 200){
        //     articles = apiResp.data.data;
        //     console.log(articles);
        //     this.setState({articles: articles},
        //         () => console.log("stories articles state completed", this.state));
        // }else{
        //     console.log('Error fetching articles');
        //     window.location.href = '/404notfound';
        // }
    }
    
    componentWillReceiveProps(nextProps) {
        // console.log("Next props", nextProps);
        debugger;
        if (this.props.match.match.params.id!==nextProps.match.match.params.id) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            this.getDataFromApi(nextProps.match.match.params.id);
        }
    }


    getArticles() {
        return this.state.articles.map( (article, i) => {
            return (
                <Link key={'articles-'+i} className="" to={"/stories/"+this.state.type+"/"+article.id}>
                    <StoriesCard data={article} image={this.state.newsImage} type={this.state.label}></StoriesCard>
                </Link>
            )
        } );
    }
    
    render() {
      let data = this.state.newsData;
      if (data.body) {
        data = data.body.replace(/:\s/g, "")

      } else {
          data = ""
      }
      console.log("News Data",this.state.newsData)
    // if (!this.state.newsData.id) return null;
    //   console.log("Data", data, data.split("</strong>:"))
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
                <Helmet>
                    <title>{this.state.newsData.t}</title>
                    <link rel="canonical" href={window.location.origin + "/news/" + this.state.newsData.id + "/"} />
                    <meta name="description" content={this.state.newsData.title || this.state.newsData.title} />
                    <meta property="og:image" content={this.state.newsData.full_image} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={this.state.newsData.title} />
                    <meta name="twitter:text:title" content={this.state.newsData.title} />
                    <meta name="twitter:description" content={this.state.newsData.title} />
                    <meta name="twitter:creator" content="@thepslt20" />
                    <meta name="twitter:image" content={this.state.newsData.full_image} />

                    <meta property="og:type" content="article" />
                    <meta property="og:description" content={this.state.newsData.title || this.state.newsData.title} />
                    <meta property="og:title" content={this.state.newsData.title} />
                    
                    <meta property="og:url" content={window.location.origin + "/news/" + this.state.newsData.id + "/"} />
                    
                </Helmet>

            {
                (this.state.isLoading) ?
                <div className="main-loader card min-height-80vh">
                <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
                </div> : <div></div>
            }

          
          <div className={this.state.customCssTransition}>
      

          <div className="card p-3">

            <div className="article pb-4 mb-3">
                <div className="d-flex">
                    <div className="ml-auto navbar-expand">
                        <ul class="navbar-nav ">
                        <li className="nav-item">
                            <a data-original-title="Facebook" className="nav-link text-secondary h4" target="_blank" 
                            href={"http://www.facebook.com/sharer/sharer.php?u="+window.location.origin+"/news/"+this.props.match.match.params.id}>
                                <i class="fa fa-facebook"></i></a></li>
                        <li className="nav-item">
                            <a data-original-title="twitter" className="nav-link text-secondary h4" 
                            href={"http://www.twitter.com/share?text="+this.state.newsData.t+"&amp;url="+window.location.origin+"/news/"+this.props.match.match.params.id}>
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                       
                        </ul>
                    </div>
                </div>
                <h2 className="article-title text-black m-f-14">
                    {this.state.newsData.title}
                </h2>
                
                <div className="article-credits my-3 fs-1p2 pb-2 border-bottom text-medium-grey d-flex m-f-12">
                
                    <span className="">{this.state.apiResp.by}</span>
                    <span className="">{getDateWithYear(this.state.newsData.created_at)   }  </span>
                </div>

                <div className="article-image mb-3">
                    {/* <img className="w-100" src={this.state.apiResp.image} /> */}
                    <ImageLoader src={this.state.newsData.full_image} >
                        <img className="w-100" />
                        <div />
                        <CigPreloader />
                    </ImageLoader>
                </div>

                <hr />
                
                <div id="news-detail" className="text-black fs-1p2 m-f-12">
                    <div dangerouslySetInnerHTML={{__html: data}}></div>
                </div>
            </div>



          </div>

          </div>
          
        </section>


    );
  }
}

const getDate = (props) => {
    let datestr,
    date = '';
    if (typeof props !== 'undefined' && props.created_at) {
        datestr = props.created_at;
        date = new Date(datestr).toDateString().split(' ');
        date = date[2]+" "+date[1]+", "+date[3];
    }
    
    return date;
}

export default StoriesDetail;
