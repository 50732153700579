import React from "react";
import "./css/become-a-part.scss";

class BecomeAPart extends React.Component {
    constructor(props) { 
        super(props);
    }

    render() {
        const isMobile = window.innerWidth < 992;
        return (<div className="frame-wrapper" style={{ 
                position: "relative", 
                width: isMobile ? "100%": "50%", 
                minHeight: isMobile ? "700px" : ""
            }}>
                {/* https://docs.google.com/forms/d/e/1FAIpQLSdnADPdpeDnbye9iGD1SB_BUBSZz2bmtgsct9Ts1-7e6qHBYw/viewform?usp=sf_link */}
                {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdnADPdpeDnbye9iGD1SB_BUBSZz2bmtgsct9Ts1-7e6qHBYw/viewform?embedded=true" width="640" height="2246" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
            <iframe src="https://docs.google.com/forms/d/1PAxZaM9jbO14MGAYCyV_rEHPPs4Abs2HspK4wJFVzTI/viewform?embedded=true" 
                width="100%" 
                frameBorder="0"
                marginHeight="0" 
                marginwidth="0"
                style={{
                    overflow: "hidden", overflowX: "hidden", overflowY: "hidden", height: "100%", width: "100%", 
                    position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px"
                }} 
                id="survey-orm">

                <section className="px-lg-0 jc-inner-page align-self-start bg-white" 
                    style={{ zIndex: '999999', width: isMobile ? "100%" : "50%" }}>
                    <div className="main-loader card min-height-80vh">
                        <img width="64" height="64" src="/images/loader_v2.gif" />
                    </div>
                </section>
            </iframe>
            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfyGRwXQezi-H99YRhRieY9B2s3e7__UN32GryhstQhZUpE3A/viewform?embedded=true" 
                width="100%"          
                marginHeight="0" 
                marginWidth="0"
                frameBorder="0" 
                style={{
                    overflow: "hidden", overflowX: "hidden", overflowY: "hidden", height: "100%", width: "100%", 
                    position: "absolute", top: "0px", left: "0px", right: "0px", bottom: "0px"
                }} 
                id="survey-orm">

                <section className="px-lg-0 jc-inner-page align-self-start bg-white" 
                    style={{ zIndex: '9999999', width: isMobile ? "100%" : "50%" }}>
                    <div className="main-loader card min-height-80vh">
                        <img width="64" height="64" src="/images/loader_v2.gif" />
                    </div>
                </section>
            </iframe> */}
        </div>);
    }
}

export default BecomeAPart;