import * as _ from "lodash";

const components = {
    scheduleWidget: [{
        mid: "matchId",
        mt: "matchType",
        imt: "intMatchType",
        mh: "matchInfo",
        md: "matchDate",
        mti: "time",
        ml: "matchLocation",
        ms: "matchState",
        mst: "matchStateType",
        mr: "closingComment",
        t1i: "team1Id",
        t1t: "team1Title",
        t1n: "team1Nick",
        t1i1s: "team1Inns1Score",
        t1i2s: "team1Inns2Score",
        t1i1o: "team1Inns1Overs",
        t1i2o: "team1Inns2Overs",
        t2i: "team2Id",
        t2t: "team2Title",
        t2n: "team2Nick",
        t2i1s: "team2Inns1Score",
        t2i2s: "team2Inns2Score",
        t2i1o: "team2Inns1Overs",
        t2i2o: "team2Inns2Overs",
        bt: "BattingTeam",
        slug: "slug",
        st: "seriesTitle",
        str: "stream",
        strh: "streamHLS",
        tId: "msId",
        t1s: "team1Score",
        t2s: "team2Score",
        umr: "ur_closingComment",
        uv: "ur_matchInfo",
        uh: "ur_matchLocation",
    }], articles: [{
        at: "articleType",
        dt: "publishDate",
        e: "excerpt",
        ht: "ht",
        Id: "id",
        lu: "linkPostUrl",
        mi: "matchId",
        mt: "matchTitle",
        t: "seriesTitle",
        u: "link",
        umt: "ur_matchTitle",
        ud: "ur_description",
        ue: "ur_excerpt",
        ut: "ur_title",
        slug: "slug"
    }], news: {
        id: "id",
        t: "title",
        d: "description",
        bf: "bloggerFName",
        bl: "bloggerLName",
        bi: "bloggerId",
        l: "Blogger_location",
        to: "topic",
        dt: "publishDate",
        at: "article_type",
        ta: "tags",
        sa: "showBloggerName",
        e: "excerpt",
        u: "url",
        lu: "linkPostUrl",
        Amp: "Amp",
        ubn: "ur_bloggerName",
        ud: "ur_description",
        ue: "ur_excerpt",
        ut: "ur_title",
        iu: "IsUrdu",
        ie: "IsEnglish",
        ct: "contentTags"
    }, seriesWidget: [{
        mid: "matchId",
        mt: "matchType",
        imt: "intMatchType",
        mh: "matchInfo",
        md: "matchDate",
        mti: "time",
        ml: "matchLocation",
        ms: "matchState",
        mst: "matchStateType",
        mr: "closingComment",
        t1i: "team1Id",
        t1t: "team1Title",
        t1n: "team1Nick",
        t1i1s: "team1Inns1Score",
        t1i2s: "team1Inns2Score",
        t1i1o: "team1Inns1Overs",
        t1i2o: "team1Inns2Overs",
        t2i: "team2Id",
        t2t: "team2Title",
        t2n: "team2Nick",
        t2i1s: "team2Inns1Score",
        t2i2s: "team2Inns2Score",
        t2i1o: "team2Inns1Overs",
        t2i2o: "team2Inns2Overs",
        bt: "BattingTeam",
        slug: "slug",
        st: "seriesTitle",
        str: "stream",
        strh: "streamHLS",
        tId: "msId",
        t1s: "team1Score",
        t2s: "team2Score"
    }], latestVideos: [{
        Id: "id",
        csId: "contentSeriesId",
        t: "title",
        d: "description",
        p: "preview",
        Url: "url",
        a: "active",
        pd: "publishDate",
        v: "views",
        vt: "videoType"
    }]

};
export const map = (partialName, response, customMapper) => {
    let mapping = components;
    if (customMapper) {
        mapping = customMapper;
    }
    if (_.get(mapping[partialName], "length") > 0) {
        const map = _.get(mapping[partialName], "0");
        return _.map(response, function (match, i) {
            return _.mapKeys(match, function (value, key) {
                return _.get(map, key);
            });
        });
    } else {
        const map = _.get(mapping, partialName);
        return _.mapKeys(response, function (value, key) {
            return _.get(map, key);
        });
    }
}
