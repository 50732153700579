import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("ERROR WAS", error)
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center text-grey main-carousel-error d-flex justify-content-center align-items-center">
          Match data is not available at the moment
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;