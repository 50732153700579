import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Globals } from './constants';
import axios from 'axios';
import {space2hyphen} from './HelperFunctions'
import { API_URL, CIG_API_URL } from "./CIG_APIS";
import { map } from "./API_MAPPER";
import * as _ from "lodash";

import {getTimeInAmPm, getDateWithoutYear} from './HelperFunctions'; 

import {getMatchCardTemplate} from './Maincarousel copy';



class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
        customCssTransition: 'h-transition',
        isLoading: true,
        allSeriesResp: [],
        seasonNumber: (this.props.match.params.id),
        title: ""
    };
    this.getMarkup = this.getMarkup.bind(this);
    this.seriesNav = this.seriesNav.bind(this);
    // this.nextPage = this.nextPage.bind(this);

    console.log("Router location props", this.props.location, this.props.match);
  };

  // async nextPage(e) {

  //   if (!this.state.pagesEnd) {
  //     let elem = document.getElementsByClassName('loading-more-results');
  //     if(elem.length)
  //       elem[0].classList.remove('d-none');
  
  //     const page = this.state.page + 1;
  //     const url1 = Globals.ApiUrl+"api/psl/v1/matches/series_matches?state=live&page="+page+"&per_page=10";
  //     const url2 = Globals.ApiUrl+"api/psl/v1/matches/series_matches?state=upcoming&page="+page+"&per_page=10";
  //     const url3 = Globals.ApiUrl+"api/psl/v1/matches/series_matches?state=recent&page="+page+"&per_page=10";

  //     const urls = [url1, url2, url3];

  //     const promises = urls.map( url => new Promise( (resolve, reject) => 
  //       axios.get(url).then( res => resolve(res) ).catch( err => reject(err) )
  //     ) );

  //     Promise.all(promises).then( res => {
  //       console.log("All matches data next page", res) 
  //       if(elem.length)
  //         elem[0].classList.add('d-none');
  //       let response = {};
  //       res.forEach( (obj, i) => {
  //         if (i===0) response.live = this.state.allSeriesResp.live.concat(obj.data.matches.slice());
  //         if (i===1) response.upcoming = this.state.allSeriesResp.upcoming.concat(obj.data.matches.slice());
  //         if (i===2) response.recent = this.state.allSeriesResp.recent.concat(obj.data.matches.slice());
  //       });
  //       let pagesEndLive = false, pagesEndRecent = false, pagesEndUpcoming = false;
  //       if (!res[0].data.matches.length) pagesEndLive = true;
  //       if (!res[1].data.matches.length) pagesEndUpcoming = true;
  //       if (!res[2].data.matches.length) pagesEndRecent = true;

  //       this.setState({allSeriesResp: response, page: page, pagesEndLive: pagesEndLive, pagesEndRecent: pagesEndRecent, 
  //       pagesEndUpcoming: pagesEndUpcoming}, () => console.log("All Series set state", this.state));

  //     }).catch(err =>  {
  //       if(elem.length)
  //         elem[0].classList.add('d-none'); 
  //       }
  //     );

  //     // const page = this.state.page + 1;
  //     // const url = Globals.ApiUrl + "api/v2/series/?page="+page.toString()+"&per_page=10";
  //     // let resp;
  //     // try {
  //     //   resp = await axios.get(url);
  //     //   console.log("Next page", resp);
  //     // }
  //     // catch(err) {
  //     //   console.log("error fetching next page");
  //     // };

  //     // if (resp.status === 200) {
  //     //   if(elem.length)
  //     //     elem[0].classList.add('d-none');
        
  //     //   let allSeriesResp = Object.assign({}, this.state.allSeriesResp);
  //     //   let pagesEndLive = false, pagesEndRecent = false, pagesEndUpcoming = false;
  //     //   if (!resp.data.live_series.length) pagesEndLive = true;
  //     //   if (!resp.data.recent_series.length) pagesEndRecent = true;
  //     //   if (!resp.data.upcoming_series.length) pagesEndUpcoming = true;
  //     //   allSeriesResp.live_series = allSeriesResp.live_series.concat(resp.data.live_series);
  //     //   allSeriesResp.recent_series = allSeriesResp.recent_series.concat(resp.data.recent_series);
  //     //   allSeriesResp.upcoming_series = allSeriesResp.upcoming_series.concat(resp.data.upcoming_series);

  //     //   this.setState({ allSeriesResp: allSeriesResp, page: page, pagesEndLive: pagesEndLive, 
  //     //       pagesEndRecent: pagesEndRecent, pagesEndUpcoming: pagesEndUpcoming }, () => console.log("Set state next page all series", this.state));

  //     // } else {
  //     //   // if (resp.data.length===0) this.setState({pagesEnd: true});
  //     //   if(elem.length)
  //     //     elem[0].classList.add('d-none');
  //     //   console.log("Api Error or no results");
  //     // };

  //   }

  // }
  async getFromApi(id) {
    const self = this;
    const url = "https://back.cricwick.net/api/v1/main/series_matches/1345/1 CIG_API_URL" + id + "/1"; 
    let title = "";     
    if (id==="1131") {
      title = "Season 2"
    }
    if (id==="1188") {
      title = "Season 3"
    } 
    if (id==="1584") {
      title = "Season 4"
    }  
    if (id==="1746") {
      title = "Season 5"
    }
    try {
      const resp = await axios.get(url);
      const matchesData = getMatchCard(map("scheduleWidget", resp.data));
      self.setState({ allSeriesResp: matchesData, customCssTransition: 'v-transition', isLoading: false, title: title });
    } catch (err) {
      console.log("Error fetching api all fixtures", err);
    };
    // fetch(url).then(function(response) {
    //     response.json().then(function(schedules) {
    //         self.setState({
    //             liveMatchResp: schedules, customCssTransition: 'v-transition'
    //         }, () => console.log("live matches state set", self.state));
    //     });
    // }).catch(function(error) {
    //     console.log("Error getting ");
    // });
    // const url = Globals.ApiUrl + "api/schedules/psl";
    // let response = {};
    // try {
    //   response = await axios.get(url);
    //   this.setState({liveMatchResp: response.data, customCssTransition: 'v-transition'}, () => console.log("live matches state set", this.state));
    //   console.log("Live match response", response);
    // } catch(err) {
    //   console.log(err);
    // };
}

    async componentDidMount() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        const self = this;
        self.getFromApi(self.props.match.params.id)

        // const url1 = Globals.ApiUrl+"api/psl/v1/matches/series_matches?state=live&page=1&per_page=10";
        // const url2 = Globals.ApiUrl+"api/psl/v1/matches/series_matches?state=upcoming&page=1&per_page=10";
        // const url3 = Globals.ApiUrl+"api/psl/v1/matches/series_matches?state=recent&page=1&per_page=10";

        // const urls = [url1, url2, url3];

        // const promises = urls.map( url => new Promise( (resolve, reject) => 
        //   axios.get(url).then( res => resolve(res) ).catch( err => reject(err) )
        // ) );

        // Promise.all(promises).then( res => {
        //   console.log("All matches data", res) 
        //   let response = {};
        //   res.forEach( (obj, i) => {
        //     if (i===0) response.live = obj.data.matches.slice();
        //     if (i===1) response.upcoming = obj.data.matches.slice();
        //     if (i===2) response.recent = obj.data.matches.slice();
        //   });

        //   this.setState({allSeriesResp: response, customCssTransition: 'v-transition', isLoading: false}, () => console.log("All Series set state", this.state));

        // }).catch(err => window.location.href = '/404notfound' );

        // let seriesId = this.props.match.params.id;
        // if (!this.state.allSeriesResp) {
        //   const url = Globals.ApiUrl+"api/v2/series/?page="+this.state.page+"&per_page=10";
        //   let resp;
        //   try {
        //       resp = await axios.get(url);
        //       console.log("All series resp", resp);
        //   }
        //   catch (err) {
        //       console.log("Error", err);
        //       window.location.href = '/404notfound';
        //   };
        //   if (resp.status!==200) window.location.href = '/404notfound';
        //   this.setState({allSeriesResp: resp.data, customCssTransition: 'v-transition', isLoading: false}, () => console.log("All Series set state", this.state));
        // }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id!==this.props.match.params.id) {
          this.getFromApi(nextProps.match.params.id);
        } 
        // if (nextProps.match.params.state==='live' && !this.selectedLive) 
        //   this.setState({selectedLive: true, selectedUpcoming: false, selectedRecent: false}, () => console.log("State updated", this.state));
        // if (nextProps.match.params.state==='upcoming' && !this.selectedUpcoming) 
        //   this.setState({selectedUpcoming: true, selectedRecent: false, selectedLive: false}, () => console.log("State updated", this.state));
        // if (nextProps.match.params.state==='recent' && !this.selectedRecent) 
        //   this.setState({selectedRecent: true, selectedUpcoming: false, selectedLive: false}, () => console.log("update", this.state));
    }
    getMarkup() {
        let selected = "", stateInt = 0;

        if (this.state.selectedLive) {
          selected = "live";
          stateInt = 1;
        }
        if (this.state.selectedRecent) {
          selected = "recent";
          stateInt = 0;          
        }
        if (this.state.selectedUpcoming) {
          selected = "upcoming";
          stateInt = 2;

        } 

        const self = this;
        const merged = self.state.allSeriesResp.length > 0 ? self.state.allSeriesResp[0].matches.concat(self.state.allSeriesResp[1].matches).concat(self.state.allSeriesResp[2].matches) : [];
        const matches = merged;
        // const matches =  this.state.allSeriesResp.length ? this.state.allSeriesResp.filter( all => all.val === stateInt )[0].matches : [];
        
        if (matches && matches.length > 0 && this.state.allSeriesResp && this.state.allSeriesResp.length) {
          // return matches.matches.map( (obj, i) => (
          //   <a key={"all-s-"+i}  href={"https://www.cricingif.com/match/" + obj.matchId + "/" + obj.slug + "/overview"}>
          //     <h5>{obj.team1Title+" vs "+obj.team2Title}</h5>
          //     <span className="text-dark-grey">{getDateWithoutYear(obj.matchDate)
          //       +", "+getTimeInAmPm(obj.matchDate.split(' ')[0] + " " + obj.time)}
          //       </span>
          //   </a>
          // ) )
          // const matches = this.state.allSeriesResp[0].matches.concat(this.state.allSeriesResp[1].matches.concat(this.state.allSeriesResp[2].matches))

          return (matches.map((obj, i) => 
            <a key={i} className="my-2" href={"https:/cricwick.net/" + obj.matchId + "/" + obj.slug + "/overview"}>
            {getMatchCardTemplate(obj, i)}</a>
          ))
          // return this.state.allSeriesResp.map( (obj, i) => (
          //   <a key={"all-s-"+i}  href={"https://www.cricingif.com/match/" + obj.matchId + "/" + obj.slug + "/overview"}>
          //     {obj.team1Title+" vs "+obj.team2Title}
          //   </a>
          // ) )
        } else if (matches && matches.length <= 0) {
          return (<div className="text-black d-flex" style={{ color: "black", justifyContent: "center" }}>No Schedules Announced Yet</div>)
        } else {
          return (<div>No Schedules Announced Yet</div>)
        }

        // if (this.state.allSeriesResp) {
        //   return this.state.allSeriesResp[selected].length ? this.state.allSeriesResp[selected].map( (series, i) => {

        //       return <Link key={"all-s-"+i} to={"/match/"+series.id+"/summary/1"} className="custom-box p-3">

        //         <h5 className="m-f-13">{series.title}</h5>
        //         <h5 className="text-secondary mb-0 m-f-13">{getDate(series.match_start) + ' ' + getMonth(series.match_start) 
        //         + ' - ' + getDate(series.match_end) + ' ' + getMonth(series.match_end)}
        //         </h5>
        //       </Link> 
            
        //     }) : <h5>No {selected} matches </h5>
        // }
    }
    seriesNav(e) {

        if (e.target.id !== this.state.selected) this.setState({selected: e.target.id}, () => console.log("nav", this.state));
    }

  render() {
    const {title} = this.state
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        {
            (this.state.isLoading) ?
            <div className="main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
        }

        <div className={this.state.customCssTransition}>
        
            {/* <div className="card p-3">
                <div className="d-flex overflow-x-auto">
                    <Link to="/fixtures/live">
                        <h4 className={(this.state.selectedLive) ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                        : "font-weight-semi-bold p-2  m-f-14"}>Live</h4>
                    </Link>
                    <Link to="/fixtures/upcoming">
                        <h4 className={(this.state.selectedUpcoming) ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                        : "font-weight-semi-bold p-2  m-f-14"}>Upcoming</h4>
                    </Link>
                    <Link to="/fixtures/recent">
                        <h4 className={(this.state.selectedRecent) ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                        : "font-weight-semi-bold p-2  m-f-14"}>Result</h4>
                    </Link>
                    
                </div>
            </div> */}


          <div className="card p-3 my-2">
            <h5 className="text-center font-weight-bold">{title}</h5>
            
            {

                this.getMarkup()
            } 
          </div>
          {/* {
            ( (!this.state.pagesEndLive && this.state.allSeriesResp && this.state.allSeriesResp.live.length && this.state.selectedLive)
            || (!this.state.pagesEndRecent && this.state.allSeriesResp && this.state.allSeriesResp.recent.length && this.state.selectedRecent)
            || (!this.state.pagesEndUpcoming && this.state.allSeriesResp && this.state.allSeriesResp.upcoming.length && this.state.selectedUpcoming) )
            ?
            (
              <div>
                <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" alt="" height="30" /></div>
                <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
              </div>
            )
            : <div></div>
          } */}
          </div>
        </section>


    );
  }
}

export const getMatchCard = (matches) => {
  const fourMatches = [];
  const _data = [
      { title: "Recently Finished", matches: [], val: 0 },
      { title: "Live Cricket", matches: [], val: 1 },
      { title: "Upcoming Matches", matches: [], val: 2 }
  ];
  _.each(matches, function (match) {
      if (match.matchState === 0) _data[0].matches.push(match);
      else if (match.matchState === 1) _data[1].matches.push(match);
      else if (match.matchState === 2) _data[2].matches.push(match);
  });
  // let pushed = 0;
  // if (_data[1].matches.length > 0) {
  //     fourMatches.concat(_data[1].matches);
  //     pushed += _data[1].matches.length;
  // }
  // if (_data[0].matches.length > 0) {
  //     fourMatches.push(_data[0].matches[0]);
  //     pushed += 1;
  // }
  // if (_data[2].matches.length > 0) {
  //     for(var i=0; i < (5 - pushed); i++) {
  //         fourMatches.push(_data[2].matches[i]);
  //     }
  // }
  return _data;
};


export const getDay = (date) => ( new Date(date).toDateString().split(' ')[0] )
export const getMonth = (date) => ( new Date(date).toDateString().split(' ')[1] )
export const getDate = (date) => ( new Date(date).toDateString().split(' ')[2] )
export const getYear = (date) => ( new Date(date).toDateString().split(' ')[3] )

export default Results;
