import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { getDateWithYear, CigPreloader } from './HelperFunctions';
import axios from 'axios';
import { Globals } from './constants';
import { API_URL, PSL_API_URL, PSL_PROD_STATIC_IMAGES_BASE_URL } from "./CIG_APIS";
import ImageLoader from 'react-load-image';


class Leftsection extends Component {
    constructor(props){
        super(props);
        this.state = {
            updateApiResp: [],
            customCssTransition: 'h-transition'
        };

        this.updateApiPath = 'https://pslcms.khaleef.com/api/psl_articles?page=1&per_page=5';
        this.getLatestUpdates = this.getLatestUpdates.bind(this);
    };

    async componentDidMount(){
        let updatesUrl = this.updateApiPath, updatesResp;
        try {
            updatesResp = await axios.get(updatesUrl)
            if(updatesResp.data.data) {
                let data = updatesResp.data.data.slice(0, 5);
                this.setState({updateApiResp: data})
                // console.log('latest updates fetched', this.state.updateApiResp);
            } else {
                this.setState({updateApiResp: []})

                // console.log('error fetching latest updates!');
            }
        } catch(error) {
            this.setState({updateApiResp: []})

            // console.log('error fetching latest updates');
        }
    }

    getLatestUpdates() {
        return this.state.updateApiResp.map( (update, i) => {
            return(
                <div key={'lu-'+i} className="p-3">
                    <Link to={"/news/"+update.id} key={"pr-"+i}>

                        {/* <div className="video-image secondary-video"
                        style={{background: "url(" + update.p + "), "
                         + `url(${PSL_PROD_STATIC_IMAGES_BASE_URL}placeholder.png)` }} /> */}
                         <ImageLoader src={update.full_image} >
                            <img className="w-100" />
                            <div />
                            <CigPreloader />
                        </ImageLoader>
                        <h5 className="font-weight-bold mt-2">{update.title}</h5>
                        <span className="text-medium-grey">  {getDateWithYear(update.created_at.split('T')[0])} </span>
                        <hr/>
                    </Link>
                </div>
            )
        } )
    }

    render() {
        return (
            <div id="left-section" className="">
                <div className="left-section ">
                    <div className="latest-updates card">
                    <div className="border-bottom d-flex justify-content-center align-items-center">
                        {/* <img src="/images/latest_videos_icon.png" /> */}
                        <h2 className="heading text-center my-3 ff-gotham-bold fs-18px
                         text-green text-uppercase mx-2">Latest News</h2>
                        {/* <img src="/images/latest_videos_icon.png" /> */}
                    </div>

                        {this.getLatestUpdates()}
                        {
                            this.state.updateApiResp.length > 0 &&
                            <div className="text-center font-weight-bold pb-4">
                                <Link to="/latest/latest-news" className="text-green">See all latest news</Link>
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}
export default Leftsection;
