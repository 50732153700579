import React, { Component } from 'react';
import JWPlayerOnScrollViewResize from './JWPlayerOnScrollViewResize';
import { SeriesNav } from './Series';
import { VideoCardDescription } from './HelperFunctions';
import ReactJWPlayer from 'react-jw-player';
import {Globals} from './constants';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { hyphen2space } from './HelperFunctions';
import { ToggleSwitch, Dropdown } from './MatchVideos';
import BackgroundImage from 'react-background-image-loader';

class SeriesVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            videoIndex: 0,
            seriesTitle: this.props.match.params.title,
            seriesVideosResp: {},
            videoId: this.props.match.params.videoId,
            seriesId: this.props.match.params.id,
            page: 1,
            pagesEnd: false,
            isLoading: true,
            filterBalls: false,
            tags: [],
            tag: 33

        };
        this.setAutoplay = this.setAutoplay.bind(this);
        this.playerReady = this.playerReady.bind(this);
        this.playerError = this.playerError.bind(this);
        this.videoLoad = this.videoLoad.bind(this);
        this.nextVideoLoad = this.nextVideoLoad.bind(this);
        this.calendarSelect = this.calendarSelect.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.singleVideoCase = this.singleVideoCase.bind(this);
        this.tagChange = this.tagChange.bind(this);
        this.getTags = this.getTags.bind(this);
        this.isMobile = window.innerWidth < 992;
        this.getFromApi = this.getFromApi.bind(this);
        
    };

    setAutoplay(e){
        let isChecked = this.state.isChecked
        let elem = document.getElementsByName('checkbox');
        if(elem.length){
            elem = elem[0]
            if(elem.checked){
                isChecked = 1;
            }else{
                isChecked = 0;
            }
            this.setState({isChecked: isChecked});
            localStorage.setItem('autoplay', isChecked)
        }
    }

    async getTags(seriesTitle) {
        const url = Globals.ApiUrl + "api/v2/user_api/get_tag_options_by_match?match_id="+this.state.matchId+"&telco="+Globals.telco
        try {
            const resp = await axios.get(url);
            const highlightsTag = resp.data.tags.filter(obj => obj.label.toLowerCase()==='highlights');
            let filterBalls = false;
            if (highlightsTag[0].show_all === false && highlightsTag[0].filter_balls === true) {
                filterBalls = true;
            };
            console.log("Highlights id", highlightsTag);
            this.setState({tags: resp.data.tags, tag: highlightsTag[0].id, filterBalls: filterBalls, seriesTitle: seriesTitle, 
            isChecked: localStorage.getItem('autoplay')}, 
                () => this.getFromApi());
            console.log("tags", resp);
        }
        catch (err) {
            console.log(err);
        };
    }

    tagChange(e) {
        e.persist();
        console.log("tag chnage", e);
        let html = e.target.innerHTML;
        const selectedTagId = e.target.id;
        document.getElementById('videos-selection').innerHTML = html;
        const selectedTag = this.state.tags.filter(obj => obj.label===html);
        console.log("selected tag", selectedTag, selectedTagId, e.target.innerHTML)
        let filterBalls = false;
        if (selectedTag[0].show_all === false && selectedTag[0].filter_balls === true) {
            filterBalls = true;
        };
        this.setState({page: 1, tag: selectedTagId, pagesEnd: false, filterBalls: filterBalls}, () => {
            this.getFromApi();
        });
        
    };

    async getFromApi() {
        const url = Globals.ApiUrl+"api/v1/user_api/get_videos_by_series?series_id="+this.props.match.params.id.toString()
        +"&page="+this.state.page.toString()+"&tag_id="+this.state.tag+"&per_page=10&telco="+Globals.telco;

        if (this.state.videoId) {

            this.singleVideoCase(this.state.videoId)

        } else {

            try {
                const resp = await axios.get(url);
                console.log("Series videos resp", resp);
                if (resp.status===200){ 
                    this.setState({ seriesVideosResp: resp.data, isLoading: false }, () => console.log("Set state ", this.state))
                    let elem = document.getElementsByClassName('none-message')
                    if(elem.length > 0){
                        elem[0].classList.remove('d-none');
                    }
                }
                else 
                    window.location.href = '/404notfound';
            }
            catch (err) {
                console.log(err);
                window.location.href = '/404notfound';
            };

        }
    }

    componentWillReceiveProps(nextProps) {

        console.log("Next props", nextProps, "previous", this.props)
        
        if (nextProps.match.params.videoId!==this.props.match.params.videoId) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            this.singleVideoCase(nextProps.match.params.videoId, nextProps.match.params.title);
        }
    }

    singleVideoCase(videoId, seriesTitle) {
        const seriesTitleCopy = seriesTitle ? seriesTitle : this.state.seriesTitle; 
        const url = Globals.ApiUrl+"api/v1/user_api/get_videos_by_series?series_id="+videoId+"&tag_id="+this.state.tag;
        console.log("related videos-->", url)
        const url2 = Globals.ApiUrl+"api/v1/user_api/get_single_video?id="+videoId+"&telco=telco="+Globals.telco;

        const promise1 = new Promise(resolve => {
            axios.get(url2).then(res => resolve(res)).catch(err => resolve(err));
        });

        const promise2 = new Promise(resolve => {
            axios.get(url).then(res => resolve(res)).catch(err => resolve(err));
        });

        Promise.all([promise1, promise2]).then(resp => {
            console.log("Promise all", resp)

            let singleVideo = [], mergedVideos = [];
            singleVideo.push({video: resp[0].data.video});
            if (resp[1].data.videos)
                mergedVideos = singleVideo.concat(resp[1].data.videos);
            else 
                mergedVideos = singleVideo;
            const mergedResponse = {...resp[1].data, ...{videos: mergedVideos}};

            this.setState({ seriesVideosResp: mergedResponse, videoId: videoId, isLoading: false, seriesTitle: seriesTitleCopy }, 
                () => console.log("Set state ", this.state))

        }).catch(err => console.log(err));
    }

    async componentDidMount() {

        // let isChecked = localStorage.getItem('autoplay');
        // if(isChecked == 1){
        //     this.setState({isChecked: true});
        // }

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });

        let seriesTitle = this.props.match.params.title;
        this.getTags(seriesTitle);

        

    }
    calendarSelect(e) {
        console.log(e);
    };
    playerReady(e) {
        
        if (!this.isMobile)
            JWPlayerOnScrollViewResize();
    }
    playerError(e) {
        
    }
    videoLoad(e) {
        console.log(e);
        if (e.index!==0) {
            const videosCopy = this.state.seriesVideosResp.videos.slice(1, this.state.seriesVideosResp.videos.length);
            console.log("Videos copy", videosCopy, "old videos", this.state.seriesVideosResp.videos);
            this.setState({seriesVideosResp: {videos: videosCopy}}, () => console.log("Next Video Loaded state", this.state));
        }
    }

    nextVideoLoad(e) {
        let elem = document.getElementsByName('checkbox')
        if(elem){
            elem = elem[0];
            if(elem.checked){
            let videoIndex = this.state.videoIndex;
                let videoElem = document.getElementsByClassName('mvlist');
                if(videoElem[videoIndex]){
                    if(this.state.videoId !== 'v'){
                        let v = videoIndex+ 1;
                        this.setState({videoIndex: v})
                    }
                    
                    videoElem[videoIndex].click();
                }
                
            }
        }
    }

    async nextPage(e) {

        if (!this.state.pagesEnd) {
            let elem = document.getElementsByClassName('loading-more-results');
            if(elem.length)
                elem[0].classList.remove('d-none');
      
          const page = this.state.page + 1;
          const url = Globals.ApiUrl+"api/v1/user_api/get_videos_by_series?series_id="+this.props.match.params.id.toString()
        +"&page="+page.toString()+"&tag_id="+this.state.tag+"&per_page=10&telco="+Globals.telco

          let resp;
          try {
            resp = await axios.get(url);
            console.log("Next page", resp);
          }
          catch(err) {
            console.log("error fetching next page");
          };

          if (resp.status === 200 && resp.data && resp.data.videos.length > 0) {
            if(elem.length)
                elem[0].classList.add('d-none');

            const moreVideos = this.state.seriesVideosResp.videos.concat(resp.data.videos);  

            this.setState({page: page, seriesVideosResp: {videos: moreVideos}}, () => console.log("Next page state set", this.state));

          } else {
            if(elem.length)
                elem[0].classList.add('d-none');

            if (resp.data.length===0 || !resp.data.videos.length) this.setState({pagesEnd: true});

            console.log("Api Error or no results");
          };

        }

    }



  render() {
    let series, videos = [], playlist = [], title = '', description = '', date = '';
    if (this.state.seriesVideosResp.hasOwnProperty('series')) {
        series = this.state.seriesVideosResp.series;
    }
    if (this.state.seriesVideosResp.hasOwnProperty('videos') && this.state.seriesVideosResp.videos.length) {
        videos = this.state.seriesVideosResp.videos;
        playlist = this.state.seriesVideosResp.videos.map( obj => ( (obj && obj.video) ? { file: obj.video.video_file } : '') );
        title = (videos[0].video) ? videos[0].video.title :"";
        description =(videos[0].video) ? videos[0].video.match_desc : "";
        date = (videos[0].video) ? videos[0].video.created_at : "";
    }
    
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
        
          <div className="card p-3">
            <h4 className="text-uppercase font-weight-bold mb-2 mb-lg-3 pl-2 m-f-14">{hyphen2space(this.state.seriesTitle)}</h4>
            <SeriesNav path={this.props.match}></SeriesNav>
          </div>
          {
            (this.state.isLoading) ?
            <div className="mt-2 main-loader card min-height-80vh">
              <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
            </div> : <div></div>
          }
        
          <div className="card p-3 mt-2">
            
            {
                (videos.length) ?
                <div>
                    {/* <h4 className="text-uppercase mb-3">{(series) ? series.short_name : ''} </h4> */}
                    <div className="player-container">
                        <div className="player-position">
                            <ReactJWPlayer
                                playerId='player'
                                playerScript='https://content.jwplatform.com/libraries/IDzF9Zmk.js'
                                // playlist={playlist}
                                file = { (videos[0].video) ? videos[0].video.video_file : ""}
                                onReady={this.playerReady}
                                onVideoLoad={this.videoLoad}
                                onOneHundredPercent={this.nextVideoLoad}
                                onSetupError = {this.playerError}
                            />
                        </div>
                    </div> 
                    
                    <div className="px-2 pt-3 pb-0 d-flex ">
                        <VideoCardDescription title={title} description={description} date={date} showDescription={true} showDate={true}></VideoCardDescription>
                        
                    </div>
                    {(videos.length) ? 
                    <div className="d-flex">
                    <div className="ml-auto navbar-expand">
                        <ul className="navbar-nav mt-2 mt-lg-0">
                        <li className="nav-item">
                            <a data-original-title="Facebook" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href}>
                                <i className="fa fa-facebook"></i></a></li>
                        <li className="nav-item">
                            <a data-original-title="twitter" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" href={"http://www.twitter.com/share?text="+title+"&amp;url="+window.location.href+"/"}>
                                <i className="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a data-original-title="google-plus" className="nav-link text-secondary h4 mb-0 py-0 m-f-14" href={"https://plus.google.com/share?url="+window.location.href+"/"}>
                                <i className="fa fa-google-plus google"></i>
                            </a>
                        </li>
                        </ul>
                        </div>
                    </div>
                    :<div></div> }
                    <div className="text-right d-flex mb-3">
                        {/* <Dropdown tagChange={this.tagChange}></Dropdown> */}
                        <Dropdown tags={this.state.tags} tagChange={this.tagChange} defaultSelect="Highlights"></Dropdown>
                        {/* <div className="dropdown my-3">&nbsp;</div> */}
                        <ToggleSwitch checked={this.state.isChecked} setAutoplay={this.setAutoplay}></ToggleSwitch>
                    </div> 
                    {
                        videos.map( (obj, i) => 
                            (i!==0 && (!this.state.filterBalls || (this.state.filterBalls && obj.ball))) ?
                            <Link to={"/series/"+this.props.match.params.id+"/"+this.state.seriesTitle+"/videos/"+obj.video.id} key={"sv-"+i} className="d-flex p-2 mvlist">
                                <div className="w-30 pr-3 position-relative">
                                <BackgroundImage className="sixteen-by-nine bg-center-cover" src={obj.video.thumb} placeholder={Globals.placeholderPlain4x3}>
                                    <div className="fa fa-play-circle play-btn-lg play-btn-sm"></div> 

                                 </BackgroundImage>
                                    {/* <div className="sixteen-by-nine bg-center-cover" style={{backgroundImage: "url(" + obj.video.thumb + ")"}}></div> */}
                                </div>
                                <VideoCardDescription title={obj.video.title} description={obj.video.match_desc}
                                showDescription={true} showDate={true}></VideoCardDescription>
                            </Link> : <div key={"sv-"+i}></div>
                            )
                    }
                </div>: <h5 className="mb-0 none-message d-none">Videos not available at the moment</h5>
            }
            
            
          </div>
          {
            (!this.state.pagesEnd && videos.length) ?
            (
            <div>
                <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" alt="" height="30" /></div>
                <button id="more-results" onClick={this.nextPage} type="button" className="btn btn-primary d-none">More Results</button>
            </div>
            )
            : <div></div>
          }
          
        </section>


    );
  }
}

export default SeriesVideos;
