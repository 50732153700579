export const API_URL = "https://cig-staging-api.azurewebsites.net/";

export const FPL_API_URL = process.env.REACT_APP_buildenv==='staging' 
? "https://storage.cricingif.com/fpl-dev-images/static-assets/" 
: "https://storage.cricingif.com/fpl-prod-images/static-assets/";

export const PSL_API_URL = process.env.REACT_APP_buildenv==='staging'
? "https://psl-apis-staging.azurewebsites.net/"
: "https://back.cricwick.net/";

export const CIG_API_URL = process.env.REACT_APP_buildenv==='staging'
? "https://cig-staging-api.azurewebsites.net/"
: "https://back.cricwick.net/";

export const PSL_PROD_STATIC_IMAGES_BASE_URL = process.env.REACT_APP_buildenv==='staging'
? "https://storage.cricingif.com/psl-dev-images/static-assets/"
: "https://storage.cricingif.com/psl-prod-images/static-assets/";

// export const PSL_PROD_PLAYER_IMAGES_BASE_URL = process.env.REACT_APP_buildenv==='staging'
// ? "https://cricingif.blob.core.windows.net/psl-dev-images/player-images/reduce/141x141/"
// : "https://cricingif.blob.core.windows.net/psl-prod-images/player-images/reduce/141x141/";


export const PSL_PROD_PLAYER_IMAGES_BASE_URL = process.env.REACT_APP_buildenv==='staging'
? "https://storage.cricingif.com/psl-dev-images/player-images/reduce/300x300/"
: "https://storage.cricingif.com/psl-prod-images/player-images/reduce/300x300/";


export const PSL_IMAGES_BASE_URL = process.env.REACT_APP_buildenv==='staging'
? "https://storage.cricingif.com/psl-dev-images"
: "https://storage.cricingif.com/psl-prod-images";

export const CIG_IMAGES_BASE_URL = process.env.REACT_APP_buildenv==='staging'
? "https://storage.cricingif.com/cig-staging-images/"
: "https://storage.cricingif.com/cig-live-images/"
// export const CIG_TEAM_IMAGES_URL