import React, { Component } from "react";
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import Maincarousel from "./Maincarousel";
import Leftsection from "./Leftsection";
import Rightsection from "./Rightsection";
import AllSeries from "./AllSeries";
import Series from "./Series";
import Stories from "./Stories";
import Videos from "./Videos";
// import Rankings from './Rankings';
import TeamPlayers from "./TeamPlayers";
import Match from "./Match";
import SeriesSchedules from "./SeriesSchedules";
import SeriesPointsTable from "./SeriesPointsTable";
import SeriesVideos from "./SeriesVideos";
import SingleVideo from "./SingleVideo";
import StoriesDetail from "./StoreisDetail";
import Photos from "./Photos";
import Results from "./Results";
import Tests from "./Tests";
import Home from "./Home";
import NotFound404 from "./404";
import PrivacyPolicy from "./PrivacyPolicy";
import BecomeAPart from "./BecomeAPart";
import RefundPolicy from "./RefundPolicy";
import WatchPSL6 from "./WatchPSL6";
// import Tickets from "./Tickets";
import { Link } from "react-router-dom";
import { staticImagesUrl } from "./constants";
import ErrorBoundary from "./ErrorBoundary";
import MyStas11 from "./Mystars11";
import Faq from "./Faq";
// import LatestNews from './LatestNews';
import { PSL_PROD_STATIC_IMAGES_BASE_URL, FPL_API_URL } from "./CIG_APIS";
import RefundUpdate from "./RefundUpdate";
import Poll from "./Poll";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "",
      selectedMatchId: 0,
    };
    this.setCurrentPath = this.setCurrentPath.bind(this);
    this.downloadApp = this.downloadApp.bind(this);
  }

  setCurrentPath(path, state) {
    let paths = path.split("/");
    paths = paths.slice(1, paths.length);
    let matchId = 0;
    if (state && state.matchId) matchId = state.matchId;

    this.setState({ currentPage: "/" + paths[0], selectedMatchId: matchId });
  }

  componentDidMount() {
    this.setCurrentPath(this.props.location.pathname);

    this.props.history.listen((location) => {
      console.log("location change", location);
      if (window.innerWidth < 992) {
        const classList = document.getElementById("navbarContent").classList;
        if (classList.value.indexOf("show") > -1) {
          document.getElementById("navToggler").click();
        }
        // console.log("Classlist", classList.value.indexOf('show'))
      }

      this.setCurrentPath(location.pathname, location.state);
    });
  }

  downloadApp(e) {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let sUsrAg = navigator.userAgent,
      win,
      url;
    if (iOS) {
      url = this.props.appPathIos;
      win = window.open(url, "_blank");
      win.focus();
    } else {
      if (sUsrAg.indexOf("Android") > -1) {
        url = this.props.marketPath;
        win = window.open(url, "_blank");
        win.focus();
      } else {
        url = this.props.appPath;
        window.open(url);
      }
    }
  }

  render() {
    const isMobile = window.innerWidth < 992;
;

    const appImageAndroid =
      "https://storage.cricingif.com/fpl-prod-images/static-assets/Indiewallet-Google-Play-button@2x.png";
    const appImageIos =
      "https://storage.cricingif.com/fpl-prod-images/static-assets/App-Store-icon@2x.png";
    return (
      <div>
        {isMobile ? (
          <div>
            <Nav currentPage={this.state.currentPage} {...this.props} />
          </div>
        ) : (
          <div>
            <Nav currentPage={this.state.currentPage} {...this.props} />
          </div>
        )}

        <div id="page" className="container bg-white belowNavPage">
          {/* <div className={`pb-3 border-bottom`}>
            <Maincarousel />
          </div> */}
          <div className="row ml-0 mr-0">
            {!isMobile && <Leftsection />}

            <Switch>
              <Route path="/" exact type="home" component={Home} />
              {/* <Route path="/series/:id/:title" exact component={Series} /> */}
              <Route path="/fixtures/:id" exact component={SeriesSchedules} />
              <Route path="/season/:id" exact component={Results} />
              <Route path="/photos" exact component={Photos} />
              <Route path="/refund-policy" exact component={RefundPolicy} />
              {/* <Route path="/tickets" exact component={Tickets} /> */}
              <Route path="/faq" exact component={Faq} />
              <Route path="/refund-update" exact component={RefundUpdate} />
              {/* <Route path="/series/:id/:title/schedules" exact component={SeriesSchedules} /> */}
              {/* <Route path="/series/:id/:title/pointstable" exact component={SeriesPointsTable} /> */}
              {/* <Route path="/series/:id/:title/videos" exact component={SeriesVideos} /> */}
              {/* <Route path="/series/:id/:title/videos/:videoId" exact component={SeriesVideos} /> */}
              {/* <Route path="/video/:videoId" exact component={SingleVideo} /> */}
              {
                <Route
                  path="/latest/:type"
                  exact
                  render={(props) => <Stories {...props} />}
                />
              }
              {/* {<Route path="/latest/blogs" exact render={()=> <Stories type='blogs' title='BLOGS'></Stories>} />}
                  {<Route path="/latest/press-release" exact render={()=> <Stories type='press-release' title='PRESS RELEASE'></Stories>} />}
                  {<Route path="/latest/match-report" exact render={()=> <Stories type='match-report' title='MATCH REPORT'></Stories>} />} */}
              {/* <Route path="/stories/news" exact render={()=> <Stories type='news'></Stories>} /> */}
              {/* <Route path="/stories/articles" exact render={()=> <Stories type='articles'></Stories>} /> */}
              {/* <Route path="/stories/articles/:id" exact render={(match)=> <StoriesDetail type='articles' match={match} ></StoriesDetail>} /> */}
              <Route
                path="/news/:id"
                exact
                render={(match) => <StoriesDetail type="news" match={match} />}
              />
              <Route
                path="/press-release/:id"
                exact
                render={(match) => <StoriesDetail type="press-release" match={match} />}
              />
              {
                <Route
                  path="/videos"
                  exact
                  render={() => <Videos type="allVideos" />}
                />
              }
              {
                <Route
                  path="/vop-poll"
                  exact
                  render={() => <Poll />}
                />
              }
              {/* <Route path="/videos/featured" exact render={() => <Videos type="featured"></Videos>} />
                  <Route path="/videos/archives" exact render={() => <Videos type="archives"></Videos>} /> */}
              <Route path="/teams/:id" exact component={TeamPlayers} />
              {/* <Route path="/test" exact component={Tests} /> */}
              {/* <Route path="/match/:id/:category/:subcategory" exact component={Match} /> */}
              <Route path="/redirect" exact component={NotFound404} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/hamaray-heroes" exact component={BecomeAPart} />
              <Route path="/watchpsl" exact component={WatchPSL6} />
              <Route path="/mystars11" exact component={MyStas11} />
              <Route component={NotFound404} />
            </Switch>

            <Rightsection downloadApp={this.downloadApp} />
          </div>

          {/* {
              !isMobile && <div id="home-psl-app" style={{ backgroundImage: "url(/images/background-mask.png)", backgroundPosition: "center", backgroundSize: "cover" }}
                  className="row ml-0 mr-0 bg-green">
              <div className="col-6 col-sm-3 d-flex align-items-end  mt-5">
                  <div>
                      <img className="mw-200-lg w-100" src={`${PSL_PROD_STATIC_IMAGES_BASE_URL}player-banner.png?v=12`} alt="" />
                  </div>
                  <div className="ml-5">
                      <img className="w-100 player-img mw-180-lg" src={`${PSL_PROD_STATIC_IMAGES_BASE_URL}leaderboard-banner.png?v=12`} alt="" />
                  </div>
              </div>
              <div className="col-6 col-sm-9 pl-3">
                  <div className=" get-app-sec p-3 mt-5 mt-5px-sm hidden-sm-down">
                      <div className="ml-5 ml-10px-sm mt-5">
                          <h3 className=" text-yellow text-uppercase">hblpsl fantasy league app</h3>
                          <h3 className="text-white text-uppercase">download now!</h3>
                          <div className="d-flex mt-5 mt-5px-sm">
                          <a href="https://play.google.com/store/apps/details?id=com.cricingif.psl">
                              <img src={appImageAndroid}
                              width="130" className="mr-3" alt="" />
                          </a>
                          <a href="https://itunes.apple.com/us/app/hbl-psl-2019-official/id1451727219?ls=1&mt=8">
                              <img src={appImageIos}
                              width="130" alt="" />
                          </a>
                          </div>
                      </div>
                  </div>
                  <img  src={"https://storage.cricingif.com/fpl-prod-images/static-assets/fantasy-bottom-banner-art.png"} className="fantasy-bt-banner"/>

              </div>
          </div>

          } */}
        </div>

        <div className="max-w-1150px mx-auto">
          <div className="container">
            <div className="row bg-white">
              <div className="m-auto">
              <div className="d-flex justify-content-center align-items-center">
                {/* <img src="/images/latest_videos_icon.png" /> */}
                <h2 className="heading text-center my-3 ff-gotham-bold fs-32px fs-28px-sm text-green text-uppercase mx-2">
                hbl psl season 8 sponsors{" "}
                </h2>
                {/* <img src="/images/latest_videos_icon.png" /> */}
              </div>
                
                <img
                  className="w-75 d-flex m-auto"
                  src={`https://asset.cricvids.co/cricwick-assets/images/sponsors-2023.png`}
                  alt=""
                />
              </div>
            </div>

            {!isMobile && (
              <div className=" p-5 bg-white row">
                <div className="w-75 mx-auto d-flex justify-content-center">
                  {/* <div className="text-center w-20">
                    <a
                      href={`${PSL_PROD_STATIC_IMAGES_BASE_URL}PSL-Code-of-Conduct-For-Players.pdf`}
                    >
                      <img
                        className="w-fx-100px"
                        src={`${PSL_PROD_STATIC_IMAGES_BASE_URL}anti-corruption-code.png`}
                        alt=""
                      />
                      <p> Code Of Conduct </p>
                    </a>
                  </div>
                  <div className="text-center w-20">
                    <Link to="/privacy-policy">
                      <img
                        className="w-fx-100px"
                        src={`${PSL_PROD_STATIC_IMAGES_BASE_URL}privacy-policy.png`}
                        alt=""
                      />
                      <p>Privacy Policy </p>
                    </Link>
                  </div>
                  <div className="text-center w-20">
                    <a
                      href={`${PSL_PROD_STATIC_IMAGES_BASE_URL}PSLDraftGuide2021.pdf`}
                    >
                      <img
                        className="w-fx-100px"
                        src={`${PSL_PROD_STATIC_IMAGES_BASE_URL}player_draft.png?v=12`}
                        alt=""
                      />
                      <p> Player Draft Guide </p>
                    </a>
                  </div> */}
                  <div className="text-center w-20">
                    <a
                      href="https://s3.us-west-2.amazonaws.com/psl-t20.com/PSL7_Playing_Conditions.pdf"
                      download
                    >
                      <img
                        className="w-fx-100px"
                        src={'/images/anti-corruption-code.png'}
                        alt=""
                      />
                      <p>HBL PSL Playing Conditions</p>
                    </a>
                  </div>
                 
                  <div className="text-center w-20">
                    <a href="#">
                      <img
                        className="w-fx-100px"
                        src={`/images/address-icon.png`}
                        alt=""
                      />
                      <p>
                        {" "}
                        <strong>Address:</strong> <br /> Pakistan Super League
                        Sectretariat, <br /> Gaddafi Stadium, <br /> Lahore,
                        Pakistan{" "}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {isMobile && (
              <div className="p-5 d-block bg-white row">
                <div className="d-flex justify-content-center ">
                  {/* <div className="text-center w-50">
                    <a
                      href={`${PSL_PROD_STATIC_IMAGES_BASE_URL}PSL-Code-of-Conduct-For-Players.pdf`}
                    >
                      <img
                        className="w-fx-80"
                        src={`${PSL_PROD_STATIC_IMAGES_BASE_URL}anti-corruption-code.png`}
                        alt=""
                      />
                      <p> Code Of Conduct </p>
                    </a>
                  </div>
                  <div className="text-center w-50">
                    <Link to="/privacy-policy">
                      <img
                        className="w-fx-80"
                        src={`${PSL_PROD_STATIC_IMAGES_BASE_URL}privacy-policy.png`}
                        alt=""
                      />
                      <p>Privacy Policy </p>
                    </Link>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="text-center w-50">
                    <a
                      href={`${PSL_PROD_STATIC_IMAGES_BASE_URL}PSLDraftGuide2021.pdf`}
                    >
                      <img
                        className="w-fx-80"
                        src={`${PSL_PROD_STATIC_IMAGES_BASE_URL}player_draft.png?v=12`}
                        alt=""
                      />
                      <p> Player Draft Guide </p>
                    </a>
                  </div> */}
                  <div className="text-center w-50">
                    <a
                      href="https://s3.us-west-2.amazonaws.com/psl-t20.com/PSL7_Playing_Conditions.pdf"
                      download
                    >
                      <img
                        className="w-fx-80"
                        src={'/images/anti-corruption-code.png'}
                        alt=""
                      />
                      <p>HBL PSL Playing Conditions</p>
                    </a>
                  </div>
                  
                </div>
                <div className="d-flex justify-content-center">
                  <div className="text-center w-50">
                    <a href="#">
                      <img
                        className="w-fx-80"
                        src={`/images/address-icon.png`}
                        alt=""
                      />
                      <p>
                        {" "}
                        <strong>Address:</strong> <br /> Pakistan Super League
                        Sectretariat, <br /> Gaddafi Stadium, <br /> Lahore,
                        Pakistan{" "}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {!isMobile && (
          <div className="h-fx-100 w-100 bg-green-psl text-white d-flex align-items-center justify-content-between bg-center-cover">
            <div className="inner-container w-lg-1150px d-flex align-items-center justify-content-between mx-auto">
              <div className="d-flex align-items-center text-white">
                <img
                  src="/images/psl-logo-2023.png"
                  alt=""
                  height={60}
                  onClick={() => {
                    // history.push('/')
                  }}
                  className="fs-1-1 pr-3 footer-cing-logo"
                />
                <div>
                  {/* <p className="mb-0">&copy; 2019 All Rights Reserved</p> */}
                  <p className="mb-0">
                    <div
                      onClick={() => {
                        window.location.href = `http://khaleef.com/`;
                      }}
                      className="text-white"
                    >
                      <div
                        className="pl-4 d-flex"
                        style={{
                          cursor: "pointer",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <small className="mb-0 font-weight-bold">
                          Brought to you by
                        </small>
                        <img
                          style={{ marginTop: "4px" }}
                          src="/images/khaleef.png"
                          width="120"
                        />
                        <a
                          href="https://www.cricingif.com/series/1368/pakistan-super-league-psl-2020"
                          className="mb-0 font-weight-bold text-white"
                          style={{ marginTop: "4px" }}
                        >
                          PSL 2023
                        </a>
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div>
                <div className="d-flex  justify-content-end">
                  <a href="https://www.facebook.com/thePSL" target="_blank">
                    <img
                      src="/images/facebook-logo.png"
                      width={40}
                      className="mx-2"
                      alt=""
                    />
                  </a>
                  <a href="https://twitter.com/thepslt20" target="_blank">
                    <img
                      src="/images/twitter-logo.png"
                      width={40}
                      className="mx-2"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/c/PakistanSuperLeagueOfficial"
                    target="_blank"
                  >
                    <img
                      src="/images/youtube-logo.png"
                      width={40}
                      className="mx-2"
                      alt=""
                    />
                  </a>
                  <a href="https://www.instagram.com/thepsl/" target="_blank">
                    <img
                      src={staticImagesUrl + "Instagram.png"}
                      width={40}
                      className="mx-2"
                      alt=""
                    />
                  </a>
                  
                </div>

                <div className="mt-2">
                  <p className="text-center">
                    {" "}
                    <a>&copy; 2023 All Rights Reserved</a>
                    {/* |  */}
                    {/* <a  className="text-white"> Term & Condition</a> |
                          <Link to="/privacy-policy" className="text-white"> Privacy Policy</Link> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <div className="w-100 bg-green-psl text-white bg-center-cover">
            <div className="inner-container w-lg-1150px py-3 text-center mx-auto">
              <div className="d-flex align-items-center justify-content-center my-3 text-white">
                <img
                  src="/images/psl-logo-2023.png"
                  alt=""
                  height={60}
                  onClick={() => {
                    // history.push('/')
                  }}
                  className="fs-1-1 pr-3 footer-cing-logo"
                />
                <div>
                  {/* <p className="mb-0">&copy; 2019 All Rights Reserved</p> */}
                  <p className="mb-0">
                    <div
                      onClick={() => {
                        window.location.href = `http://khaleef.com/`;
                      }}
                      className="text-white"
                    >
                      <div
                        className="pl-4 d-flex"
                        style={{
                          cursor: "pointer",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <small className="mb-0 font-weight-bold">
                          Brought to you by 
                        </small>{" "}
                        <br />
                        <img
                          style={{ marginTop: "4px" }}
                          src="/images/khaleef.png"
                          width="110"
                        />
                        <a
                          style={{ marginTop: "4px" }}
                          href="https://www.cricingif.com/series/1368/pakistan-super-league-psl-2020"
                          className="mb-0 font-weight-bold text-white"
                        >
                          PSL 2023
                        </a>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              {/*
                  <div className="mt-5">

                    <div className="pl-5">
                    <p className="text-center fs-20px">Download HBL PSL Fantasy App</p>
                    <a href="https://play.google.com/store/apps/details?id=com.cricingif.psl">
                      <img src={appImageAndroid} width={130} className="mr-3" alt="" />
                    </a>
                    <a href="https://itunes.apple.com/us/app/hbl-psl-2019-official/id1451727219?ls=1&mt=8">
                      <img src={appImageIos} width={130} alt="" />
                    </a>

                    </div>
                  </div> */}

              <div className="mt-4">
                <div className="d-flex justify-content-center">
                  <a href="https://www.facebook.com/thePSL" target="_blank">
                    <img
                      src="/images/facebook-logo.png"
                      width={40}
                      className="mx-2"
                      alt=""
                    />
                  </a>
                  <a href="https://twitter.com/thepslt20" target="_blank">
                    <img
                      src="/images/twitter-logo.png"
                      width={40}
                      className="mx-2"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/c/PakistanSuperLeagueOfficial"
                    target="_blank"
                  >
                    <img
                      src="/images/youtube-logo.png"
                      width={40}
                      className="mx-2"
                      alt=""
                    />
                  </a>
                  <a href="https://www.instagram.com/thepsl/" target="_blank">
                    <img
                      src={staticImagesUrl + "Instagram.png"}
                      width={40}
                      className="mx-2"
                      alt=""
                    />
                  </a>
                </div>

                <div className="mt-4">
                  {/* <p className="fs-11px"> <a className="text-white"> Term & Condition</a> |
                          <Link to="/privacy-policy" className="text-white"> Privacy Policy</Link></p> */}
                  <p className="fs-11px"> &copy; 2023 All Rights Reserved </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      //   <div />
    );
  }
}

export default withRouter(Routes);
