import React, { Component } from 'react';

class RefundUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        return( 
        
        <section id="main-section" className="jc-inner-page align-self-start px-3 py-3">
            <h2 className="d-flex align-center justify-content-center">HBL PSL Refund Update</h2>
            <br/>
            <br/>
            <h4 >Refund update will be available soon. 
            </h4>

            
            
            </section>
        )
    }
}

export default RefundUpdate;