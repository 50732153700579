import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  VideoCardDescription,
  getDateWithYear,
  CigPreloader,
} from "./HelperFunctions";
import axios from "axios";
import BackgroundImage from "react-background-image-loader";
import { Globals } from "./constants";
import { API_URL, PSL_API_URL } from "./CIG_APIS";
import ImageLoader from "react-load-image";

class Stories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customCssTransition: "h-transition",
      isLoading: true,
      apiPath: Globals.ApiUrl + "api/psl/v1/",
      page: 1,
      per_page: 6,
      pagesEnd: false,
      articles: [],
      type: props.ty,
      label: "",
      articlesClass: "",
      newsClass: "",
    };

    this.nextPage = this.nextPage.bind(this);
  }

  async componentDidMount() {
    // debugger;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    let categoryId = 1;
    if (this.props.match.params.type === "latest-news") {
      categoryId = 1;
    }
    if (this.props.match.params.type === "blogs") {
      categoryId = 2;
    }
    if (this.props.match.params.type === "press-release") {
      categoryId = 3;
    }
    if (this.props.match.params.type === "match-report") {
      categoryId = 4;
    }
    let apiResp;

    let url = "";
    if (categoryId === 1) {
      url = 'https://pslcms.khaleef.com/api/psl_articles?page=' + this.state.page + '&per_page=10';
    } else if (categoryId === 3 ) {
      url = 'https://pslcms.khaleef.com/api/psl_press_releases?page=' + this.state.page + '&per_page=10';
    }

    try {
      apiResp = await axios.get(url);
    } catch (err) {
      // console.log('Error fetching articles');
      // window.location.href = '/404notfound';
    }

    if (apiResp.status === 200) {
      const articles = apiResp.data.data;

      this.setState({
        articles: articles,
        customCssTransition: "v-transition",
        isLoading: false,
        page: 1,
      });
    } else {
      // console.log('Error fetching articles');
      // window.location.href = '/404notfound';
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.match.params.type !== nextProps.match.params.type) {
      let categoryId = 1;
      if (nextProps.match.params.type === "latest-news") {
        categoryId = 1;
      }
      if (nextProps.match.params.type === "blogs") {
        categoryId = 2;
      }
      if (nextProps.match.params.type === "press-release") {
        categoryId = 3;
      }
      if (nextProps.match.params.type === "match-report") {
        categoryId = 4;
      }
      let apiResp;
      let url = "";
    if (categoryId === 1) {
      url = 'https://pslcms.khaleef.com/api/psl_articles?page=' + this.state.page + '&per_page=10';
    } else if (categoryId === 3 ) {
      url = 'https://pslcms.khaleef.com/api/psl_press_releases?page=' + this.state.page + '&per_page=10';
    }

      try {
        apiResp = await axios.get(url);
      } catch (err) {
        // console.log('Error fetching articles');
        // window.location.href = '/404notfound';
      }

      if (apiResp.status === 200) {
        const articles = apiResp.data.data;
        // console.log(articles);
        this.setState({
          articles: articles,
          customCssTransition: "v-transition",
          isLoading: false,
          page: 1,
        });
      } else {
        // console.log('Error fetching articles');
        // window.location.href = '/404notfound';
      }
    }
  }

  getArticles() {
    return this.state.articles.map((article, i) => {
      const label = article.t;
      return (
        <div key={"articles-" + i} className="mt-3">
          {
            this.props.match.params.type === "press-release" ?
            <Link className="" to={"/press-release/" + article.id}>
              <StoriesCard2 data={article} image={article.full_image} />
            </Link>
            :
<Link className="" to={"/news/" + article.id}>
            <StoriesCard2 data={article} image={article.full_image} />
          </Link>
          }
          
        </div>
      );
    });
  }

  async nextPage(e) {
    if (!this.state.pagesEnd) {
      let elem = document.getElementsByClassName("loading-more-results");
      if (elem.length) elem[0].classList.remove("d-none");

      let categoryId = 1;
      if (this.props.match.params.type === "latest-news") {
        categoryId = 1;
      }
      if (this.props.match.params.type === "blogs") {
        categoryId = 2;
      }
      if (this.props.match.params.type === "press-release") {
        categoryId = 3;
      }
      if (this.props.match.params.type === "match-report") {
        categoryId = 4;
      }
      let apiResp,
        resp,
        page = this.state.page + 1;
        let url = "";
        if (categoryId === 1) {
          url = 'https://pslcms.khaleef.com/api/psl_articles?page=' + page + '&per_page=10';
        } else if (categoryId === 3 ) {
          url = 'https://pslcms.khaleef.com/api/psl_press_releases?page=' + page + '&per_page=10';
        }

      try {
        resp = await axios.get(url);
        // console.log("Next page", resp);
      } catch (err) {
        // console.log("error fetching next page");
      }

      if (resp.status == 200 && resp.data.data.length > 0) {
        if (elem.length) elem[0].classList.add("d-none");

        const Articles = this.state.articles.slice();
        resp.data.data.forEach((obj, i) => {
          Articles.push(obj);
        });
        this.setState({ page: page, articles: Articles });
      } else {
        if (elem.length) elem[0].classList.add("d-none");

        if (resp.data.length === 0) this.setState({ pagesEnd: true });
        // console.log("error fetching next page");
      }
    }
  }

  render() {
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
          </div>
        ) : (
          <div />
        )}

        <div className={this.state.customCssTransition}>
          <div className="card p-3">
            <div className="d-flex overflow-x-auto ">
              <h4
                className={
                  this.props.match.params.type === "latest-news"
                    ? "border-bottom border-danger font-weight-semi-bold p-2 m-f-14 mr-3"
                    : "font-weight-semi-bold p-2  m-f-14 mr-3"
                }
              >
                <Link className="alert-heading" to="/latest/latest-news">
                  Latest News
                </Link>
              </h4>
              
              <h4
                className={
                  this.props.match.params.type === "press-release"
                    ? "border-bottom border-danger font-weight-semi-bold p-2  m-f-14 mr-3"
                    : "font-weight-semi-bold p-2  m-f-14 mr-3"
                }
              >
                <Link className="alert-heading" to="/latest/press-release">
                  Press Release
                </Link>
              </h4>
              
              {/* <h4 className="font-weight-semi-bold p-2 ml-4 text-secondary">Articles</h4> */}
            </div>
          </div>

          <div className="card mt-2 p-3 stories-list-card">
            <div className="pt-3">
              <h4 className="text-uppercase text-black font-weight-normal mb-4 m-f-14">
                {this.props.title}
              </h4>
              {this.state.articles && this.state.articles.length > 0 ? (
                this.getArticles()
              ) : (
                <div />
              )}
            </div>
          </div>
          {!this.state.pagesEnd && this.state.articles.length ? (
            <div>
              <div className="text-center d-none loading-more-results">
                <img src="/images/loader_v2.gif?v=2" alt="" height="30" />
              </div>
              <button
                id="more-results"
                onClick={this.nextPage}
                type="button"
                className="btn btn-primary d-none"
              >
                More Results
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </section>
    );
  }
}

const StoriesFullWidthCard = (props) => (
  <div className="col-12">
    <div className="w-100 position-relative">
      <div className="sixteen-by-nine bg-center-cover" style={props.image} />
      <div className="fa fa-play play-btn-lg" />
    </div>
    <div className="py-2">
      <h5 className="font-weight-normal text-red">Match Reports</h5>
      <h5 className="font-weight-normal text-secondary">
        Pakistan vs Australia in UAE
      </h5>
      <h3 className="text-black">
        A case for Pakistan to play all three openers in UAE?
      </h3>
    </div>
  </div>
);

const StoriesFullWidthCardSm = (props) => (
  <div className="col-6 p-1">
    <div className="w-100 position-relative">
      <div className="sixteen-by-nine bg-center-cover" style={props.image} />
      <div className="fa fa-play play-btn-lg play-btn-sm" />
    </div>
    <div className="py-2">
      <h5 className="font-weight-normal text-red">Match Reports</h5>
      <h5 className="font-weight-normal text-secondary">
        Pakistan vs Australia in UAE
      </h5>
      <h5 className="font-weight-normal text-black">
        A case for Pakistan to play all three openers in UAE?
      </h5>
    </div>
  </div>
);

const getSummary = (props) => {
  let summary;
  if (props.data.summary) {
    summary = props.data.summary;
  } else if (props.data.body) {
    summary = stripTags(props.data.body);
  }
  return summary;
};

const stripTags = (string) => {
  var StrippedString = string.replace(/(<([^>]+)>)/gi, "");
  return StrippedString;
};

export const CIGVideoCardDescription = (props) => (
  <div className="custom-clamp-text flex-fill">
    <div className="d-flex justify-content-between">
      <p className="font-weight-normal text-secondary mb-0 m-f-11 text-nowrap-no-lh mw-75px">
        {props.showDate ? getDateWithYear(props.data.created_at) : ""}
      </p>
    </div>
    <h4 className="font-weight-normal mb-0 m-f-12 d-block text-nowrap-no-lh">
      {props.data.title}
    </h4>
    
  </div>
);

export const StoriesCard = (props) => (
  <div className="d-flex my-2">
    <div className="w-30">
      <BackgroundImage
        className="four-by-three bg-center-cover"
        src={props.data.image}
        placeholder={Globals.placeholder4x3}
      />
    </div>
    <div className="pl-3 flex-fill">
      <VideoCardDescription
        showDescription={true}
        showDate={true}
        showRedTitle={props.type}
        data={props.data}
        title={props.data.title}
        description={getSummary(props)}
      />
    </div>
  </div>
);
export const StoriesCard2 = (props) => (
  <div className="d-flex my-2 w-100">
    <div className="w-25">
      <ImageLoader src={props.image}>
        <img className="w-100" />
        <div />
        <CigPreloader />
      </ImageLoader>
    </div>
    <div className="pl-3 flex-fill">
      <CIGVideoCardDescription
        showDescription={false}
        showDate={true}
        showRedTitle={true}
        data={props.data}
        title={props.data.title}
      />
    </div>
  </div>
);

export default Stories;
