import React, { Component } from 'react';
import { Globals } from './constants';

class Footer extends Component {
  render() {
    return (
        <footer>
          <div className="container py-5">
              <div className="d-flex">

                  <div className="d-none align-self-center">&copy; 2018 {Globals.product}. All rights reserved</div>
                  <div className="ml-auto">
                    <img onClick={this.props.downloadApp} height="50" src="/images/ios_icon.png" style={{cursor:'pointer'}} />
                    <img onClick={this.props.downloadApp} height="50" src="/images/android_icon.png" style={{cursor:'pointer'}} />
                  </div>

              </div>
          </div>
        </footer>


    );
  }
}

export default Footer;
