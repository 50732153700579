import React, { Component } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
// import {Globals} from './constants';
import { CIG_API_URL, PSL_PROD_PLAYER_IMAGES_BASE_URL } from "./CIG_APIS";
// import { API_URL, CIG_API_URL, PSL_PROD_PLAYER_IMAGES_BASE_URL } from "./CIG_APIS";
import * as _ from "lodash";
import { getTeamLogoAndNameById } from "./HelperFunctions";
import ImageLoader from "react-load-image";

class Rankings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customCssTransition: "h-transition",
      isLoading: true,
      apiResp: [],
      teamId: "",
    };
    // this.apiPath = Globals.ApiUrl + 'api/psl/v1/teams';
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    const id = this.props.match.params.id;
    let apiPath = "https://back.cricwick.net/api/v4/series/1493/squad/" + id,
      resp = [],
      url = apiPath,
      teamId = id;

    try {
      resp = await axios.get(url);
    } catch (error) {}

    if (resp.status === 200) {
      const R = resp.data;
      this.setState({
        apiResp: R,
        customCssTransition: "v-transition",
        isLoading: false,
        teamId: teamId,
      });
      // console.log(filter+' rankings set:', this.state.apiResp);
    } else {
      // console.log('error fetch '+filter+' rankings!');
    }
    // rankings
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      const id = this.props.match.params.id;
      let apiPath = "https://back.cricwick.net/api/v4/series/1493/squad/" + id,
        resp = [],
        url = apiPath,
        teamId = id;

      try {
        resp = await axios.get(url);
      } catch (error) {}

      if (resp.status === 200) {
        const R = resp.data;
        this.setState({
          apiResp: R,
          customCssTransition: "v-transition",
          isLoading: false,
          teamId: teamId,
        });
        // console.log(filter+' rankings set:', this.state.apiResp);
      } else {
        // console.log('error fetch '+filter+' rankings!');
      }
    }
  }
  render() {
    const players = _.clone(this.state.apiResp, true);
    const groupedPlayers = _.chunk(players, 3);
    const { teamId } = this.state;
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start"
      >
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
          </div>
        ) : (
          <div />
        )}

        <div id="teamsCard" className={"card p-3 team-id-" + teamId}>
        {getTeamLogoAndNameById(teamId)}
          
          {groupedPlayers.map((playersChunk, i) => (
            <div
              style={{ marginTop: "20px" }}
              className="d-flex justify-content-between align-items-start"
              key={i}
            >
              {playersChunk.map((player, j) => (
                <div
                  key={i + j}
                  className="w-25 d-flex flex-column align-items-center players-row text-center"
                >
                  <ImageLoader
                    src={player.full_display_picture_url}
                  >
                    <img className="w-100" alt="" />
                    <img
                      className="w-100"
                      src={`/images/placeholder4x3.jpg`}
                      alt=""
                    />
                    <Preloader />
                  </ImageLoader>

                  <div className="ff-gotham-medium">{player.name}</div>
                  <div className="fs-12px text-medium-grey ff-gotham-medium">
                    {player.playingrole}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export function Preloader(props) {
  return (
    <img
      className="w-100"
      src={`/images/placeholder16x9.jpg`}
      alt=""
    />
  );
}

const getTeamNameById = (id) => {
  if (id === "4586") return "Peshawar Zalmi";
  if (id === "4587") return "Lahore Qalandars";
  if (id === "4583") return "Karachi Kings";
  if (id === "4585") return "Quetta Gladiators";
  if (id === "4588") return "Islamabad United";
  if (id === "4584") return "Multan Sultans";
};

const getTeamsRow = (props) => {
  if (!(typeof props.data == "undefined") && props.data.length) {
    return props.data.map((team, i) => {
      return (
        <RankingsTableRow
          key={"rtr-" + i}
          image={props.image}
          showPlayerDetailText={false}
          data={team}
          rank={i + 1}
        />
      );
    });
  } else {
    return;
  }
};
export const RankingsTable = (props) => (
  <table className="mb-3 points-table fs-1p2">
    <tbody>
      <RankingsTableTitle />
      {getTeamsRow(props)}
    </tbody>
  </table>
);

const RankingsTableTitle = (props) => (
  <tr>
    <td
      valign="middle"
      className="text-black w-50 font-weight-semi-bold py-3 px-1 m-f-12"
      align="left"
    >
      Teams
    </td>
    {/* <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" align="center">Matches</td>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" align="center">Points</td>
        <td valign="middle" className="text-black font-weight-semi-bold py-3 px-1 m-f-12" align="center" width="1">Rating</td> */}
  </tr>
);

const RankingsTableRow = (props) => (
  <tr>
    <td
      valign="middle"
      className="font-weight-normal px-1 py-1 d-flex align-items-center"
      align="left"
    >
      <div className="mw-2rem mr-2">
        <div
          className="four-by-three bg-center-contain"
          style={{
            backgroundImage: `url(${props.data.flag})`,
            backgroundColor: "transparent",
          }}
        />
      </div>
      {props.data.name}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.match_count}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.points}
    </td>
    <td valign="middle" className="font-weight-normal px-1 py-1" align="center">
      {props.data.rating}
    </td>
  </tr>
);

export default Rankings;
