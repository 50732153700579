import React, { Component } from "react";

class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section
        id="main-section"
        className="jc-inner-page align-self-start px-3 py-3"
      >
        <h2 className="d-flex align-center justify-content-center">
          Watch HBL PSL 7
        </h2>
        <br />
       
        <div className={`mt-5`}>
          <img
            src={`/images/broadcasters.jpeg`}
            alt={`Watch PSL 7 on these channels`}
            style={{
              width: `100%`,
              height: `109%`,
              cursor: 'pointer'
            }}
            onClick={() => window.open(`https://youtube.com/c/PakistanSuperLeagueOfficial`, `_blank`)}
          />
        </div>
        {/* <div>
          <table className="border-top mb-3">
            <tbody>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Pakistan
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  PTV Sports & Geo Super
                </td>
              </tr>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  North America
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Willow TV
                </td>
              </tr>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Carribean
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Flow Sports
                </td>
              </tr>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Sub-Saharan Africa
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Super Sports
                </td>
              </tr>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  United Kingdom
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Sky Sports
                </td>
              </tr>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  South Asia (excluding Pakistan)
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Sony Pictures Network
                </td>
              </tr>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  NZ
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  Sky NZ
                </td>
              </tr>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  MENA, Aus and Row*
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  tapmad.com/psl6
                </td>
              </tr>
              <tr className="bg-light">
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  UAE
                </td>
                <td
                  className="px-3 pb-0 pt-3 pb-3 font-weight-semi-bold"
                  style={{
                    fontSize: `20px`,
                  }}
                >
                  etisalat CricLife 2 channel no 788
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </section>
    );
  }
}

export default Tickets;
