import React from "react";
import "./css/become-a-part.scss";

import ImageLoader from 'react-load-image';
import {getDateWithYear, CigPreloader} from './HelperFunctions';

class Poll extends React.Component {

    render() {
       return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
             <div className="article-image mb-3">
                    {/* <img className="w-100" src={this.state.apiResp.image} /> */}
                    <ImageLoader src={'https://asset.cricvids.co/cricwick-assets/images/vop_psl.jpeg'} >
                        <img className="w-100" />
                        <div />
                        <CigPreloader />
                    </ImageLoader>
                </div>
            <iframe title="poll" width="620" src="https://strawpoll.com/embed/v5fpyuzbd" style={{width: '100%', height: "980px"}} frameborder="0" allowfullscreen></iframe>
        </section>
       )
    }
}

export default Poll;