// import {Telco} from './telco';
export const Globals = { og: {}, login: {} };
const Telco = "cricingif";

// !!!!!!!!!!!~~~~~CREATE A FILE AND ADD TELCO~~~~~!!!!!!!!!!!
// !!!!!!!!!!!  src/telco.js                       !!!!!!!!!!!
// !!!!!!!!!!!  export const Telco = "cricingif";   !!!!!!!!!!!
export const staticImagesUrl =
  "https://storage.cricingif.com/psl-prod-images/static-assets/";

let version = "1.9";

if (Telco === "cricingif") {
  // console.log("Environment", process.env.NODE_ENV);
  Globals.ApiUrl = "http://54.245.34.7:8080/"; //staging
  // // Globals.ApiUrl = "https://back.cricwick.net/";//live
  Globals.pusherKey = "96a11f3e2f71d6b34e4f"; //staging
  // // Globals.pusherKey = "44382e5777aebbc67aad";//live
  Globals.Api2 = "https://mobisub.cricwick.mobi/main/";
  Globals.NodeApi = "https://staging.cricwick.net:13002/";

  Globals.ssl = true;
  Globals.firebase = true;

  Globals.logo = "";
  Globals.imgRightAppDownload = "";
  Globals.title =
    "PSL Cricket - Enjoy live cricket and watch key cricket moments";
  Globals.telco = Telco;
  Globals.cssFor = Telco;
  Globals.product = "PSL Cricket";
  Globals.ios = "";
  Globals.androidMarket = "";
  Globals.android = "";

  Globals.og.title = "PSL Cricket";
  Globals.og.description = "Enjoy live cricket and watch key cricket moments";
  Globals.og.image = "";

  Globals.login.enterNumber = "Please Enter Your Number";
  Globals.login.phonePlaceholder = "03xx xxxx xxx";
  Globals.login.pinPlaceholder = "XXXXX";
  Globals.login.enterPin =
    "Please enter the Pin code you received in SMS. Prepaid users will be charged 2.38 per day.";
  Globals.login.image = "";
  Globals.login.invalidPhone =
    "Please enter phone number in following format " +
    Globals.login.phonePlaceholder;

  Globals.placeholder4x3 = "/images/sda.jpg";
  Globals.placeholder16x9 = "/images/placeholder16x9.jpg";
  Globals.placeholderVideo4x3 = "/images/placeholderVideo4x3.jpg";
  Globals.placeholderPlain4x3 = "/images/placeholderPlain4x3.jpg";
} else if (Telco == "ufone") {
} else if (Telco === "zain") {
  //zain ksa; cric.sa.zain.com
  // Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
  Globals.ApiUrl = "http://back.cricwick.net/"; //live
  // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
  Globals.pusherKey = "44382e5777aebbc67aad"; //live
  Globals.Api2 = "http://mobisub.cricwick.mobi/main/";
  Globals.NodeApi = "http://staging.cricwick.net:3002/";

  Globals.logo = "/images/zain/cricwick-logo.jpg?v=1";
  Globals.telcoLogo = "/images/zain/telco_logo.png";
  Globals.imgRightAppDownload = "/images/zain/app_download.png";
  Globals.title = "Cricwick - Enjoy live cricket and watch key cricket moments";

  Globals.telco = Telco;
  Globals.cssFor = Telco;

  Globals.product = "Cricwick";
  Globals.ios =
    "https://itunes.apple.com/us/app/cricwick/id1436495759?ls=1&mt=8";
  Globals.androidMarket = "market://details?id=com.Khaleef.cricwick";
  Globals.android =
    "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick";

  Globals.og.title = "Cricwick";
  Globals.og.description = "Enjoy live cricket and watch key cricket moments";
  Globals.og.image = "";

  Globals.login.enterNumber = "Please Enter Your Number";
  Globals.login.phonePlaceholder = "966 xxx xxx xxx";
  Globals.login.pinPlaceholder = "XXXXX";
  Globals.login.enterPin = "Please enter the Pin code you received in SMS.";
  Globals.login.image = "/images/zain/img-download.jpg?v=" + version;
  Globals.login.invalidPhone =
    "Please enter phone number in following format " +
    Globals.login.phonePlaceholder;

  Globals.placeholder4x3 = "/images/zain/placeholder4x3.jpg";
  Globals.placeholder16x9 = "/images/zain/placeholder16x9.jpg";
  Globals.placeholderVideo4x3 = "/images/zain/placeholderVideo4x3.jpg";
  Globals.placeholderPlain4x3 = "/images/zain/placeholderPlain4x3.jpg";
} else {
  // Globals.ApiUrl = "http://54.245.34.7:8080/";//staging
  Globals.ApiUrl = "https://back.cricwick.net/"; //live
  // Globals.pusherKey = "96a11f3e2f71d6b34e4f";//staging
  Globals.pusherKey = "44382e5777aebbc67aad"; //live
  Globals.Api2 = "https://mobisub.cricwick.mobi/main/";
  Globals.NodeApi = "http://staging.cricwick.net:3002/";

  Globals.logo = "/images/cricwick/cricwick-logo.jpg?v=1";
  Globals.imgRightAppDownload = "/images/cricwick/app_download.png";
  Globals.title = "Cricwick - Enjoy live cricket and watch key cricket moments";
  Globals.telco = "cricingif";
  Globals.cssFor = Telco;
  Globals.product = "Cricwick";
  Globals.ios = "market://";
  Globals.android =
    "https://play.google.com/store/apps/details?id=com.Khaleef.cricwick";

  Globals.og.title = "Cricwick";
  Globals.og.description = "Enjoy live cricket and watch key cricket moments";
  Globals.og.image = "";

  Globals.login.enterNumber = "Please Enter Your Number";
  Globals.login.phonePlaceholder = "03xx xxxx xxx";
  Globals.login.pinPlaceholder = "XXXXX";
  Globals.login.enterPin = "Please enter the Pin code you received in SMS.";
  Globals.login.image = "/images/cricwick/img-download.jpg?v=" + version;
  Globals.login.invalidPhone =
    "Please enter phone number in following format " +
    Globals.login.phonePlaceholder;

  Globals.placeholder4x3 = "/images/cricwick/placeholder4x3.jpg";
  Globals.placeholder16x9 = "/images/cricwick/placeholder16x9.jpg";
  Globals.placeholderVideo4x3 = "/images/cricwick/placeholderVideo4x3.jpg";
  Globals.placeholderPlain4x3 = "/images/cricwick/placeholderPlain4x3.jpg";
}
if (process.env.buildenv === "staging") {
  Globals.ApiUrl = "http://54.245.34.7:8080/"; //staging
  Globals.pusherKey = "96a11f3e2f71d6b34e4f"; //staging
} else if (process.env.buildenv === "production") {
  Globals.ApiUrl = "https://back.cricwick.net/"; //live
  Globals.pusherKey = "44382e5777aebbc67aad"; //live
}
