import React, { Component } from "react";
import { MainCarouselSwipe } from "./InitializePlugins";
import {
  MatchScorecardInningsDetails,
  getMatchStateText,
  getLocalTimeInAmPm,
  getTimeInAmPm,
  getDateWithoutYear,
  getTeamColorClass,
} from "./HelperFunctions";
import { ApiUrl, pusherKey } from "./constants";
// import { MatchScorecardInningsDetails, getMatchStateText } from './HelperFunctions';
import { Globals } from "./constants";
import axios from "axios";
import Pusher from "pusher-js";
import { map } from "./API_MAPPER";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import { API_URL, CIG_API_URL } from "./CIG_APIS";

class Maincarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveMatchResp: {},
      customCssTransition: "h-transition",
      leftHandleClass: "",
      rightHandleClass: "",
      loading: true,
    };
    this.handleSwipe = MainCarouselSwipe.bind(this);
    this.windowLoadListener = false;
    this.liveMatchHandler = this.liveMatchHandler.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getFromApi = this.getFromApi.bind(this);

    this.poller = null;
  }

  liveMatchHandler(data) {

    const liveMatchesList = this.state.liveMatchResp.live_matches || [];
    const liveMatches = liveMatchesList.map(
      (obj, i) => (data.id === obj.id ? data : obj)
    );
    let liveMatchResp = this.state.liveMatchResp;
    liveMatchResp.live_matches = liveMatches;
    this.setState({ liveMatchResp: liveMatchResp });
  }

  refresh(data) {
    this.getFromApi();
  }

  async getFromApi() {
    const self = this;
    const url = `https://back.cricwick.net/api/v3/main/live_matches?telco=mobilink&app_name=CricwickWeb`;
    fetch(url)
      .then(function(response) {
        response.json().then(function(schedules) {
          self.setState({
            liveMatchResp: schedules.live_matches,
            customCssTransition: "v-transition",
            loading: false,
          });
        });
      })
      .catch(function(error) {
        console.log("Error getting live Matches", error);
      });
  }

  componentDidMount() {
    if (document.readyState === "complete") {
      // if document has finished loading all the scripts
      this.handleSwipe.call(); // call videos swipe initialization
    } else {
      // if document is still loading scripts i.e readyState === 'loading'
      this.windowLoadListener = true; // Set listener to true to remove it in component will unmount react lifecycle method
      window.addEventListener("load", this.handleSwipe); // add listener
    }
    const pusher = new Pusher(Globals.pusherKey, {
      cluster: "ap1",
      encrypted: true,
    });
    const channel = pusher.subscribe("live_matches");
    channel.bind("match_update", this.liveMatchHandler);
    channel.bind("refresh", this.refresh);
    this.getFromApi();

    this.poller = setInterval(this.getFromApi, 30000);
  }
  componentWillUnmount() {
    if (this.windowLoadListener)
      window.removeEventListener("load", this.handleSwipe); //remove listener if listening
    clearInterval(this.poller);
  }

  render() {
    const { selectedMatchId } = this.props;
    if (
      !this.state.liveMatchResp.length ||
      this.state.liveMatchResp.length <= 0
    )
      return (
        <div className="text-center text-grey main-carousel-error d-flex justify-content-center align-items-center">
          {this.state.loading
            ? "Loading ..."
            : "Match data is not available at the moment"}
        </div>
      );
    let matchesData = getMatchCard(this.state.liveMatchResp);

    return (
      <div
        id="main-carousel-container"
        className="container zi-1 bg-body position-relative"
      >
        <div className={"cardss " + this.state.customCssTransition}>
          {matchesData.map((obj, i) => (
            <a key={i} href={obj.match_state === `Scheduled` ? "https://cricwick.net/" : `https://cricwick.net/match/${obj.id}/summary/1`} target="_blank">
              {getMatchCardTemplate(obj, i, "shortName")}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export const getMatchCard = (matches) => {
  let sortedMatches = matches.sort(
    (a, b) => new Date(a.match_start) - new Date(b.match_start)
  );

  let pslMatches = sortedMatches.filter(
    (m) => m.series.title === `Pakistan Super League 2021`
  );
  const liveMatches = pslMatches.filter((m) => m.match_state === `Live`); // live
  const upcomingMatches = pslMatches.filter(
    (m) => m.match_state === `Scheduled`
  ); // upcoming

  return [...liveMatches, ...upcomingMatches];
};

const returnScoreDivs = (obj, teamNumber) => {
  let innings = [];
  for (let i = 0; i < obj.innings.length; i++) {
    innings.push({ ...obj.innings[i] });
  }
  let team = innings.filter((m) => m.batting_team_id === obj[teamNumber])[0];
  if (team) {
    return (
      <React.Fragment>
        <div
          className={`team-name ${
            team.overs !== team.total_overs ? `` : `text-grey`
          } fs-14px ff-gotham-medium`}
        >
          {`${team.runs ? team.runs : `0`}/${
            team.wickets ? team.wickets : `0`
          }`}{" "}
        </div>
        {team.overs !== team.total_overs ? (
          <div
            className={`team-name ${
              team.overs !== team.total_overs ? `` : `text-medium-grey`
            } fs-10px ff-gotham-light`}
          >
            {`${team.overs}`}
          </div>
        ) : null}
      </React.Fragment>
    );
  } else {
    return null;
  }
};

const getScoreTemplate = (obj, type) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div
        className={
          "d-flex align-items-center justify-content-between mh-60px border-left-2px " +
          getTeamColorClass(obj.team1Id)
        }
      >
        <img
          className="mx-2"
          width="40px"
          alt={`${obj.teamA.name} flag`}
          src={obj.teamA.flag_url}
        />
        <div className="flex-column">
          <div className="team-name text-black ff-gotham-medium fs-16px">
            {obj.teamA.short_name}
          </div>
          {returnScoreDivs(obj, `team_1_id`)}
        </div>
      </div>
      <div className="flex-column d-flex align-items-center justify-content-start">
        <div className="text-medium-grey">vs</div>
        <div
          className={
            type === "shortName"
              ? "text-medium-grey closing-comment px-1 m-top-10"
              : "text-medium-grey text-center m-top-10"
          }
        >
          {obj.closingComment}
        </div>
      </div>
      <div
        className={
          "d-flex align-items-center justify-content-between mh-60px border-right-2px " +
          getTeamColorClass(obj.team2Id)
        }
      >
        <div className="flex-column">
          <div className="team-name text-right text-black fs-16px ff-gotham-medium">
            {obj.teamB.short_name}
          </div>
          {returnScoreDivs(obj, `team_2_id`)}
        </div>
        <img
          className="mx-2"
          width="40px"
          alt={`${obj.teamB.name} flag`}
          src={obj.teamB.flag_url}
        />
      </div>
    </div>
  );
};

export const getMatchCardTemplate = (obj, i, type) => (
  <div key={"lv-match-c-" + i} className="card main-carousel mx-2">
    <div className="card-body m-f-11 py-2 px-2 topline d-flex justify-content-between">
      <div>
        <span>{" " + obj.title + " at "}</span>
        <span className="text-dark-grey">{obj.venue.title}</span>
        <span>{" on "}</span>
        <span className="text-dark-grey">
          {getDateWithoutYear(obj.match_start) +
            ", " +
            getTimeInAmPm(obj.match_start)}
        </span>
      </div>
      <div>
        {obj.match_state === `Live` && (
          <span className="text-red font-weight-bold">Live</span>
        )}
        {/* {obj.match_state === `Scheduled` && (
          <span className="text-grey font-weight-bold">Completed</span>
        )} */}
        {obj.match_state === `Scheduled` && (
          <span className="text-grey font-weight-bold">Upcoming</span>
        )}
      </div>
    </div>
    {getScoreTemplate(obj, type)}
  </div>
);

export default Maincarousel;
