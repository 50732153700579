import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Globals } from './constants';
import axios from 'axios';
import {space2hyphen} from './HelperFunctions'
import { API_URL, CIG_API_URL } from "./CIG_APIS";
import { map } from "./API_MAPPER";
import * as _ from "lodash";

import {getTimeInAmPm, getDateWithoutYear} from './HelperFunctions'; 

import {getMatchCardTemplate} from './Maincarousel';



class AllSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
        customCssTransition: 'h-transition',
        isLoading: true,
        allSeriesResp: [],
        selectedLive: (this.props.match.params.state==='live'),
        selectedUpcoming: (this.props.match.params.state==='upcoming'),
        selectedRecent: (this.props.match.params.state==='recent'),
        page: 1,
        pagesEndLive: false,
        pagesEndRecent: false,
        pagesEndUpcoming: false,
    };
    this.getMarkup = this.getMarkup.bind(this);
    this.seriesNav = this.seriesNav.bind(this);
  };

  async getFromApi() {
    const self = this;
    const url = `https://back.cricwick.net/api/v3/main/live_matches?telco=mobilink&app_name=CricwickWeb`;      
    try {
      const resp = await axios.get(url);
      const matchesData = getMatchCard(resp.data.live_matches);
      console.log("Matches data", matchesData);
      self.setState({ allSeriesResp: matchesData, customCssTransition: 'v-transition', isLoading: false });
    } catch (err) {
      console.log("Error fetching api all fixtures", err);
    };
}

    async componentDidMount() {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        const self = this;
        self.getFromApi()

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.state==='live' && !this.selectedLive) 
          this.setState({selectedLive: true, selectedUpcoming: false, selectedRecent: false});
        if (nextProps.match.params.state==='upcoming' && !this.selectedUpcoming) 
          this.setState({selectedUpcoming: true, selectedRecent: false, selectedLive: false});
        if (nextProps.match.params.state==='recent' && !this.selectedRecent) 
          this.setState({selectedRecent: true, selectedUpcoming: false, selectedLive: false});
    }
    getMarkup() {
        let selected = "", stateInt = 0;

        if (this.state.selectedLive) {
          selected = "live";
          stateInt = 1;
        }
        if (this.state.selectedRecent) {
          selected = "recent";
          stateInt = 0;          
        }
        if (this.state.selectedUpcoming) {
          selected = "upcoming";
          stateInt = 2;
        } 
        let matches = [];
        if (stateInt === 1) {
          matches = this.state.allSeriesResp.length ? this.state.allSeriesResp.filter( all => all.val === 1 )[0].matches : [];
          // matches = _.concat(liveMatches, completedMatches);
        } 
        if (stateInt === 2) {
          matches = this.state.allSeriesResp.length ? this.state.allSeriesResp.filter( all => all.val === 2 )[0].matches : [];
        }
        if (stateInt === 0) {
          matches =  this.state.allSeriesResp.length ? this.state.allSeriesResp.filter( all => all.val === 0)[0].matches : [];          
        }
        

        if (this.state.allSeriesResp && this.state.allSeriesResp.length && matches.length) {
          
          return (matches.map((obj, i) => 
            // <a key={i} className="my-2" href={"https://www.cricingif.com/match/" + obj.matchId + "/" + obj.slug + "/overview"}>
            <a key={i} className="my-2" href={obj.match_state === `Scheduled` ? "https://cricwick.net/" : `https://cricwick.net/match/${obj.id}/summary/1`} target="_blank">
            {getMatchCardTemplate(obj, i)}</a>
          ))
          
        } else {
          return (<div>No {selected} matches</div>)
        }

        
    }
    seriesNav(e) {

        if (e.target.id !== this.state.selected) this.setState({selected: e.target.id});
    }

  render() {
    
    const liveMatchesCount = this.state.allSeriesResp.length ? this.state.allSeriesResp.filter( all => all.val === 1 )[0].matches.length : 0;
    const upcomingMatchesCount = this.state.allSeriesResp.length ? this.state.allSeriesResp.filter( all => all.val === 2 )[0].matches.length : 0;
    const recentMatchesCount = this.state.allSeriesResp.length ? this.state.allSeriesResp.filter( all => all.val === 0 )[0].matches.length : 0;
    
    const { allSeriesResp, selectedLive, selectedUpcoming } = this.state;

    if (allSeriesResp.length && selectedLive && liveMatchesCount === 0) {
      if (upcomingMatchesCount>0) {
        return (
          <Redirect to="/fixtures/upcoming" />
        )
      }
      if (upcomingMatchesCount===0 && recentMatchesCount>0) {
        return (
          <Redirect to="/fixtures/recent" />
        )
      }
      
    }
    else if (allSeriesResp.length && selectedUpcoming && upcomingMatchesCount === 0) {
      if (recentMatchesCount>0) {
        return (
          <Redirect to="/fixtures/recent" />
        )
      }
      if (liveMatchesCount>0 && recentMatchesCount === 0) {
        return (
          <Redirect to="/fixtures/live" />
        )
      }
    }
    else {

      return (
          <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
          {
              (this.state.isLoading) ?
              <div className="main-loader card min-height-80vh">
                <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
              </div> : <div></div>
          }

          <div className={this.state.customCssTransition}>
          
              <div className="card p-3">
                  <div className="d-flex overflow-x-auto">
                      { liveMatchesCount > 0 && <Link to="/fixtures/live">
                          <h4 className={(this.state.selectedLive) ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                          : "font-weight-semi-bold p-2  m-f-14"}>Live</h4>
                      </Link>
                      }
                      { upcomingMatchesCount > 0 && <Link to="/fixtures/upcoming">
                          <h4 className={this.state.selectedUpcoming 
                          ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                          : "font-weight-semi-bold p-2  m-f-14"}>Upcoming</h4>
                      </Link>
                      }
                      { recentMatchesCount > 0 && <Link to="/fixtures/recent">
                          <h4 className={this.state.selectedRecent 
                          ? 'border-bottom border-danger font-weight-semi-bold p-2  m-f-14' 
                          : "font-weight-semi-bold p-2  m-f-14"}>{
                            (liveMatchesCount>0||upcomingMatchesCount>0)?"Results":"Results"
                          }</h4>
                      </Link>
                      }
                      
                  </div>
              </div>


            <div className="card p-3 my-2">
              {
                  this.getMarkup()
              } 
            </div>
            </div>
          </section>


      );
    }
  }
}

export const getMatchCard = (matches) => {
  const _data = [
      { title: "Recently Finished", matches: [], val: 0 },
      { title: "Live Cricket", matches: [], val: 1 },
      { title: "Upcoming Matches", matches: [], val: 2 }
  ];
  _.each(matches, function (match) {
    if (match.series.title === `Pakistan Super League 2021`){
      if (match.match_state === `Completed`) _data[0].matches.push(match);
      else if (match.match_state === `Live`) _data[1].matches.push(match);
      else if (match.match_state === `Scheduled`) _data[2].matches.push(match);
    }
  });
  return _data;
};


export const getDay = (date) => ( new Date(date).toDateString().split(' ')[0] )
export const getMonth = (date) => ( new Date(date).toDateString().split(' ')[1] )
export const getDate = (date) => ( new Date(date).toDateString().split(' ')[2] )
export const getYear = (date) => ( new Date(date).toDateString().split(' ')[3] )

export default AllSeries;
