import React, { Component } from "react";
// import { BrowserRouter as Router, Route } from "react-router-dom";
import AllVideosComponent from "./AllVideosComponent";
// import HomeSeriesCard from "./HomeSeriesCard";
// import { StoriesCard } from "./Stories";
import { PointsTableComponent } from "./HelperFunctions";
// import { FeaturedSeriesCard } from "./Leftsection";
import { Globals } from "./constants";
import axios from "axios";
import { Link } from "react-router-dom";
// import {
//   API_URL,
//   CIG_API_URL,
//   PSL_API_URL,
//   FPL_API_URL,
//   PSL_PROD_STATIC_IMAGES_BASE_URL,
//   PSL_IMAGES_BASE_URL,
// } from "./CIG_APIS";
import { CIG_API_URL, PSL_API_URL, PSL_IMAGES_BASE_URL } from "./CIG_APIS";
import * as _ from "lodash";
import Leftsection from "./Leftsection";
// import { VideosListFourByThree, InHouseContent, Rankings, FullWidthCard, MobileFeaturedSeries, getDateOrCountdown,
//   getTeamLogoAndNameById } from './HelperFunctions';
import { getTeamLogoAndNameById } from "./HelperFunctions";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seriesApiPath: Globals.ApiUrl + "api/v2/series/featured",
      seriesApiResp: [],
      homeApiResp: [],
      currentRankings: "odi",
      customCssTransition: "h-transition",
      isLoading: true,
      page: 1,
      pagesEnd: false,
      hasError: false,
      pointsTable: [],
      recentMatch: [],
    };

    this.isMobile = window.innerWidth < 992;
    // this.rankingsChange = this.rankingsChange.bind(this);
    this.getMarkup = this.getMarkup.bind(this);
    this.getFromApi = this.getFromApi.bind(this);
    // this.nextPage = this.nextPage.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getFromApi();
  }

  refresh() {
    //test comment
    this.setState(
      { isLoading: true, hasError: false, customCssTransition: "h-transition" },
      this.getFromApi
    );
  }

  async getFromApi() {

    const url = "https://back.cricwick.net/api/series_pool_points_table/1493";
    let response = await axios.get(url);

    if (response.status === 200) {
      this.setState({
        homeApiResp: null,
        customCssTransition: "v-transition",
        isLoading: false,
        pointsTable: response.data.data.reverse()
      })
    } else {
      this.setState({ hasError: true, isLoading: false })
    }


    // let homeApiResp,
    //   homeApiUrl = PSL_API_URL + "api/psl/videos/latest/0";
    // const pointsTableApiUrl = CIG_API_URL + "api/psl/points/table";


    // const promises = [homeApiUrl, pointsTableApiUrl].map(
    //   (obj) =>
    //     new Promise((resolve) =>
    //       axios
    //         .get(obj)
    //         .then((resp) => resolve(resp.data))
    //         .catch((err) => resolve([]))
    //     )
    // );

    // Promise.all(promises)
    //   .then((resp) =>
    //     this.setState({
    //       homeApiResp: resp[0],
    //       customCssTransition: "v-transition",
    //       isLoading: false,
    //       pointsTable: resp[1],
    //     })
    //   )
    //   .catch((err) => this.setState({ hasError: true, isLoading: false }));
  }

  rankingsChange(e) {
    if (e.target.id !== this.state.currentRankings)
      this.setState({ currentRankings: e.target.id });
  }

  getMarkup() {
     
    const  pointsTable = _.get(this.state, "pointsTable", null);
    const isMobile = window.innerWidth < 992;
    return (
      <div>
        {/* <div>
          <Link className="dropdown-item" to="/tickets">
            <h2 className="heading text-center my-3 ff-gotham-bold fs-32px fs-28px-sm text-green text-uppercase mx-2">
              Tickets
            </h2>
            <h2
              className="heading text-center my-2 ff-gotham-bold 
                         text-green text-uppercase mx-2"
            >
              Tickets
            </h2>
          </Link>
        </div> */}

        {pointsTable && <PointsTableComponent pointsTable={pointsTable} />}
        
        {isMobile && <Leftsection />}
      </div>
    );
  }

  render() {
    return (
      <section
        id="main-section"
        className="px-lg-0 jc-inner-page align-self-start bg-white"
      >
        {this.state.isLoading ? (
          <div className="main-loader card min-height-80vh">
            <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
          </div>
        ) : (
          <div />
        )}

        <div className={"card " + this.state.customCssTransition}>
          {this.state.hasError ? (
            <div className="has-error card min-height-80vh p-3">
              <h5>
                {" "}
                Error or no result{" "}
                <button className="btn btn-default" onClick={this.refresh}>
                  Try Again
                </button>
              </h5>
            </div>
          ) : (
            this.getMarkup()
          )}

          {/* {
            (!this.state.pagesEnd && this.state.homeApiResp.length) ?
            (
              <div>
                <div className="text-center d-none loading-more-results">
                <img src="/images/loader_v2.gif" height="30" alt="" /></div>
                <button id="more-results" onClick={this.nextPage} type="button" 
                className="btn btn-primary d-none">More Results</button>
              </div>
            )
            : <div></div>
          } */}

          {!this.state.isLoading && (
            <div id="home-psl-teams" className="text-uppercase p-4">
              {/* <h2 className="text-center mt-4 mb-4">HBL PSL 2019 Teams </h2> */}
              <div className="d-flex justify-content-center align-items-center">
                {/* <img src="/images/latest_videos_icon.png" /> */}
                <h2 className="heading text-center my-3 ff-gotham-bold fs-32px fs-28px-sm text-green text-uppercase mx-2">
                  HBL PSL Teams
                </h2>
                {/* <img src="/images/latest_videos_icon.png" /> */}
              </div>

              <div className="d-flex w-100">
                <Link to="/teams/4588" className="w-50">
                  {getTeamLogoAndNameById("4588")}
                </Link>

                <Link to="/teams/4587" className="w-50">
                  {getTeamLogoAndNameById("4587")}
                </Link>
              </div>

              <div className="d-flex w-100">
                <Link to="/teams/4583" className="w-50">
                  {getTeamLogoAndNameById("4583")}
                </Link>

                <Link to="/teams/4584" className="w-50">
                  {getTeamLogoAndNameById("4584")}
                </Link>
              </div>

              <div className="d-flex w-100">
                <Link to="/teams/4585" className="w-50">
                  {getTeamLogoAndNameById("4585")}
                </Link>

                <Link to="/teams/4586" className="w-50">
                  {getTeamLogoAndNameById("4586")}
                </Link>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default Home;
