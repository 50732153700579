import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";
import './App.scss';
import './Modal.scss';
import Routes from './Routes';
import { Globals } from './constants';
import {Helmet} from "react-helmet";

import './css/cricingif.css?v=1';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cssLoaded: false,
      appPathIos: Globals.ios,
      appPath: Globals.android,
      marketPath: Globals.androidMarket,
      
    };
   } 

  downloadApp(e){
    let sUsrAg = navigator.userAgent,
    win,
    url;
    if ((sUsrAg.indexOf("Android") > -1)) {
        url = this.state.appPath;
        window.open(url);
    } else {
      url = this.state.appPathIos;
      win = window.open(url, '_blank');
      win.focus();
    }
  } 
  async componentDidMount() {
    this.setState({cssLoaded: true});

    // if(this.state.cssLoaded === false){
    //   this.setState({cssLoaded: true});
    //   switch(Globals.cssFor){
    //     case 'cricingif':
    //       import('./css/cricingif.css?v=1');
    //       break;
    //     case 'cricwick':
    //       import('./css/cricwick.css?v=1.7');
    //       break;
    //     case 'zain':
    //       import('./css/zain.css?v=1.0');
    //       break;
    //     default:
    //       // console.log("unknown telco");
    //   }
    // }
    
    
  }
  
  render() {
    return (
      <BrowserRouter>
        <div>

          <Routes {...this.state} />
        </div>
      </BrowserRouter>
    );
  }

}

export default App;
