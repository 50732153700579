import React, { Component } from 'react';
import { Scorecard, VideoCard, NewsCard, FullWidthCard } from './HelperFunctions';
import { Link } from 'react-router-dom';
import { Globals } from './constants';
import axios from 'axios';
import HomeSeriesCard from './HomeSeriesCard';
import {hyphen2space} from './HelperFunctions';


class Series extends Component {
  constructor(props) {
    super(props);
    this.state = {
        seriesTitle: this.props.match.params.title,
        seriesResp: [],
        apiHit: 0,
        customCssTransition: 'h-transition',
        isLoading: true,
        page: 0,
        pagesEnd: false,
        seriesId: this.props.match.params.id
    };
    this.getMarkup = this.getMarkup.bind(this);
    this.setSeriesFromApi = this.setSeriesFromApi.bind(this);
    console.log("Router location props", this.props.location, this.props.match);
  };

    async componentDidMount() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      
      this.setSeriesFromApi();
    }
    async setSeriesFromApi() {

      if(!this.state.pagesEnd){
        let elem = document.getElementsByClassName('loading-more-results');
        if(elem.length)
        elem[0].classList.remove('d-none');

        let seriesId = this.state.seriesId,
        page = this.state.page+1,
        apiHit = this.state.apiHit;
        const url = Globals.ApiUrl+"api/v2/view_lists/get_list_items_from_viewable?viewable_type=series&viewable_id="+seriesId+"&page="+page+"&telco="+Globals.telco;
        let resp = [];
        console.log("Series Id", seriesId, "url", url)

      try {
          resp = await axios.get(url);
          console.log("series resp", resp)
          if (resp.status!==200) {
            window.location.href = '/404notfound';
          }

          const seriesRespCopy = resp.data.slice();
          let seriesResp = [];

          if(seriesRespCopy.length){
            if(elem.length)
            elem[0].classList.add('d-none');

            if (page > 1) {

              seriesResp = this.state.seriesResp.slice();
              seriesRespCopy.forEach( (obj, i) => {
                seriesResp.push(obj);       
              })
            
            } else {
              seriesResp = seriesRespCopy;
            };

            console.log("series resp before set state", seriesResp)
            this.setState({seriesResp: seriesResp, customCssTransition: 'v-transition', 
            isLoading: false, apiHit: 1, page: page}, () => console.log("Series set state", this.state));
          }else{
            if(elem.length)
            elem[0].classList.add('d-none');

            if(apiHit){
              this.setState({pagesEnd: true})
              // alert('reached end');
            }
          }
      }
      catch (err) {
          console.log("Error", err);
          // window.location.href = '/404notfound';
     
        };
      }   
    }
    nextPage(e) {

      // if (!this.state.pagesEnd) {
      //   let elem = document.getElementsByClassName('loading-more-results');
      //   if(elem.length)
      //     elem[0].classList.remove('d-none');
        
      //   const page = this.state.page + 1;
      //   const url = Globals.ApiUrl + "api/v2/view_lists/get_by_name?view=home&page="+page.toString();
      //   let resp;
      //   try {
      //     resp = await axios.get(url);
      //     console.log("Next page", resp);
      //   }
      //   catch(err) {
      //     console.log("error fetching next page");
      //   };
  
      //   if (resp.status === 200 && resp.data.length > 0) {
      //     if(elem.length)
      //       elem[0].classList.add('d-none');
  
      //   const homeApiResp = this.state.homeApiResp.slice();
        
      //   resp.data.forEach( (obj, i) => {
  
      //     (i===0 && homeApiResp[homeApiResp.length-1].type==='video' && obj.type==='video') 
      //     ?  homeApiResp[homeApiResp.length-1].data.push(obj.data[0])
      //     :  homeApiResp.push(obj);
          
      //   })
  
      //     this.setState({page: page, homeApiResp: homeApiResp}, () => console.log("Next page state set", this.state));
  
      //   } else {
      //     if(elem.length)
      //       elem[0].classList.add('d-none');
  
      //     if (resp.data.length===0) this.setState({pagesEnd: true});
  
      //     console.log("Api Error or no results");
      //   };
  
      // }
  
    }
    componentWillReceiveProps(nextProps) {
      console.log("next props", nextProps);
      if (nextProps.match.params.id!==this.state.seriesId) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.setState({seriesId: nextProps.match.params.id, seriesTitle: nextProps.match.params.title, page: 0, pagesEnd: false, isLoading: true}, () => 
          this.setSeriesFromApi()
        )
        console.log("setting series")
      }
    }
    getMarkup() {
        return this.state.seriesResp.map( (categoryList, i) => {

            switch(categoryList.type) {
      
              case 'match':
                return <HomeSeriesCard key={'hms-'+i} seriesId={this.state.seriesId} data={categoryList} isSeriesHome={true}></HomeSeriesCard>
              case 'generic-home':
                return <div key={'generic-section-'+i} className="card my-2 p-3">
                <FullWidthCard key={'fwc-'+i} type={categoryList.type} data={categoryList.data[0]}></FullWidthCard></div>
              default:
                console.log("Other type occured in home lists", categoryList)
      
            }
          
          });
    }

  render() {
    return (
        <section id="main-section" className="px-lg-0 jc-inner-page align-self-start">
          <div className="card p-3 mb-2">
              <h4 className="text-uppercase font-weight-bold mb-2 mb-lg-3 pl-2 m-f-14">{hyphen2space(this.state.seriesTitle)}</h4>
              <SeriesNav path={this.props.match}></SeriesNav>
                
            </div>
          {
              (this.state.isLoading) ?
              <div className="mt-2 main-loader card min-height-80vh">
                <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
              </div> : <div></div>
          }
        <div className={this.state.customCssTransition}>
            
            {
                this.getMarkup()
            }
            {
              (!this.state.pagesEnd && this.state.seriesResp.length) ?
              (
                <div>
                  <div className="text-center d-none loading-more-results"><img src="/images/loader_v2.gif" alt="" height="30" /></div>
                  <button id="more-results" onClick={this.setSeriesFromApi} type="button" className="btn btn-primary d-none">More Results</button>
                </div>
              )
              : <div></div>
            }
        </div>
        </section>


    )
  }
}

const MatchNavActiveClasses = (props, path, otherPath) => {
  let str = "";
  (props.path.path === path || props.path.path === otherPath) ? 
  (str = "font-weight-semi-bold p-2  m-f-14  border-bottom border-danger") : 
  (str = "font-weight-semi-bold p-2  m-f-14");
  return str;
};

export const SeriesNav = (props) => (
  <div className="d-flex overflow-x-auto">
      <Link to={"/series/"+props.path.params.id+"/"+props.path.params.title}>
          <h4 className={MatchNavActiveClasses(props, "/series/:id/:title")}>Summary</h4>
      </Link>
      <Link to={"/series/"+props.path.params.id+"/"+props.path.params.title+"/schedules"}>
          <h4 className={MatchNavActiveClasses(props, "/series/:id/:title/schedules")+' ml-4'}>Schedules</h4>
      </Link>
      {
        // (props.data && props.data[0] && props.data[0].series_obj.is_videos_enabled) ? 
        // ( 
          <Link to={"/series/"+props.path.params.id+"/"+props.path.params.title+"/videos"}>
          <h4 className={MatchNavActiveClasses(props, "/series/:id/:title/videos", "/series/:id/:title/videos/:videoId", "/series/:id/videos/:videoId")+' ml-4'}>Videos</h4>
          </Link>
        // ):
        // (<div></div>)
      }

      {
        // (props.dara && props.data[0] && props.data[0].series_obj.has_points_table) ? 
        // ( 
          <Link to={"/series/"+props.path.params.id+"/"+props.path.params.title+"/pointstable"}>
              <h4 className={MatchNavActiveClasses(props, "/series/:id/:title/pointstable")+' ml-4'}>Points Table</h4>
          </Link>
        // ):
        // (<div></div>)
      }
      
      
      
  </div>
);


export default Series;
