import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Link } from "react-router-dom";
import axios from 'axios';
import { StoriesCard } from './Stories';
import { VideoCardDescription } from './HomeSeriesCard';
import ImageLoader from 'react-load-image';

import Iframe from 'react-iframe'
import { Globals } from './constants';
import {Helmet} from "react-helmet";


class Photos extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }

        this.photosPluginLoad = this.photosPluginLoad.bind(this);

        
    }

    componentDidMount() {
        let script = document.createElement("script")
        script.onload = this.photosPluginLoad;

        script.src = "https://embedsocial.com/embedscript/ei.js";
        document.getElementsByTagName('head')[0].appendChild(script)
    }

    photosPluginLoad() {
        setTimeout( () => {
            this.setState({isLoading: false})

        }, 2000 )
    }

  render() {
    return (

        <section id="main-section" className="position-relative px-lg-0 jc-inner-page align-self-start">
            {
                (this.state.isLoading) ?
                <div className="main-loader card min-height-80vh">
                <img width="64" height="64" src="/images/loader_v2.gif" alt="" />
                </div> : <div></div>
            }
            <div class='embedsocial-album' data-ref="0a145b04cf4be8a3d444b3ab8e2f28f211a1f78d"></div>
            <div className="h-fx-100 w-100 position-absolute bottom-0 bg-body"/>

            {/* <Helmet>
            <script>
                {
                    (function(d, s, id){var js; if (d.getElementById(id)) {return;} 
                    js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/embedscript/ei.js"; 
                    d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialScript"))
                }
            </script>
            
            </Helmet> */}
           

            

        
          
        </section>


    );
  }
}

  
export default Photos;
