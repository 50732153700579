import * as React from "react";
import ReactPlayer from 'react-player';

class VideoComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        playVideo: false,
        videoId: "",
        videoUrl: "",
        playingVideoId: "",
        videoReady: false
    }
  }

  componentWillMount() {
      if (this.props.playingVideoId !== this.state.videoId) {
          this.setState({ playVideo: false });
      }
  }
  getPlaceHolder(isPrimary, path) {
      return <div className="cover-image" style={{ height: "216px", background: "url(" + path + ")" }}></div>
  }

  onReady(event) {
    this.setState({ videoReady: true });
  }

  handleMobileChange(video) {
    let videoUrl = video ? video.url : "";
    if (video.id !== this.props.playingVideoId) {
        this.props.handleMobileVideo(video.id);
        this.setState({ videoId: video.id, playVideo: true, videoUrl: videoUrl, videoReady: false });
    }
  }
  
  render() {
    const self = this;
    const { video } = this.props;
    return(
        <div className="primary-video-container" onClick={() => this.handleMobileChange(video)}>
        {/* <div onClick={() => this.handleMobileChange(video)}> */}
            {(this.state.videoId !== "") && (this.state.videoId === this.props.playingVideoId) && <div style={{height: "216px", position: "relative", background: "black"}}>
                {!this.state.videoReady && <div className="video-image" style={{background: "black", position: "absolute", bottom: "0px", top: "0px", left: "0px", right: "0px"}}>
                    <img src="https://scdn.cricingif.com/cig-live-images/static-assets/loading.gif" width="150" height="150" />
                </div>}
                {video.videoType === 3 && <div>
                    <ReactPlayer
                        url={this.state.videoUrl}
                        preload="true"
                        playing={true}
                        className="video-player"
                        controls={true}
                        onReady={this.onReady.bind(this)}
                    />
                </div>}
                {video.videoType === 2 && <div>
                    <ReactPlayer
                        url={this.state.videoUrl}
                        preload="true"
                        playing={true}
                        className="video-player"
                        controls={true}
                        config={{ facebook: { appId: '1536690803213439' } }}
                        onReady={this.onReady.bind(this)}
                    />
                </div>}
                {video.videoType === 1 && <div>
                    <ReactPlayer
                        url={this.state.videoUrl}
                        preload="true"
                        playing={true}
                        className="video-player"
                        controls={true}
                        config={{ youtube: { playerVars: { showinfo: 1 } } }}
                        onReady={this.onReady.bind(this)}
                    />
                </div>}
            </div>}
            {((this.state.videoId === "") || (this.state.videoId !== "" && this.state.videoId !== this.props.playingVideoId)) && <div className="flex-row align-center" 
                style={{height: "216px", position: "relative", background: "black", overflow: "hidden" }}>
                <div className="sixteen-by-nine video-image" style={{background: "url(" + this.props.imageUrl + "/content-assets/images/reduce/320x180/" + (video.id) + ".jpg), " + "url(" + this.props.imageUrl + "/content-assets/images/LQ/320x180/" + (video.id) + ".jpg), " + "url(" + "https://scdn-sak.azureedge.net/cig-live-images/static-assets/placeholder.png" + ")" }}>
                </div>
                <img className="play-icon" src="/images/play-icon.svg" width="50" height="50" />
            </div>}
            <div className="title-and-date">
                <div className="oswald-bold title">{video && video.title}</div>
                <div className="oswald-regular date">{video && new Date(video.publishDate).toDateString()}</div>
            </div>
        </div>
    );
  }
}

export default VideoComponent;
